// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import {
	Dropdown,
	Image,
	OverlayTrigger,
	Tooltip,
	Row,
	Col,
	Table,
	Button,
	Modal
} from 'react-bootstrap';
import { MoreVertical, Trash, Edit, Mail } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

// import utility file
import { numberWithCommas } from 'helper/utils';

// import data files
import { InstructorTestData } from 'data/users/InstructorData';

import DotBadge from 'components/elements/bootstrap/DotBadge';
import { useSelector } from 'react-redux';
import { deleteTeacherAction, fetchAllTeachersAction } from 'actions/teachers';
import { useDispatch } from 'react-redux';
import { approveUserAction } from 'actions/teachers';
import { TestList } from 'data/users/StudentsData';
import { fetchAllUsersAction } from 'actions/users';

const InstructorList = ({setTeacherId}) => {
	const dispatch = useDispatch();
const {teachers} = useSelector(state => state.teachers);
useEffect(() => {
 dispatch(fetchAllTeachersAction())
}, [dispatch])

/* const handelDeleteTeacher = (id) => {
	dispatch(deleteTeacherAction(id))
};
 */
const userDataString = localStorage.getItem('userData');
const userData = JSON.parse(userDataString);


const handleDeleteTeacher = (id) => {
	setTeacherToDelete(id);
	setShowDeleteModal(true);
};

const confirmDeleteTeacher = () => {
	if (teacherToDelete && confirmationMessage === 'DELETE TEACHER') {
	  dispatch(deleteTeacherAction(teacherToDelete));
	  setTeacherToDelete(null);
	  setShowDeleteModal(false);
	  setConfirmationMessage('');
	}
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [teacherToDelete, setTeacherToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

	const ActionMenu = ({id, setTeacherId}) => {
		return (
			<Dropdown style={{ zIndex: 1000, position:"absolute" }}>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item 
					onClick={()=> setTeacherId(id)}
					eventKey="1">
						{' '}
						<Edit size="15px" className="dropdown-item-icon" /> Edit
					</Dropdown.Item>
					{userData?.role=="admin"&&<Dropdown.Item
					onClick={() => handleDeleteTeacher(id)}
					eventKey="2">
						{' '}
						<Trash 
						size="15px"
						className="dropdown-item-icon"
						variant="danger"
						onClick={confirmDeleteTeacher}
						disabled={confirmationMessage !== 'DELETE TEACHER'}
						/> Remove
					</Dropdown.Item>}
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: '_id', Header: 'ID', show: true },
			{
				accessor: 'name',
				Header: 'Name',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							{/* <Image
								src={row.original.img}
								alt=""
								className="rounded-circle avatar-md me-2"
							/> */}
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			},
			// { accessor: 'image', Header: '', show: false },
			{
				accessor: 'email',
				Header: 'Email',
				Cell: ({ value }) =>  {
					return value;
				} 
			},
			{ accessor: 'role', Header: 'Role' },
			// { accessor: 'courses', Header: 'Courses' },
            // { accessor : 'students', Header: 'Students'},
			// { accessor: 'created_at', Header: 'Joined At' },
			/* {
				accessor: 'payment',
				Header: 'TotaL Payment',
				Cell: ({ value }) => {
					return '$' + numberWithCommas(value);
				}
			}, */
			// { accessor: 'confirmed', Header: 'Status' },
			// {
			// 	accessor: 'approved',
			// 	Header: 'Approved',

			// 	Cell: ({ value, row }) => {
					
			// 		return (
			// 			<Fragment>
			// 				<DotBadge
			// 					bg={
			// 						value === false
			// 							? 'warning'
			// 							: value === true
			// 							? 'success'
			// 							: ''
			// 					}
			// 				></DotBadge>
			// 			{value==true?'Confirmed':
			// 				<Fragment>
			// 					{/* <Button
			// 						href="#"
			// 						variant="outline"
			// 						className="btn btn-outline-secondary btn-sm"
			// 					>
			// 						Reject
			// 					</Button>{' '} */}
			// 					<Button href="#" variant="success" className="btn-sm"
			// 					onClick={(e) => handleApproveTeacher(row.original.id,e)}
			// 					>
			// 						Approved
			// 					</Button>
			// 				</Fragment>}
			// 			</Fragment>
			// 		);
			// 	}
			// },
			/* {
				accessor: 'confirmed',
				Header: 'Action',
				Cell: ({ value }) => {
					if (value === 'true') {
						return (
							<Fragment>
								<Button
									href="#"
									variant="outline"
									className="btn btn-outline-secondary btn-sm"
								>
									Reject
								</Button>{' '}
								<Button href="#" variant="success" className="btn-sm">
									Approved
								</Button>
							</Fragment>
						);
					}
					if (value === 'false') {
						return (
							<Button href="#" variant="secondary" className="btn-sm">
								Change Status
							</Button>
						);
					}
				}
			}, */
			{
				accessor: 'message',
				Header: 'Action',
				Cell: ({ row }) => {
					return (
						<div className="hstack gap-4">
							{/* <OverlayTrigger
								key="top"
								placement="top"
								overlay={<Tooltip id={`tooltip-top`}>Message</Tooltip>}
							>
								<Link to="#">
									<Mail size="15px" className="dropdown-item-icon" />
								</Link>
							</OverlayTrigger>
 */}
							{/* <OverlayTrigger
								key="top"
								placement="top"
								overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}
							>
								<Link to="#">
									<Trash size="15px" className="dropdown-item-icon" />
								</Link>
							</OverlayTrigger> */}

							<ActionMenu id={row.original._id} setTeacherId={setTeacherId}/>
						</div>
					);
				}
			}
		],
		[]
	);
        // const data = useMemo(() => teachers, [teachers]);
		const data = useMemo(() => teachers, [teachers]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Teacher"
						/>
					</Col>
				</Row>
			</div>

			<Table
				hover
				responsive
				{...getTableProps()}
				className="text-nowrap table-centered"
			>
				<thead className="table-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Deletion</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to delete this teacher?</p>
					<h4 className="text-secondary" >you must write "DELETE TEACHER" to confirm the deletion</h4>
					<input
						className="form-control"
						rows="3"
						placeholder="Enter confirmation message"
						value={confirmationMessage}
						onChange={(e) => setConfirmationMessage(e.target.value)}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
						Cancel
					</Button>
					<Button variant="danger" onClick={confirmDeleteTeacher}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default InstructorList;
