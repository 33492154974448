import { getAllFollowedPacks, approveFollowedPack, deleteFollowedPack, rejectFollowedPack } from "api";
import {
  GET_ALL_FOLLOWED_PACKS,
  APPROVE_FOLLOWED_PACK,
  DELETE_FOLLOWED_PACK,
  REJECT_FOLLOWED_PACK,
  LOADING
} from "./constants";


  export const fetchAllFollowedPacksAction = (fromData) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getAllFollowedPacks(fromData);
      dispatch({ type: GET_ALL_FOLLOWED_PACKS, payload: data.pack_follow_requests });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const ApproveFollowedPackAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
   
    await approveFollowedPack(fromData);
  
    dispatch({ type: APPROVE_FOLLOWED_PACK, payload: fromData });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
      }
  }
};
export const deleteFollowedPackRequestAction = (fromData) => async (dispatch) => {
  try {
    
    dispatch({ type: LOADING, payload: true });
    await deleteFollowedPack(fromData);

    dispatch({ type: DELETE_FOLLOWED_PACK, payload: fromData });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
  }
};

export const RejectFollowedPackAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
   
    await rejectFollowedPack(fromData);
  
    dispatch({ type: REJECT_FOLLOWED_PACK, payload: fromData });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
      }
  }
};