import React, { useEffect, useState } from "react";
import {
  Gitgraph,
  templateExtend,
  TemplateName,
  Orientation,
} from "@gitgraph/react";
import { useDispatch, useSelector } from "react-redux";

import { getAllTaskLogsAction } from "actions/boardCard";

const LogGraph = ({ id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getAllTaskLogsAction(id));
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [id, dispatch]);
  const { tasksLogs, lists } = useSelector((state) => state.boards);
  console.log(tasksLogs);
  const colors = [
    "#F58D06",
    "#4B4C5E",
    "#65637B",
    "#97B5A9",
    "#B8CDC6",
    "#DCE6DD",
    "#893326",
    "#4F1009",
    "#22191C",
  ];
  const withoutAuthor = templateExtend(TemplateName.Metro, {
    branch: {
      lineWidth: 4,
      label: {
        font: "normal 10pt Calibri",
      },
      spacing: 24,
    },
    commit: {
      spacing: 44,
      dot: {
        size: 8,
      },
      message: {
        displayAuthor: false,
        font: "normal 10pt Calibri",
        displayHash: false,
      },
    },
    colors: colors,
  });
  const initGraph = (gitgraph) => {
    const branches = {};
    lists
      ?.map((e) => e.title)
      .forEach((list, index) => {
        branches[list] = gitgraph.branch(list);
        branches[list].commit("");
      });

    tasksLogs?.forEach((logEntry, index) => {
      const { user, createdAt, action, details, list } = logEntry;
      if (action === "merge") {
        const sourceBranch = branches[details.source];
        const destinationBranch = branches[details.destination];
        destinationBranch
          .merge(sourceBranch)

          .tag("#" + (index + 1) + " " + user?.name);
      } else {
        const commitMessage = `${action} 
       at ${createdAt.toLocaleString()}`;

        branches[list?.title]
          .commit(commitMessage, { tooltip: details }) // Assuming the library supports tooltips
          .tag("#" + (index + 1) + " " + user?.name);
      }
    });
  };

  return (
    <div className="App">
      {loading ? (
        <div style={{ marginLeft: "30%", marginTop: "20%" }}>
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Gitgraph
          options={{
            orientation: Orientation.VerticalReverse,
            reverseArrow: true,
            template: withoutAuthor,
          }}
        >
          {initGraph}
        </Gitgraph>
      )}
    </div>
  );
};

export default LogGraph;
