import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Accordion, useAccordionButton, ListGroup } from "react-bootstrap";

const GKAccordionDefault = ({ accordionItems, itemClass, books }) => {
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    return (
      <Fragment>
        <Link
          to="#"
          onClick={decoratedOnClick}
          className="d-flex align-items-center text-inherit text-decoration-none h4 mb-0"
          data-bs-toggle="collapse"
          aria-controls={`collapse-${eventKey}`} // Use unique IDs for aria-controls
        >
          <div className="me-auto">{children.title}</div>
          <span className="chevron-arrow ms-4">
            <i className="fe fe-chevron-down fs-4"></i>
          </span>
        </Link>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Accordion defaultActiveKey={books[0]?.id}>
        <ListGroup as="ul" variant="flush">
          {books?.map((item, index) => {
            return (
              <ListGroup.Item
                key={index}
                as="li"
                className={`${itemClass ? itemClass : ""}`}
              >
                <ContextAwareToggle eventKey={item.id}>
                  {item}
                </ContextAwareToggle>
                <Accordion.Collapse eventKey={item.id} className="test">
                  <ListGroup className="py-4">
                    <div className="row">
                      <div className="col-md-6">
                        <p>{item.desc} </p>
                      </div>
                      <div className="col-md-6 d-flex flex-column align-items-center">
                        <div className="image-container">
                          <img
                            style={{ width: "200px" }}
                            src={item.img}
                            alt={item.title}
                          />
                        </div>
                        <div className="title-container mt-auto">
                          <Link>
                            <h3>{item.title}</h3>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </ListGroup>
                </Accordion.Collapse>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Accordion>
    </Fragment>
  );
};

export default GKAccordionDefault;
