import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllBooks, getBookById, editBook, deleteBook, createBook } from "api";
import {
  GET_ALL_BOOKS,
  GET_BOOK,
  EDIT_BOOK,
  DELETE_BOOK,
  CREATE_BOOK,
 
  LOADING
} from "./constants";

export const fetchAllBooksAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllBooks();

    dispatch({ type: GET_ALL_BOOKS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchBookAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const data = await getBookById(id);
  
      dispatch({ type: GET_BOOK, payload: data?.data });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editBookAction = (fromData, handleClose, setBookId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await editBook (fromData);

    dispatch({ type: EDIT_BOOK, payload: data?.data.book });
    handleClose()
    setBookId()
    toast.success(data.data.message, {
         
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const deleteBookAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
     const data = await deleteBook(id);
  
      dispatch({ type: DELETE_BOOK, payload: id });
      toast.success(data.data.message, {
         
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createBookAction = (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
 
      const {data} = await createBook(fromData);
      dispatch({ type: CREATE_BOOK, payload: data.book });
      handleClose()
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

