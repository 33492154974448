// import node module libraries
import { useContext, useEffect, useState } from "react";

// import context file
import { TaskKanbanContext } from "context/Context";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { moveTask } from "actions/boardList";
import { useDispatch } from "react-redux";
import {
  changeIndexPositionAction,
  changePositionAction,
  fetchCardAction,
} from "actions/boardCard";
import io from "socket.io-client";
// import { toast } from "react-toastify";
import { createQuizAccountAction } from "actions/quiz";

const socket = io("https://api-sprintup.intellect.tn");
// const socket = io("http://localhost:5034");
const useTaskKanban = () => {
  const navigate = useNavigate();
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  //socket
  const { id } = useParams();
  const [cursors, setCursors] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    // Listen for taskMoved events from the server
    socket.on("taskMoved", ({ taskId, newListId, source, newIndex }) => {
      dispatch(moveTask(source, taskId, newListId, newIndex));
    });
    socket.on("taskCreated", (data) => {
      // window.location.reload();
    });
    return () => {
      socket.off("taskMoved"); // Remove the event listener
    };
  }, []);
  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX: x, clientY: y } = event;
      const userName = userData?.name;
      socket.emit("cursorMove", { boardId: id, userName, x, y });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    socket.emit("joinBoard", id);
    return () => {
      socket.off("joinBoard");
    };
  }, []);
  // useEffect(() => {
  //   socket.on("joined", (data) => {
  //     toast.info(`${data.userName} has joined the room`, {
  //       autoClose: 1000,
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       hideProgressBar: true,
  //     });
  //   });
  //   socket.on("left", (data) => {
  //     toast.info(`${data.userName} has left the room`, {
  //       autoClose: 1000,
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //       hideProgressBar: true,
  //     });
  //   });
  //   return () => {
  //     socket.off("joined");
  //     socket.off("left");
  //   };
  // }, []);

  useEffect(() => {
    socket.on("cursorMove", (data) => {
      setCursors((prevCursors) => ({
        ...prevCursors,
        [data.userId]: { userName: data.userName, x: data.x, y: data.y },
      }));
    });

    return () => {
      socket.off("cursorMove");
    };
  }, []);

  const { lists, card } = useSelector((state) => state.boards);

  useEffect(() => {
    dispatch(fetchCardAction(id));
  }, [dispatch, id]);
  const {
    TaskKanbanState: { taskList },
    TaskKanbanDispatch,
  } = useContext(TaskKanbanContext);

  const [showQuizModal, SetShowQuizModal] = useState(false);

  const handleCloseQuiz = () => {
    SetShowQuizModal(false);
  };

  const handleCreateQuizAccount = () => {
    dispatch(createQuizAccountAction(userData._id));
  };

  const handleDragEnd = (result) => {
    const { destination, draggableId, source } = result;
    if (!destination) {
      return;
    }
    if (
      userData.role == "student" &&
      lists.filter((l) => l._id == destination.droppableId)[0]?.type == "done"
    ) {
      SetShowQuizModal(true);
      dispatch(fetchCardAction(draggableId));
    }

    const cardCheck = lists
      ?.flatMap((list) => list?.cards)
      ?.find((card) => card?._id === result?.draggableId);
    if (
      cardCheck?.type === "onlyMe" &&
      userData?._id !== cardCheck?.assignedTo?._id
    ) {
      alert("You are not allowed to do this action.");
      return;
    }
    // Find the source list and group
    let startList = lists.find((list) => list._id === source.droppableId);
    let startGroup = null;

    // If no startList, check if it's from a group
    if (!startList) {
      startList = lists.find((list) =>
        list.groups?.some((group) => group._id === source.droppableId)
      );
      startGroup = startList?.groups?.find(
        (group) => group._id === source.droppableId
      );
    }

    // Find the destination list and group
    let finishList = lists.find((list) => list._id === destination.droppableId);
    let destinationGroup = null;

    // If no finishList, check if it's to a group
    if (!finishList) {
      finishList = lists.find((list) =>
        list.groups?.some((group) => group._id === destination.droppableId)
      );
      destinationGroup = finishList?.groups?.find(
        (group) => group._id === destination.droppableId
      );
    }

    dispatch(
      changePositionAction(
        {
          cardId: result?.draggableId,
          currentListId: startList._id,
          newListId: finishList._id,
          currentIndex: result?.source?.index,
          newIndex: result?.destination?.index,
          startGroup: startGroup?._id,
          destinationGroup: destinationGroup?._id,
        },
        id
      )
    );
  };

  const handlestartQuiz = () => {
    navigate("/quiz/quiz_start?quiz_id=" + card?.quizId + "&board_id=" + id);
  };
  return {
    handleDragEnd,
    showQuizModal,
    cursors,
    handleCloseQuiz,
    handleCreateQuizAccount,
    handlestartQuiz,
  };
};

export default useTaskKanban;
