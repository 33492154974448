// import node module libraries
import { Row, Col, Image, Form, Button, Container } from 'react-bootstrap';

// import media files
import Analytics from 'assets/images/background/analytics2.jpg';

const HeroFormLeftB = () => {
	return (
		
		<section className="p-1 bg-white">
			<Container>
				<Row>
					<Col xl={{ offset: 0, span: 12 }} sm={12}>
						<Row className="px-0 pt-3 pb-0 pe-lg-0 pt-lg-4 align-items-center rounded-3 ">
                        <Col
								lg={{ offset: 0, span: 6 }}
								className="p-0 overflow-hidden rounded-end-md shadow-lg"
							>
								<Image
									className="rounded-top-md card-img-size-600"
									src={Analytics}
									alt=""
								/>
							</Col>
							<Col lg={6} className="p-0 p-md-5 pt-lg-9">
								<h1 className="display-5 fw-bold lh-1 mb-3" style={{color:"#6778c9"}}>
                                kanban board <a><br></br>(Agile educationel process)</a>
								</h1>
								<p className="lead mb-5">
                                A Kanban board is a visual tool that supports agile educational processes by organizing tasks into stages like "To Do," "In Progress," and "Done." It helps students and educators track progress, manage projects efficiently, and adapt to individual learning needs. By fostering transparency and continuous improvement, Kanban boards enhance engagement and develop essential skills in collaboration and time management.
								</p>
								{/* <Form>
									<Row>
										<Col md={7} sm={12} className="mb-3">
											<Form.Control
												type="email"
												className="form-control"
												placeholder="Work Email"
												required=""
											/>
										</Col>
										<Col md={5} sm={12} className="d-grid mb-3 ps-md-0">
											<Button variant="primary" type="submit">
												Request Access
											</Button>
										</Col>
									</Row>
								</Form> */}
							</Col>
							
						</Row>
					</Col>
				</Row>
			</Container>

			
		</section>	
	);
};
export default HeroFormLeftB;