// import node module libraries
import React from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';

const Pagination = ({
	previousPage,
	pageCount,
	pageIndex,
	gotoPage,
	nextPage,
	pageNumber,
	setPageNumber,
	usersCount,
	setUsersCount,
	total,
	currentPage,
}) => {
	const PageView = Array.from(Array(pageCount).keys());
	return (
		<Row>
			<Col lg={12} md={12} sm={12}>
			{usersCount !== null && usersCount !== undefined && (
					<div className="d-flex align-items-center justify-content-sm-between justify-content-center flex-wrap pagination-bx px-4 py-3" style={{ marginBottom:"-4%", fontSize:'15px'}}>
						<div className="mb-sm-0 mb-3 pagination-title">
							<p className="mb-0">
								<span>Showing </span>
								<select
									style={{border:"none", zIndex:9999999999}}
									value={usersCount}
									onChange={(e) => {
										setUsersCount(e.target.value);
										setPageNumber(1);
									}}
								>
									<option value="10">10</option>
									<option value="15">15</option>
									<option value="20">20</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="200">200</option>
									<option value="500">500</option>
								</select>
								<span> from <span>{total}</span> Logs</span>
							</p>
						</div>
					</div>
				)}
				<div className="pb-5">
					<nav>
						<ListGroup
							as="ul"
							bsPrefix="pagination"
							className="justify-content-center mb-0"
						>
							<ListGroup.Item as="li" bsPrefix="page-item">
								<Button
									onClick={() => previousPage()}
									className="page-link mx-1 rounded"
								>
									<i className="fe fe-chevron-left"></i>
								</Button>
							</ListGroup.Item>
							{PageView.filter(e => e == PageView[0] || e == PageView.length - 1 || e == pageIndex|| e == pageIndex+1|| e == pageIndex-1).map((page) => (
								<ListGroup.Item
									as="li"
									bsPrefix="page-item"
									key={page}
									className={`page-item ${currentPage !== null && currentPage !== undefined && currentPage === page ? 'active' : pageIndex === page ? 'active' : ''}`}
								>
									<Button
										className="page-link mx-1 rounded"
										onClick={() => gotoPage(page)}
									>
										{page + 1}
									</Button>
								</ListGroup.Item>
							))}
							<ListGroup.Item as="li" bsPrefix="page-item">
								<Button
									onClick={() => nextPage()}
									className="page-link mx-1 rounded"
								>
									<i className="fe fe-chevron-right"></i>
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</nav>
				</div>
			</Col>
		</Row>
	);
};

export default Pagination;
