import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllAdmins, getAdminById, editAdmin, deleteAdmin, createAdmin } from "api";
import {
  GET_ALL_ADMINS,
  GET_ADMIN,
  EDIT_ADMIN,
  DELETE_ADMIN,
  CREATE_ADMIN,
  LOADING,
  EDIT_TEACHER
} from "./constants";

export const fetchAllAdminsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await getAllAdmins();
    dispatch({ type: GET_ALL_ADMINS, payload: data.users});

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchAdminAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getAdminById(id);
      dispatch({ type: GET_ADMIN, payload: data.user });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editAdminAction = (fromData, handleClose,setAdminId, id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

   const {data} = await editAdmin (fromData, id);
   if (data.data.role == "admin"){
    dispatch({ type: EDIT_ADMIN, payload: data.data });
   }else{
    dispatch({ type: DELETE_ADMIN, payload: id });
   }
    
handleClose()
setAdminId()
toast.success(data.message, {
         
  autoClose: 1000,
});
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const deleteAdminAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await deleteAdmin(id);
      dispatch({ type: DELETE_ADMIN, payload: id });
      
        toast.success(data.message, { autoClose: 1000,});
        
       
      
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createAdminAction = (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const {data} = await createAdmin(fromData);
      if (data.data.role == "admin"){
        dispatch({ type: CREATE_ADMIN, payload: data.data });
       }else{ }
      
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      handleClose()
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
  