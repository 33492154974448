import { enc, HmacSHA256 } from "crypto-js";
import React, { useState, useEffect } from "react";
/**
 * Functions in utils
 */

export function useDebounce(value, delay) {
  // State to store the debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set a timeout to update the debounced value after the specified delay
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Clear the timer if the value changes before the delay elapses
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Add commas to a number
 * v1.0.0
 */
export const numberWithCommas = (x, decimal = 0) => {
  return x.toLocaleString("en-US", { minimumFractionDigits: decimal });
};

/**
 * Get the file extension from given file name
 * v1.2.0
 */
export const getFileExtension = (filename) => {
  const extension = filename.split(".").pop();
  return extension;
};

/**
 * Get the random number between min and max value
 * v1.2.0
 */
export const getRandomNo = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getStatusColor = (itemstatus) => {
  let color = "";
  switch (itemstatus) {
    case "In Progress":
      color = "info";
      break;
    case "Pending":
      color = "warning";
      break;
    case "Finished":
      color = "success";
      break;
    case "Cancel":
      color = "danger";
      break;
    default:
      color = "primary";
  }
  return color;
};
/**
 * Reformat a date and time string from ISO format to "yyyy-mm-dd - hh:mm" format
 * @param {string} isoDate - The date and time in ISO format (e.g., "2023-10-23T08:24:06.356Z")
 * @returns {string} - The reformatted date and time
 */
export const reformatISODate = (isoDate) => {
  const date = new Date(isoDate);
  date.setHours(date.getHours());

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours() - 2).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy} ${hh}h:${min}`;
};
export const reformatKanbanDate = (isoDate) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const date = new Date(isoDate);
  const yyyy = date.getFullYear();
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = months[date.getMonth()];

  return `${dd} ${mm} ${yyyy}`;
};
export const reformatCommentDate = (isoDate) => {
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const dateObj = new Date(isoDate);
  const dd = String(dateObj.getDate()).padStart(2, "0");
  const mm = months[dateObj.getMonth()];
  const yyyy = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");

  return `${dd} ${mm} ${yyyy} at ${hours}:${minutes}`;
};

export const reformDate = (isoDate) => {
  const date = new Date(isoDate);
  date.setHours(date.getHours() - 1); // Subtracting 1 hour

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");
  const hh = String(date.getHours()).padStart(2, "0");
  const min = String(date.getMinutes()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy} ${hh}h:${min}`;
};

export const reformatSearchDate = (isoDate) => {
  const date = new Date(isoDate);

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");

  return `${dd}-${mm}-${yyyy}`;
};

export const getPresenceColor = (itemstatus) => {
  let color = "";
  switch (itemstatus) {
    case "true":
      color = "success";
      break;
    case "false":
      color = "danger";
      break;
    default:
      color = "primary";
  }
  return color;
};

/**
 * Get the color name/value based on given status
 * v1.2.0
 */
export const getCategoryColor = (category) => {
  let color = "";
  switch (category) {
    case "Saas Services":
    case "Entertainment":
    case "Extra":
      color = "info";
      break;
    case "Design":
      color = "warning";
      break;
    case "Marketing":
      color = "success";
      break;
    case "Development":
      color = "danger";
      break;
    case "SEO":
      color = "primary";
      break;
    default:
      color = "primary";
  }
  return color;
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

// function to get time value in hh:mm AM | PM format
export const getTimeValue = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

// function to get date value in Month Name DD, YYYY format
export const getDateValue = (date) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const yyyy = date.getFullYear();
  let mm = date.getMonth();
  let dd = date.getDate();
  var today = month[mm] + " " + dd + ", " + yyyy;
  return today;
};

// function to generate slug or ID with slug format
export const getSlug = (text) => {
  text = text.toLowerCase();
  text = text.replace(/ /g, "-").replace(/[^\w-]+/g, "");
  return text;
};
// get public id
const regex = /\/v\d+\/([^/]+)\.\w{3,4}$/;
export const getPublicIdFromUrl = (url) => {
  const match = url.match(regex);
  return match ? match[1] : null;
};

const CLOUDINARY_API_SECRET = "jSW_GSXsGujJUSwWP7rjlCIV4TU";
export const generateCloudinarySignature = (params) => {
  const signaturePayload = Object.keys(params)
    .sort()
    .map((key) => key + "=" + params[key])
    .join("&");

  const signature = HmacSHA256(
    signaturePayload,
    CLOUDINARY_API_SECRET
  ).toString(enc.Hex);

  return signature;
};
export const generateRandomId = () => {
  const RandomNumber = Math.floor(100000 + Math.random() * 900000);
  return RandomNumber;
};
export function getCoveredPointsFromFailedAnswers(data) {
  let failedCoveredPoints = [];

  data.answers.forEach((answer) => {
    if (!answer.success) {
      failedCoveredPoints = failedCoveredPoints.concat(
        answer.question.covredPoints
          .map((e) => e._id)
          .filter((e) => e != undefined)
      );
    }
  });

  return failedCoveredPoints;
}

export function generateFourDigitId() {
  return Math.floor(1000 + Math.random() * 9000).toString();
}

export function generateText(data) {
  let text = "";
  data.map((desc) => {
    const htmlElement = document.createElement("div");
    htmlElement.innerHTML = desc.desc;
    // Extract text from the HTML structure
    const extractedText = htmlElement.innerText;

    if (desc.title) {
      text += `${desc.title}\n\n`;
    }

    if (desc.desc) {
      text += `${extractedText}\n`;
    }
  });
  return text.trim();
}
export const orderCardsByPrevCardId = (cards) => {
  const cardMap = new Map();

  // Step 1: Populate cardMap with card IDs as keys
  cards.forEach((card) => {
    cardMap.set(card._id, card);
  });

  // Step 2: Find the first card (the one with prevCardId = null)
  let firstCard = cards.find((card) => !card.prevCardId);

  // Step 3: Build the ordered array of cards by following the prevCardId links
  const orderedCards = [];
  while (firstCard) {
    orderedCards.push(firstCard);
    // Find the next card by its prevCardId
    firstCard = cards.find((card) => card.prevCardId === firstCard._id);
  }

  return orderedCards;
};

const utils = [
  numberWithCommas,
  getFileExtension,
  getRandomNo,
  getStatusColor,
  chunk,
  getTimeValue,
  getDateValue,
  getSlug,
];

export default utils;
