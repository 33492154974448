import {
    GET_ALL_SESSIONS,
    GET_SESSION,
    EDIT_SESSION,
    DELETE_SESSION,
    CREATE_SESSION,
    GET_SESSIONS_FROM_BOOK,
    GET_SESSIONS_BY_TEACHER,
  } from "../actions/constants";
  
  const sessionsReducer = (state = { sessions:[], session: {} }, action) => {
    switch (action.type) {
        case GET_ALL_SESSIONS:
            return { ...state, sessions: action?.payload.sessions };
            case GET_SESSIONS_BY_TEACHER:
            return { ...state, sessions: action?.payload.sessions };
        case GET_SESSION:
            return { ...state, session: action?.payload };
        case EDIT_SESSION:
            return { ...state, sessions: state.sessions.map(e=>{
                if(e._id==action.payload._id){
                  return action.payload
                }
                else{
                  return e
                }
              }) };
              ;
        case DELETE_SESSION:
            return { ...state, sessions: state.sessions.filter(e => e._id !== action.payload)};
        case CREATE_SESSION:
            return {...state, sessions: [...state.sessions, action.payload]};
            case GET_SESSIONS_FROM_BOOK:
                return { ...state, sessions: action?.payload.sessions };
        default:
            return state;
    }
}
  
  export default sessionsReducer;
  