import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import TaskDetails from "../TaskDetails";
import { useDispatch } from "react-redux";
import { fetchCardAction } from "actions/boardCard";

const DEFAULT_HANDLE_STYLE = {
  width: 10,
  height: 10,
  bottom: -5,
};

const CustomNode = memo(({ data, isConnectable }) => {
  const dispatch = useDispatch();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const handleShowCardDetails = () => {
    setShowDetailsModal(true);
    dispatch(fetchCardAction(data.id));
  };
  return (
    <>
      {data.label == "start" ? (
        <div
          style={{
            padding: 10,

            borderRadius: 5,
            backgroundColor: "#fff",
          }}
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/009/267/136/original/location-icon-design-free-png.png"
            style={{ color: "blue", width: "35px", marginTop: "-50px" }}
          />

          <Handle
            type="source"
            position="right"
            style={{ background: "#555" }}
          />
        </div>
      ) : data.label == "end" ? (
        <div
          style={{
            padding: 10,

            borderRadius: 5,
            backgroundColor: "#fff",
          }}
        >
          <img
            src="https://images.emojiterra.com/google/android-oreo/512px/1f6a9.png"
            style={{ color: "blue", width: "50px", marginTop: "-50px" }}
          />

          <Handle
            type="target"
            position="left"
            style={{ background: "#555" }}
          />
        </div>
      ) : (
        <div
          onClick={() => handleShowCardDetails()}
          style={{
            background: data.color,
            padding: 10,
            border: `1px solid ${data.color}`,
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h6 style={{ marginBottom: "-2px", color: "white" }}>
              {" "}
              {data.label}
            </h6>
          </div>
          {data.sourcePosition && (
            <Handle
              type="source"
              id={data?.arrow?.id}
              position={data.sourcePosition}
              style={{
                ...DEFAULT_HANDLE_STYLE,
                backgroundColor: "#A9A9A9",
              }}
            />
          )}
          {data.targetPosition && (
            <Handle
              type="target"
              id={data?.arrow?.id}
              position={data.targetPosition}
              style={{
                ...DEFAULT_HANDLE_STYLE,
                backgroundColor: "#A9A9A9",
              }}
            />
          )}

          {data.subcategories.map((subcategory, index) => (
            <div key={index}>
              <div
                style={{
                  position: "absolute",
                  top: `${80 + (index + 1) * 30}%`,
                  left: 0,
                  // border: `solid 1px ${subcategory.color}`,
                  borderRadius: "6px",
                  padding: "2%",
                  fontSize: "8px",
                }}
              >
                {subcategory.label}
              </div>
              <Handle
                type="source"
                id={subcategory.label}
                position={Position.Bottom}
                style={{
                  ...DEFAULT_HANDLE_STYLE,
                  left: `${(index + 1) * 15}%`,
                  background: subcategory.color,
                }}
                onConnect={(params) => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
              />
            </div>
          ))}
        </div>
      )}
      <TaskDetails
        showDetailsModal={showDetailsModal}
        setShowDetailsModal={setShowDetailsModal}
        cardId={data.id}
      />
    </>
  );
});

export default CustomNode;
