// import node module libraries
import { Fragment, useEffect } from "react";
import { Form, Button, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// import custom components

import {
  createBoardAction,
  editBoardAction,
  fetchBoardAction,
} from "actions/boardKanban";
import { Plus, Trash } from "react-feather";
import { fetchAllTeachersAction } from "actions/teachers";
import { useSearchParams } from "react-router-dom";
import AutoCompleteCurriculum from "./AutoCompleteCurriculum";
import { getAllCurriculumAction } from "actions/curriculum";

const AddNewBoard = ({
  boardId,
  setBoardId,
  showBoard,
  setShowBoard,
  boardData,
  setBoardData,
}) => {
  const [searchParams] = useSearchParams();
  const template = searchParams.get("template");
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { loading } = useSelector((state) => state.auth);
  const { board: boardEdited } = useSelector((state) => state.boards);
  const dispatch = useDispatch();

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    if (userData) {
      setBoardData((prevState) => ({
        ...prevState,
        owner: userData._id,
      }));
    }
  }, []);
  const handleAddBoard = async (e) => {
    if (boardId) {
      dispatch(editBoardAction({ ...boardData }, boardId, handleClose));
    } else {
      dispatch(
        createBoardAction(
          { ...boardData, template: template == "true" ? true : false },
          handleClose
        )
      );
    }
    e.preventDefault();
  };

  useEffect(() => {
    if (boardId) {
      dispatch(fetchBoardAction(boardId));
    }
  }, [dispatch, boardId]);
  useEffect(() => {
    if (boardEdited?._id) {
      setBoardData({
        title: boardEdited.title,
        description: boardEdited.description,
        owner: boardEdited.owner,
        members: boardEdited?.members?.map((member) => member),
        type: boardEdited.type,
        curriculum: boardEdited.curriculum,
      });
    }
  }, [boardEdited]);
  const handleClose = () => {
    setBoardData({
      title: "",
      description: "",
      owner: userData._id,
      members: [],
      type: "",
    });
    setShowBoard(false);
    setBoardId(null);
  };

  useEffect(() => {
    dispatch(fetchAllTeachersAction());
    dispatch(getAllCurriculumAction());
  }, [dispatch]);
  const handleDeleteCurriculum = (id) => {
    setBoardData({
      ...boardData,
      curriculum: boardData.curriculum.filter((e) => e._id != id),
    });
  };
  return (
    <Fragment>
      {/* <Button variant="primary" onClick={() => handleShow()}>
        {template === "true" ? "Add New Template" : "Add New Board"}
      </Button> */}

      <Offcanvas show={showBoard} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {boardId ? "Update Board" : "Add New Board"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Board Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Board Title"
                id="title"
                required
                value={boardData.title}
                onChange={(e) =>
                  setBoardData({ ...boardData, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Board Description</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Board Description"
                id="desc"
                required
                value={boardData.description}
                onChange={(e) =>
                  setBoardData({ ...boardData, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Curriculums :</Form.Label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {boardData?.curriculum?.map((c, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <Form.Label key={index}>
                      {" "}
                      {index + 1} - {c.title}
                    </Form.Label>
                    <Trash
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleDeleteCurriculum(c._id)}
                      size={16}
                    />
                  </div>
                ))}
              </div>

              <AutoCompleteCurriculum
                setBoardData={setBoardData}
                boardData={boardData}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Project Type</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  id="general"
                  name="boardType"
                  label="General"
                  checked={boardData.type === "general"}
                  onChange={() =>
                    setBoardData({ ...boardData, type: "general" })
                  }
                />
                <Form.Check
                  type="radio"
                  id="teaching"
                  name="boardType"
                  label="Teaching"
                  checked={boardData.type === "teaching"}
                  onChange={() =>
                    setBoardData({ ...boardData, type: "teaching" })
                  }
                />
                <Form.Check
                  type="radio"
                  id="administration"
                  name="boardType"
                  label="Administration"
                  checked={boardData.type === "administration"}
                  onChange={() =>
                    setBoardData({ ...boardData, type: "administration" })
                  }
                />
              </div>
            </Form.Group>
            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={(e) => handleAddBoard(e)}
              >
                {boardId ? "Update Board" : "Add New Board"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddNewBoard;
