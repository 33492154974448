// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Form, Button, Offcanvas } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  fetchTemplatsAction,
  genrateTemplatsAction,
} from "actions/boardKanban";
const BoardFromTemplate = ({ show, setShow }) => {
  const { loading } = useSelector((state) => state.auth);
  const { templates } = useSelector((state) => state.boards);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [template, setTemplate] = useState({
    title: "",
    templateId: null,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTemplatsAction());
  }, [dispatch]);

  const handleSelection = (selected) => {
    if (selected.length > 0) {
      setTemplate({ ...template, templateId: selected[0]._id });
    }
  };
  const close = () => {
    setTemplate({
      title: "",
      templateId: null,
    });
    setShow(false);
  };
  const handleGenrateTemplate = () => {
    dispatch(
      genrateTemplatsAction({ ...template, ownerId: userData._id }, close)
    );
  };

  return (
    <Fragment>
      <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Genrate From Template</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Board Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Board Title"
                id="title"
                required
                value={template.title}
                onChange={(e) =>
                  setTemplate({ ...template, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Templates</Form.Label>
              <Typeahead
                id="autocomplete-example"
                labelKey="title"
                options={templates}
                placeholder="Type to search template.."
                onChange={handleSelection}
              />
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={handleGenrateTemplate}
              >
                Generate
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default BoardFromTemplate;
