import axios from "axios";
import { generateCloudinarySignature } from "helper/utils";

const JSON_API = axios.create({
  baseURL: "https://json.iread.tn",
  withCredentials: false,
});
const API = axios.create({ baseURL: "https://api-sprintup.intellect.tn/" });
// const API = axios.create({ baseURL: "http://localhost:5034/" });
const APIclient = axios.create({ baseURL: "http://5.135.52.74:5000/" });
const APIiread = axios.create({ baseURL: "https://api.iread.tn/" });

const CurriculumAPI = axios.create({
  baseURL: "https://curriculum-api.intellect.tn/",
});
// const CurriculumAPI = axios.create({ baseURL: "http://localhost:5222/" });
const CLOUD_NAME = "dtwkikmuy";
const API_KEY = "564314396825113";
const API_SECRET = "jSW_GSXsGujJUSwWP7rjlCIV4TU";
const cloudinaryAPI = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/destroy`;
const TIMESTAMP = Math.round(new Date().getTime() / 1000);
const KEY_QUIZ = "66e829cd883d1176a0d73beb";
const QUIZ_API = axios.create({ baseURL: "https://quiz.iread.tn/" });
// const AICORE_API = axios.create({ baseURL: "http://localhost:5221" });
const AICORE_API = axios.create({
  baseURL: "https://ai-core-api.intellect.tn",
});
// const QUIZ_API = axios.create({ baseURL: "http://localhost:5044" });
//user
export const signIn = (fromData) => API.post("/users/signin", fromData);
export const signUp = (fromData) => API.post("/users/signup", fromData);
export const signInClient = (fromData) =>
  API.post("/clients/signin_client", fromData);
export const signUpClient = (fromData) =>
  API.post("/clients/signup_client", fromData);
export const logOut = () => API.get("/users/logout");
export const current = () => API.get("/users/current");
export const resendLink = () =>
  API.post("/users/resend_email_confirmation_link");
export const deleteImage = async (publicId) => {
  const signatureParams = {
    public_id: publicId,
    timestamp: TIMESTAMP,
    api_key: API_KEY,
  };

  const signature = generateCloudinarySignature(signatureParams);
  try {
    return await axios.post(cloudinaryAPI, {
      public_id: publicId,
      signature: signature,
      api_key: API_KEY,
      timestamp: TIMESTAMP,
    });
  } catch (error) {
    console.log(error);
  }
};

//packs
// export const getAllPacks = () => API.get("/main/show_all_pack");
// export const getAllPackDetails = (id) =>
//   API.post("/main/get_pack_details", { id });

// Function to get the schoolId from localStorage
export const getSchoolIdFromLocalStorage = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  return userData?.schoolId || null;
};

// export const getSchoolIdFromLocalStorage = () => {
//   return "651ec55b85ea310134cdd167" || null;
// };

// Update your API calls with the schoolId parameter
export const getAllAdmins = (formData) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(`/users/get_all_admin/${schoolId}`, formData);
  } else {
    // Handle the case when schoolId is not found in userData
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getAllAssistants = (formData) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(`/users/get_all_assistant/${schoolId}`, formData);
  } else {
    // Handle the case when schoolId is not found in userData
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getAllUsers = (formData) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(`/users/get_all/${schoolId}`, formData);
  } else {
    // Handle the case when schoolId is not found in userData
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getAllTeachers = (formData) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(`/users/get_all_teacher/${schoolId}`, formData);
  } else {
    // Handle the case when schoolId is not found in userData
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getAllClasses = (page, search) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(
      `/class_room/get_all?schoolId=${schoolId}&page=${page}&search=${search}`
    );
  } else {
    // Handle the case when schoolId is not found in userData
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getAllClients = (page, search) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(
      `/clients/get_all?schoolId=${schoolId}&page=${page}&search=${search}`
    );
  } else {
    // Handle the case when schoolId is not found in userData
    return Promise.reject("School ID not found in localStorage");
  }
};

export const fetchAllNotes = () => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get("/note/get_all/" + schoolId);
  } else {
    // Handle the case when schoolId is not found in userData
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getAllBadges = (page, search) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(`/badge/get_all?page=${page}&search=${search}`);
  } else {
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getAllLogs = (search, page, page_limite, startFrom, endTo) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(
      `/door-log?page=${page}&search=${search}&limit=${page_limite}&startTime=${startFrom}&endTime=${endTo}`
    );
  } else {
    return Promise.reject("School ID not found in localStorage");
  }
};

export const getFreeBadges = (page, search) => {
  const schoolId = getSchoolIdFromLocalStorage();
  if (schoolId) {
    return API.get(`/badge/get_free_badges?page=${page}&search=${search}`);
  } else {
    return Promise.reject("School ID not found in localStorage");
  }
};

//export logs
export const exportRapportExcel = (
  search,
  page,
  page_limite,
  startFrom,
  endTo
) =>
  API.get(
    `/door-log/generate_excel?page=${page}&search=${search}&limit=${page_limite}&startTime=${startFrom}&endTime=${endTo}`
  );
//Badges

export const getBadgeById = (id) => API.get("/badge/get_one/" + id);
export const editBadge = (fromData, id) =>
  API.patch("/badge/update/" + id, fromData);
export const deleteBadge = (id) => API.delete("/badge/delete/" + id);
export const createBadge = (fromData) => API.post("/badge/create", fromData);
export const importBadge = (formData) =>
  API.post("/badge/import-excel", formData);
export const assigneBadge = (fromData) =>
  API.post("/class_room/add_badges_to_class", fromData);
export const assigneBadgeToClient = (fromData) => API.post("/", fromData);

//Link Client To Coffee Shop
export const LinkToCoffeShop = () => API.get("/badge/link-to-coffee-shop");

//Users
// export const getAllUsers = (formData) => API.get("/users/get_all/"+"651ec55b85ea310134cdd167", formData)
export const getUserById = (id) => API.get("/users/get_one/" + id);
export const editUser = (fromData, id) =>
  API.patch("/users/update" + id, fromData);
export const deleteUser = (id) => API.delete("/users/delete/" + id);
export const createUser = (fromData) => API.post("/users/create", fromData);

//Admins
// export const getAllAdmins = (fromData,schoolId) => API.get(`/users/get_all_admin/${schoolId}`, fromData)
export const getAdminById = (id) => API.get("/users/get_one/" + id);
export const editAdmin = (fromData, id) =>
  API.patch("/users/update/" + id, fromData);
export const deleteAdmin = (id) => API.delete("/users/delete/" + id);
export const createAdmin = (fromData) => API.post("/users/create", fromData);
//Assistants
export const getAssistantById = (id) => API.get("/users/get_one/" + id);
export const editAssistant = (fromData, id) =>
  API.patch("/users/update/" + id, fromData);
export const deleteAssistant = (id) => API.delete("/users/delete/" + id);
export const createAssistant = (fromData) =>
  API.post("/users/create", fromData);
//Teachers
// export const getAllTeachers = (fromData) => API.get("/users/get_all_teacher/"+"651ec55b85ea310134cdd167", fromData)
export const getTeacherById = (id) => API.get("/users/get_one/" + id);
export const editTeacher = (fromData, id) =>
  API.patch("/users/update/" + id, fromData);
export const deleteTeacher = (id) => API.delete("/users/delete/" + id);
export const createTeacher = (fromData) => API.post("/users/create", fromData);
//Classes
// export const getAllClasses = (fromData) => API.get("/class_room/get_all?schoolId=651ec55b85ea310134cdd167");
export const getClassById = (id) => API.get("/class_room/get_one/" + id);
export const editClass = (fromData, id) =>
  API.patch("/class_room/update/" + id, fromData);
export const deleteClass = (id) => API.delete("/class_room/delete/" + id);
export const createClass = (fromData) =>
  API.post("/class_room/create", fromData);
export const addClientInClass = (fromData) =>
  API.post("/class_room/add_client", fromData);
export const getClientInClass = (id) =>
  API.get("/class_room/get_clients/" + id);
export const deleteClientFromClass = (fromData) =>
  API.post("/class_room/remove_client", fromData);
export const generateCode = (classId) =>
  API.post("/class_room/add_codes_to_class/" + classId);
//sessions
export const getAllSessions = (id) => API.get("/sessions/get_all/" + id);
export const getSessionsByTeacher = (id) =>
  API.get("/sessions/get_by_teacher/" + id);
export const getSessionById = (id) => API.get("/sessions/get_one/" + id);
export const editSession = (fromData, id) =>
  API.patch("/sessions/update/" + id, fromData);
export const deleteSession = (id) => API.delete("/sessions/delete/" + id);
export const createSession = (fromData) =>
  API.post("/sessions/create", fromData);
//Clients
// export const getAllClients = (id) => API.get("/clients/get_all?schoolId=651ec55b85ea310134cdd167")
export const checkSession = (code) =>
  APIiread.get("/admin/user_session/" + code);
export const linkCode = (fromData) =>
  APIiread.post("/reader/link_code", fromData);
export const searchReader = (fromData) =>
  APIiread.post("/admin/get_users_in_pack", fromData);
export const getCode = (code) => APIiread.get("/admin/get_code/" + code);
export const generateExcelFile = (class_id) =>
  API.get("clients/export/excel/" + class_id);
export const getClientById = (id) => API.get("/clients/get_one/" + id);
export const editClient = (fromData, id) =>
  API.patch("/clients/update/" + id, fromData);
export const deleteClient = (id) => API.delete("/clients/delete/" + id);
export const createClient = (fromData) => API.post("/clients/create", fromData);
export const getClientFromData = (fromData) =>
  APIclient.get(
    `/clients/user?searchQuery=64e5cd598ce2622ff0a3ffeb&&search=${fromData}`
  );

export const getStudents = () => API.get("/clients/get_students");
//sms
export const sendOneSms = (fromData) => API.post("/sms/send", fromData);
export const sendSmsToClass = (fromData) =>
  API.post("/sms/send_to_class", fromData);
export const sendCodeToClass = (fromData) =>
  API.post("/sms/send_code_to_class", fromData);
export const fetchsms = (fromData) =>
  API.post("/sms/fetch_sms_to_class", fromData);
//presence
export const startSession = (id) => API.post("/sessions/start_sessions/" + id);
export const fetchAllPresence = (id) => API.get("/presence/get_all/" + id);
export const presentAPI = (id, presence) =>
  API.patch(`/presence/update/${id}`, { presence });
export const absentAPI = (id, presence) =>
  API.patch(`/presence/update/${id}`, { presence });
export const clientDetails = (id) => API.get("/presence/get_by_client/" + id);

//Notes
export const createNote = (fromData, schoolId) =>
  API.post("/note/create/", fromData);
export const fetchNoteByTeacher = (id) => API.get("/note/get_by_teacher/" + id);
export const deleteNote = (id) => API.delete("/note/delete/" + id);
export const getNoteById = (id) => API.get("/note/get_one/" + id);
export const editNote = (fromData, id) =>
  API.patch("/note/update/" + id, fromData);
export const changeStatus = (id, status) =>
  API.patch(`/note/update/${id}`, { status });

//KAnban TAsk
export const fetchAllTasks = () => API.get("/kanban/get_all");
export const createTask = (fromData) => API.post("/kanban/create/", fromData);
export const fetchTaskByTeacher = (id) =>
  API.get("/kanban/get_by_teacher/" + id);
export const deleteTask = (id) => API.delete("/kanban/delete/" + id);
export const getTaskById = (id) => API.get("/kanban/get_one/" + id);
export const editTask = (fromData, id) =>
  API.patch("/kanban/update/" + id, fromData);

//KANABAN STATUS
export const fetchAllStatus = () => API.get("/kanban_status/get_all");

//BOARD KANBAN
export const getAllBoards = (template) =>
  API.get("/kanban_board?template=" + template);
export const createBoard = (fromData) => API.post("/kanban_board/", fromData);
export const fetchBoardByTeacher = (id) => API.get("/kanban_board/user/" + id);
export const deleteBoard = (id) => API.delete("/kanban_board/" + id);
export const getBoardById = (id) => API.get("/kanban_board/" + id);
export const editBoard = (fromData, id) =>
  API.put("/kanban_board/" + id, fromData);
export const getMembers = (id) => API.get("/kanban_board/members/" + id);
export const removeMember = (fromData) =>
  API.post("/kanban_board/remove_members/", fromData);
export const importBoard = (fromData, ownerId) =>
  API.post("/kanban_board/import/" + ownerId, fromData);
export const exportBoard = (boardId) =>
  API.get("/kanban_board/export/" + boardId);
//BOARD LIST
export const getAllLists = () => API.get("/board_list/");
export const createList = (fromData) => API.post("/board_list/", fromData);
export const fetchListByBoard = (id) => API.get("/board_list/board/" + id);
export const fetchListByBoardAndFilter = (id, filterOption) =>
  API.post("/board_list/specific-cards/" + id, { filterOption });
export const deleteList = (id) => API.delete("/board_list/" + id);
export const getListById = (id) => API.get("/board_list/" + id);
export const editList = (fromData, id) =>
  API.put("/board_list/" + id, fromData);
//BOARD CARD
export const getAllCards = () => API.get("/board_card/");
export const getCardsInBoard = (id) => API.get("/board_card/board/" + id);
export const fetchNoteCardByTeacher = (boardId, teacherId) =>
  API.get(`/board_card/board/${boardId}/createdBy/${teacherId}`);
export const createCard = (fromData) => API.post("/board_card/", fromData);
export const getRelatedCards = (id) =>
  API.get("/board_card/get-related-cards/" + id);
export const createRelatedCards = (id) =>
  API.get("/board_card/create-related-cards/" + id);
export const updateRelatedCards = (id) =>
  API.put("/board_card/update-related-cards/" + id);
export const deleteRelatedCards = (id) =>
  API.delete("/board_card/delete-related-cards/" + id);
export const createCardWithStudent = (fromData) =>
  API.post("/board_card/by_student", fromData);
export const fetchCardByList = (id) => API.get("/board_card/list/" + id);
export const deleteCard = (id) => API.delete("/board_card/" + id);
export const getCardById = (id) => API.get("/board_card/" + id);
export const editCard = (fromData, id) =>
  API.put("/board_card/" + id, fromData);
export const changePosition = (fromData, id) =>
  API.post("/board_card/change_position/" + id, fromData);
export const changeIndexPosition = (fromData, id) =>
  API.post("/board_card/cards/update-position/" + id, fromData);
export const getAllArchivedCard = (id) =>
  API.get("/board_card/archived/board/" + id);
export const addMetaCardToMember = (fromData, id) =>
  API.post("/board_card/add_meta_card/" + id, fromData);
export const exportCard = (id) => API.get("/board_card/export-card/" + id);
export const importCard = (card, id, boardId, listId) =>
  API.post(
    `/board_card/import-card?createdBy=${id}&boardId=${boardId}&listId=${listId}`,
    card
  );
export const createMetaCards = (id, ids) =>
  API.post("/board_card/create-meta-to-user/" + id, { ids });
//BOARD COMMENT
export const getAllComments = () => API.get("/comment/");
export const createComment = (fromData) => API.post("/comment/", fromData);
export const fetchCommentByCard = (id) => API.get("/comment/card/" + id);
export const deleteComment = (id) => API.delete("/comment/" + id);
export const getCommentById = (id) => API.get("/comment/" + id);
export const editComment = (fromData, id) =>
  API.put("/comment/" + id, fromData);
//BOARD DESCRIPTION
export const createDescription = (fromData) => API.post("/desc/", fromData);
export const createDescriptionToChilds = (fromData) =>
  API.post("/desc/to-childs", fromData);
export const fetchDescriptionByCard = (id) => API.get("/desc/card/" + id);
export const deleteDescription = (id) => API.delete("/desc/" + id);
export const getDescriptionById = (id) => API.get("/desc/" + id);
export const editDescription = (fromData, id) =>
  API.put("/desc/" + id, fromData);
export const editRelatedDescriptions = (id) => API.put("/desc/to-childs/" + id);
export const deleteRelatedDescriptions = (id) =>
  API.delete("/desc/to-childs/" + id);
//BOARD LOGS
export const getAllTaskLogs = (task) =>
  API.get(`/task_log/get_task_logs?id=${task}`);
export const createTaskLog = (fromData) => API.post("/task_log/", fromData);
export const deleteTaskLog = (fromData) =>
  API.post("/task_log/log_delete/", fromData);
export const editTaskLog = (fromData) =>
  API.post("/task_log/log_update/", fromData);
export const customTaskLog = (fromData) =>
  API.post("/task_log/customTaskLog/", fromData);
export const moveTaskLog = (fromData) =>
  API.post("/task_log/log_move", fromData);

export const getAllBoardLogs = (project) =>
  API.get(`/board_log/get_project_logs?id=${project}`);
export const updateBoardLog = (fromData) =>
  API.post("/board_log/update", fromData);
//board groups

export const createGroup = (fromData) => API.post("/group/create", fromData);
export const updateGroup = (fromData, id) =>
  API.put("/group/update/" + id, fromData);
//GET PACKS FROM API IREAD
export const getPacksFromData = () => APIiread.get("/main/show_all_pack");

//Approved
export const approveUser = (id) => API.post(`/admin/approved_user`, { id });
export const approveTeacher = (id) => API.post(`/admin/approved_user`, { id });

//Packs
export const getAllPacks = (fromData) =>
  API.get("/main/show_all_pack", fromData);
export const getPackById = (id) => API.post("/main/get_pack_details", { id });
export const editPack = (fromData) =>
  API.post("/admin/update_pack_details", fromData);
export const deletePack = (id) => API.post("/admin/delete_pack", { id });
export const createPack = (fromData) =>
  API.post("/admin/create_pack", fromData);
export const getAllBooksInPack = (id) =>
  API.post("/main/get_books_from_pack", { id });
//
export const addBookInPack = (book_id, pack_id) =>
  API.post("admin/add_book_to_pack", { book_id, pack_id });
export const deleteBookFromPack = (book_id, pack_id) =>
  API.post("admin/delete_book_from_pack", { book_id, pack_id });

//Books
export const getAllBooks = (fromData) =>
  API.get("/admin/show_all_books", fromData);
export const getBookById = (id) => API.get("/admin/get_book/" + id);
export const editBook = (fromData) => API.put("/admin/update_book", fromData);
export const deleteBook = (id) => API.post("/admin/delete_book", { id });
export const createBook = (fromData) =>
  API.post("/admin/create_book", fromData);

//
export const getSessionsFromBook = (book_id) =>
  API.post("/admin/sessions_in_book", { book_id });

//FollowedPacks

export const getAllFollowedPacks = (fromData) =>
  API.get("/admin/show_pack_follow_requests", { fromData });
export const approveFollowedPack = (fromData) =>
  API.post("/admin/approve_pack_follow_request", fromData);
export const deleteFollowedPack = (fromData) =>
  API.post("/admin/delete_follow_request", fromData);
export const rejectFollowedPack = (fromData) =>
  API.post("/admin/reject_pack_follow_request", fromData);

//FollowedSessions

export const getAllFollowedSessions = (fromData) =>
  API.get("/admin/show_session_follow_requests", { fromData });
export const approveFollowedSession = (fromData) =>
  API.post("/admin/approve_session_follow_request", fromData);
export const deleteFollowedSession = (fromData) =>
  API.post("/admin/delete_session_follow_request", fromData);
export const rejectFollowedSession = (fromData) =>
  API.post("/admin/reject_session_follow_request", fromData);

//Curriculum
export const getAllCurriculum = () => CurriculumAPI.get("/api/curriculums");
export const deleteCurriculum = (id) =>
  CurriculumAPI.delete("/api/curriculums/" + id);
export const createCurriculum = (data) =>
  CurriculumAPI.post("/api/curriculums/", data);
export const updateCurriculum = (id, data) =>
  CurriculumAPI.put("/api/curriculums/" + id, data);
export const getOneCurriculum = (id) =>
  CurriculumAPI.get("/api/curriculums/" + id);

// Subjects
export const createSubjectInCurriculum = (id, data) =>
  CurriculumAPI.post("/api/subjects/" + id, data);
export const deleteSubject = (id) =>
  CurriculumAPI.delete("/api/subjects/" + id);
export const updateSubject = (id, data) =>
  CurriculumAPI.put("/api/subjects/" + id, data);
//topics

export const createTopicInSubject = (id, data) =>
  CurriculumAPI.post("/api/topics/" + id, data);

export const deleteTopic = (id) => CurriculumAPI.delete("/api/topics/" + id);
export const updateTopic = (id, data) =>
  CurriculumAPI.put("/api/topics/" + id, data);
export const getPoints = () => CurriculumAPI.get("/api/topics/");
export const getPointsByIds = (ids) =>
  CurriculumAPI.post("/api/topics/get_points/byids", { ids });

//quiz
export const exportQuiz = ({ id }) =>
  QUIZ_API.get("/quiz/export-quiz/" + id, {
    responseType: "blob", // Ensure the response is treated as a blob
  });
export const fetchAllQuizs = () => QUIZ_API.get("/quiz/" + KEY_QUIZ);
export const fetchOneQuiz = (id) => QUIZ_API.get("/quiz/get_one/" + id);
export const deleteQuiz = (id) => QUIZ_API.delete("/quiz/" + id);
export const importQuiz = (formData) =>
  API.post("/reader/import-quiz-json", formData);
export const assignQuizToUser = (formData) =>
  API.post("/reader/assign_quiz_to_user", formData);
export const addQuizToSession = (formData) =>
  API.post("/admin/add_quiz_to_session", {
    session_id: formData.session_id,
    quiz_token: formData.quiz_token,
  });
export const deleteQuizFromSession = (formData) =>
  API.post("/admin/delete_quiz_from_session", formData);
export const getQuizsInSession = (formData) =>
  API.post("/admin/get_quiz_in_session", formData);
export const getQuizByToken = (formData) =>
  API.post("/reader/quiz_by_token", formData);
export const getResult = (token, user_id) => {
  return API.post("/reader/result", { token, user_id });
};
export const getQuiz = (quiz_id) => {
  return QUIZ_API.get("/quiz/get_one/" + quiz_id);
};
export const getQuestion = (question_id) => {
  return QUIZ_API.get("/question/" + question_id);
};
export const getQuizAnswer = (fromData) => {
  return QUIZ_API.post("/userAnswer/get_one", fromData);
};
export const getEssayQuestion = (token, user_id) =>
  API.post("/reader/get-essay-answer", {
    user_id: Number(user_id),
    token: token,
  });
export const validationEssay = (fromData) =>
  API.post("/reader/validate-essay-answer", fromData);
export const createQuiz = (fromData) =>
  QUIZ_API.post("/quiz/" + KEY_QUIZ, fromData);
export const updateQuiz = (fromData) =>
  QUIZ_API.post("/quiz/update/" + KEY_QUIZ, fromData);

export const startQuiz = (quiz_id, user_id) =>
  QUIZ_API.post("/userAnswer", { quiz_id, user_id });

export const createQuizAccount = () =>
  QUIZ_API.post("/user", { app: KEY_QUIZ });
export const submitAnswer = async (formData) => {
  return QUIZ_API.post("/userAnswer/answer", formData);
};
export const getAllCategories = () => QUIZ_API.get("/category/" + KEY_QUIZ);
export const getStudentsInMeta = (id) =>
  API.get("/board_card/user_in_card/" + id);
export const getQuizesInMeta = (formData) =>
  API.post("/board_card/quiz_in_board/", formData);
export const getLearningPath = (formData) =>
  API.post("/board_card/learning_path", formData);
export const gentrateBoardForStudent = (formData) =>
  API.post("/kanban_board/genrate_board_for_student", formData);
export const importCurriculum = (formData) =>
  CurriculumAPI.post("/api/curriculums/json", formData);

export const importQuizJson = (fromData) =>
  QUIZ_API.post("/quiz/json/" + KEY_QUIZ, fromData);

export const splitText = (fromData) => AICORE_API.post("/paser_text", fromData);
export const getWord = (word) => AICORE_API.post("/get_word", { word });
export const updateJson = (data, title) =>
  JSON_API.put("/update-json/" + title, data);
export const saveJson = (data) => JSON_API.post("/save-json", data);
export const getJson = async (title) => {
  try {
    const { data } = await JSON_API.get("/get-json/" + title);
    return data;
  } catch (error) {
    return false;
  }
};

//placment test
export const updateTest = (id, formData) =>
  API.patch("test/update/" + id, formData);

//aiAgent
export const genrateQuizToActivity = (formData) =>
  QUIZ_API.post("/aiAgent/genrate_quiz/", { ...formData, app: KEY_QUIZ });

export const genrateActivityDesc = (formData) =>
  QUIZ_API.post("/aiAgent/genrate_activity", formData);

export const genrateTemplate = (template) =>
  API.post("kanban_board/genrate_board_from_template", template);
//meeting

export const createMeeting = (meeting) => API.post("/meeting/create/", meeting);
export const startMeeting = (id) => API.get("/meeting/start/" + id);
export const getMeetings = (id) => API.get("/meeting/get-by-board/" + id);
export const getMeeting = (id) => API.post("/meeting/get-one/" + id);
