/* import { getAllBooksInPack, getAllPackDetails, getAllPacks } from "api";
import {
  GET_ALL_PAKCS,
  GET_BOOKS_IN_PACK,
  GET_PACK_DETAILS,
  LOADING,
} from "./constants";

export const fetchAllPacksAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllPacks();

    dispatch({ type: GET_ALL_PAKCS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchAllPackDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllPackDetails(id);

    dispatch({ type: GET_PACK_DETAILS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchBooksInPackAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllBooksInPack(id);

    dispatch({ type: GET_BOOKS_IN_PACK, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
 */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllPacks, getPackById, editPack, deletePack, createPack, getAllBooksInPack, addBookInPack, deleteBookFromPack, getPacksFromData } from "api";
import {
  GET_ALL_PACKS,
  GET_PACK,
  EDIT_PACK,
  DELETE_PACK,
  CREATE_PACK,
  GET_BOOKS_IN_PACK,
  DELETE_BOOKS_FROM_PACK,
  ADD_BOOKS_IN_PACK,
  LOADING,
  GET_PACK_FROM_DATA
} from "./constants";

export const getPacksFromDataAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await getPacksFromData();
    dispatch({ type: GET_PACK_FROM_DATA, payload: data.packs });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const fetchAllPacksAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllPacks();
    dispatch({ type: GET_ALL_PACKS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchPackAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const data = await getPackById(id);
      dispatch({ type: GET_PACK, payload: data?.data });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editPackAction = (fromData, handleClose, setPackId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await editPack (fromData);
    dispatch({ type: EDIT_PACK, payload: data?.data.pack });
    handleClose()
    setPackId(null)
    toast.success(data.data.message, {
         
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const deletePackAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const data = await deletePack(id);
  
      dispatch({ type: DELETE_PACK, payload: id });
      toast.success(data.data.message, {
         
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createPackAction = (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
 
      const {data} = await createPack(fromData);
      dispatch ({type : CREATE_PACK, payload: data.pack})
      handleClose()
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
        
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

  export const fetchBooksInPackAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const data = await getAllBooksInPack(id);
  
      dispatch({ type: GET_BOOKS_IN_PACK, payload: data?.data });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

  export const addBookInPackAction = (book_id , pack_id , handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
 
      const {data} = await addBookInPack(book_id , pack_id);
      dispatch ({type : ADD_BOOKS_IN_PACK, payload: data.book})
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      handleClose()
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
        
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
  export const deleteBookFromPackAction = (book_id , pack_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const data = await deleteBookFromPack(book_id , pack_id);
  
      dispatch({ type: DELETE_BOOKS_FROM_PACK, payload: book_id });
      toast.success(data.data.message, {
         
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};