import { GET_PACK_FROM_DATA }
     from "actions/constants";

const packsFromDataReducer = (state = { packsFromData: [], packFromData:{} }, action) => {
   switch (action.type) {
       case GET_PACK_FROM_DATA:
           return { ...state, packsFromData: action?.payload};
     default:
       return state;
   }
 };
 
 export default packsFromDataReducer;