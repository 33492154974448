import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllLists,
  getListById,
  editList,
  deleteList,
  createList,
  fetchListByBoard,
  updateBoardLog,
  fetchListByBoardAndFilter,
} from "api";
import {
  GET_ALL_LISTS,
  GET_LIST,
  EDIT_LIST,
  DELETE_LIST,
  CREATE_LIST,
  LOADING,
  GET_LIST_BY_BOARD,
  MOVE_TASK,
} from "./constants";

const userDataString = localStorage.getItem("userData");
const userData = JSON.parse(userDataString);
export const fetchAllListsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllLists();
    dispatch({ type: GET_ALL_LISTS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchListAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getListById(id);
    dispatch({ type: GET_LIST, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchListByBoardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchListByBoard(id);
    dispatch({ type: GET_LIST_BY_BOARD, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchListByBoardAndFilterAction =
  (id, filterOption) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await fetchListByBoardAndFilter(id, filterOption);

      dispatch({
        type: GET_LIST_BY_BOARD,
        payload: data,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
    }
  };

export const editListAction =
  (fromData, handleClose, id, idOfBoard) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      await editList(fromData, id);
      // dispatch({ type: EDIT_LIST, payload: data });
      const { data } = await fetchListByBoard(idOfBoard);
      dispatch({ type: GET_LIST_BY_BOARD, payload: data });
      handleClose();
      await updateBoardLog({
        list: id,
        project: boardId,
        user: userData._id,
        action: "List Updated",
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteListAction = (id, boardId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteList(id);
    dispatch({ type: DELETE_LIST, payload: id });

    await updateBoardLog({
      list: id,
      project: boardId,
      user: userData._id,
      action: "List Deleted",
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const createListAction = (fromData, handleClose) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await createList(fromData);
    dispatch({ type: CREATE_LIST, payload: data });
    handleClose();
    await updateBoardLog({
      list: data._id,
      project: fromData.boardId,
      user: userData._id,
      action: "List Created",
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const moveTask =
  (source, taskId, newListId, newIndex) => async (dispatch) => {
    dispatch({
      type: MOVE_TASK,
      payload: { source, taskId, newListId, newIndex },
    });
  };
