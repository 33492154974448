import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tab, Card, Nav, Breadcrumb, Button, Modal } from 'react-bootstrap';

// import sub custom components


import ArchivedTaskTable from './ArchivedTaskTable';


const ArchivedTaskPage = () => {

    return (
        <Fragment>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
                        <div className="mb-3 mb-md-0" style={{marginBottom:"5%"}}>
                            <h1 className="mb-1 h2 fw-bold">List of Archived Tasks </h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="#">Kanban Board</Breadcrumb.Item>
                                <Breadcrumb.Item active>List of Archived Tasks</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12} md={12} sm={12}>
                    <Tab.Container defaultActiveKey="all">
                        <Card>
                            <Card.Body className="p-0">
                                <Tab.Content>
                                    <Tab.Pane eventKey="all" className="pb-4" >
                                        <ArchivedTaskTable
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Card.Body>
                        </Card>
                    </Tab.Container>
                </Col>
            </Row>
            <ToastContainer />
        </Fragment>
    );
};

export default ArchivedTaskPage;
