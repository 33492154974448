import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllAssistants, getAssistantById, editAssistant, deleteAssistant, createAssistant } from "api";
import {
  GET_ALL_ASSISTANTS,
  GET_ASSISTANT,
  EDIT_ASSISTANT,
  DELETE_ASSISTANT,
  CREATE_ASSISTANT,
  LOADING,
} from "./constants";

export const fetchAllAssistantsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await getAllAssistants();
    dispatch({ type: GET_ALL_ASSISTANTS, payload: data.users});

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchAssistantAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getAssistantById(id);
      dispatch({ type: GET_ASSISTANT, payload: data.user });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editAssistantAction = (fromData, handleClose,setAssistantId, id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

   const {data} = await editAssistant (fromData, id);
   if (data.data.role == "assistant"){
    dispatch({ type: EDIT_ASSISTANT, payload: data.data });
   }else{
    dispatch({ type: DELETE_ASSISTANT, payload: id });
   }
    
handleClose()
setAssistantId()
toast.success(data.message, {
         
  autoClose: 1000,
});
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const deleteAssistantAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await deleteAssistant(id);
      dispatch({ type: DELETE_ASSISTANT, payload: id });
      
        toast.success(data.message, { autoClose: 1000,});
        
       
      
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createAssistantAction = (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const {data} = await createAssistant(fromData);
      if (data.data.role == "assistant"){
        dispatch({ type: CREATE_ASSISTANT, payload: data.data });;
       }else{ }
      
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      handleClose()
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
  