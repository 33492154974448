// import node module libraries
import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

// import layouts
import NavbarDefault from "layouts/marketing/navbars/NavbarDefault";
import { useSelector } from "react-redux";
import FooterWithLinks from "./footers/FooterWithLinks";

const DefaultLayout = (props) => {
  const { is_authenticated } = useSelector((state) => state.auth);
  return (
    <Fragment>
      <NavbarDefault login={is_authenticated} />
      <main>
        {props.children}
        <Outlet />
      </main>
      <FooterWithLinks/>
    </Fragment>
  );
};

export default DefaultLayout;
