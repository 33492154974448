import { createGroup, updateGroup } from "api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CREATE_KANBAN_GROUP,
  EDIT_KANBAN_GROUP,
  HIDE_GROUPS,
  LOADING,
} from "./constants";

export const createGroupAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await createGroup(fromData);

      dispatch({
        type: CREATE_KANBAN_GROUP,
        payload: { ...data.group, listId: fromData.listId },
      });
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editGroupAction =
  (fromData, id, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await updateGroup(fromData, id);

      dispatch({
        type: EDIT_KANBAN_GROUP,
        payload: { ...data.group, listId: fromData.listId },
      });
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const hideGroupsAction = (id) => (dispatch) => {
  dispatch({ type: HIDE_GROUPS, payload: id });
};
