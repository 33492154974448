import { PacksDetailsData } from 'data/courses/BootstrapCoursesData';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Import Typeahead CSS
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCardsInBoardAction } from 'actions/boardCard';

const AutocompleteCardTitle = ({selectedCard, setSelectedCard,setSearched}) => {
  const {id} = useParams();
	const dispatch = useDispatch();
    const {cardInBoard} = useSelector(state => state.boards)
    useEffect(() => {
        dispatch(getCardsInBoardAction(id))
      }, [dispatch, id])

  const handleSelection = (selected) => {
    if(selected.length>0){
        setSelectedCard(selected[0].title);
        setSearched(true)
        
    }
  };

  const options = [...cardInBoard];

  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="title" 
        options={options}
        placeholder="Type to search..."
        onChange={handleSelection}
      />
    </div>
  );
};

export default AutocompleteCardTitle;
