import {
  CREATE_MEETING,
  FETCH_MEETING,
  FETCH_MEETINGS,
  START_MEETING,
} from "actions/constants";

const meetingsReducer = (state = { meetings: [], meeting: {} }, action) => {
  switch (action.type) {
    case CREATE_MEETING:
      return { ...state, meeting: action.payload };
    case FETCH_MEETING:
      return { ...state, meeting: action.payload };
    case FETCH_MEETINGS:
      return { ...state, meetings: action.payload };
    case START_MEETING:
      return {
        ...state,
        meetings: state.meetings.map((meeting) => {
          if (meeting._id == action.payload) {
            return { ...meeting, start: true };
          } else {
            return meeting;
          }
        }),
      };

    default:
      return state;
  }
};
export default meetingsReducer;
