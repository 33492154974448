import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllClasses,
  getClassById,
  editClass,
  deleteClass,
  createClass,
  addClientInClass,
  getClientInClass,
  deleteClientFromClass,
  generateCode,
} from "api";
import {
  GET_ALL_CLASSES,
  GET_CLASS,
  EDIT_CLASS,
  DELETE_CLASS,
  CREATE_CLASS,
  LOADING,
  ADD_CLIENT_IN_CLASS,
  GET_CLIENT_IN_CLASS,
  DELETE_CLIENT_FROM_CLASS,
  GENERATE_CODE,
} from "./constants";

export const fetchAllClassesAction = (page, search) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllClasses(page, search);

    dispatch({ type: GET_ALL_CLASSES, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchClassAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getClassById(id);
    dispatch({ type: GET_CLASS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const editClassAction =
  (fromData, handleClose, id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await editClass(fromData, id);
      dispatch({ type: EDIT_CLASS, payload: data.data });
      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteClassAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteClass(id);
    dispatch({ type: DELETE_CLASS, payload: id });

    toast.success(data.message, { autoClose: 1000 });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const createClassAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createClass(fromData);
      dispatch({ type: CREATE_CLASS, payload: data.data });
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const AddClientInClassAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await addClientInClass(fromData);
    dispatch({ type: ADD_CLIENT_IN_CLASS, payload: data.client });
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchClientInClassAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getClientInClass(id);
    console.log(data);
    const clients = data.clients
      .map((client) => client.clientId)
      .filter((e) => e !== undefined && e !== null);
    dispatch({ type: GET_CLIENT_IN_CLASS, payload: clients });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const deleteClientFromClassAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteClientFromClass(fromData);
    const id = fromData.clientId;
    dispatch({ type: DELETE_CLIENT_FROM_CLASS, payload: id });

    toast.success(data.message, { autoClose: 1000 });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const generateCodeAction = (classId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await generateCode(classId);
    dispatch({ type: GENERATE_CODE, payload: data });
    toast.success(data.message, {
      autoClose: 1000,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
