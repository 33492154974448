// import node module libraries
import React, { useContext, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  Accordion,
  Card,
  useAccordionButton,
  AccordionContext,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
import { deleteTopicAction } from "actions/curriculum";
import { useDispatch } from "react-redux";

const Topics = ({ accordionItems, setPoint, setPointId, setShowPoint }) => {
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const handleClose = () => setShowDelete(false);
  const handleShow = (id) => {
    setIdToDelete(id);
    setShowDelete(true);
  };

  const handleDeletePoint = () => {
    dispatch(deleteTopicAction(idToDelete, setIdToDelete, handleClose));
  };

  const handleUpdatePoint = (item) => {
    setPointId(item._id);
    setShowPoint(true);
    setPoint({
      title: item.title,
      description: item.description,
      coveredPoints: item.coveredPoints,
    });
  };
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Fragment>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">
            <Link
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
              to="#"
              className="text-inherit"
            >
              <span className="align-middle p-1">{children}</span>
            </Link>
          </h5>
          <div>
            <Link
              to="#"
              className="text-inherit"
              data-bs-toggle="collapse"
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
            >
              <span className="chevron-arrow">
                <i className="fe fe-chevron-down fs-5"></i>
              </span>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  };

  //   const generateKey = (pre) => {
  //     pre = pre?.toLowerCase();
  //     pre = pre?.replace(" ", "_");
  //     return `${pre}_${new Date().getTime()}`;
  //   };
  return (
    <Fragment>
      <Accordion defaultActiveKey={accordionItems[0]?._id}>
        {accordionItems.map((item, index) => (
          <Card
            key={item?._id}
            className="px-2 py-2 mb-1 shadow-none"
            style={{ position: "relative" }}
          >
            <Card.Header className="bg-transparent border-0 p-0">
              <div className="border-0">
                <h3 className="mb-0 fw-bold">
                  <ContextAwareToggle eventKey={item?._id + index}>
                    * {item.title}
                  </ContextAwareToggle>
                </h3>
                <p>{item?.description}</p>

                <ul>
                  {item.coveredPoints.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </div>
            </Card.Header>

            <div style={{ position: "absolute", right: 30, top: 12 }}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
              >
                <Link
                  onClick={() => handleUpdatePoint(item)}
                  to="#"
                  className="me-1 text-inherit"
                  title="Edit"
                >
                  <i className="fe fe-edit fs-6"></i>
                </Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
              >
                <Link
                  to="#"
                  onClick={() => handleShow(item?._id)}
                  className="me-1 text-inherit"
                  title="Delete"
                >
                  <i className="fe fe-trash-2 fs-6"></i>
                </Link>
              </OverlayTrigger>
            </div>
          </Card>
        ))}
      </Accordion>
      <Modal
        show={showDelete}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Point</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Are you sure you want to delete thus point</p>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button onClick={() => handleDeletePoint()} variant="primary">
            Yes
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Topics;
