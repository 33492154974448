import {
  createCurriculum,
  createSubjectInCurriculum,
  createTopicInSubject,
  deleteCurriculum,
  deleteSubject,
  deleteTopic,
  getAllCurriculum,
  getOneCurriculum,
  getPoints,
  getPointsByIds,
  importCurriculum,
  updateCurriculum,
  updateSubject,
  updateTopic,
} from "api";
import {
  CREATE_CURRICULUM,
  CREATE_SUBJECT_IN_CURRIULUM,
  CREATE_TOPIC_IN_SUBJECT,
  DELETE_CURRICULUM,
  DELETE_SUBJECT,
  DELETE_TOPIC,
  FETCH_POINTS,
  FETCH_POINTS_BY_IDS,
  GET_ALL_CURRICULUM,
  GET_ONE_CURRICULUM,
  LOADING,
  UPDATE_CURRICULUM,
  UPDATE_SUBJECT,
  UPDATE_TOPIC,
} from "./constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getAllCurriculumAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllCurriculum();

    dispatch({ type: GET_ALL_CURRICULUM, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const getOneCurriculumAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getOneCurriculum(id);

    dispatch({ type: GET_ONE_CURRICULUM, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const deleteCurriculumAction =
  (id, setIdToDelete, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await deleteCurriculum(id);

      handleClose();
      setIdToDelete(null);
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: DELETE_CURRICULUM, payload: id });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  };

export const createCurriculumAction =
  (formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createCurriculum(formData);

      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: CREATE_CURRICULUM, payload: data.curriculum });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };

export const updateCurriculumAction =
  (id, formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await updateCurriculum(id, formData);

      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: UPDATE_CURRICULUM,
        payload: data.curriculum,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };

export const createSubjectInCurriculumAction =
  (id, formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createSubjectInCurriculum(id, formData);

      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: CREATE_SUBJECT_IN_CURRIULUM,
        payload: data.curriculum,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };

export const deleteSubjectAction =
  (id, setIdToDelete, handleClose, curriculumId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await deleteSubject(id);

      handleClose();
      setIdToDelete(null);
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: DELETE_SUBJECT, payload: { id, curriculumId } });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  };
export const updateSubjectAction =
  (id, formData, handleClose, curriculumId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await updateSubject(id, formData);

      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: UPDATE_SUBJECT,
        payload: { subject: data.subject, curriculumId },
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };
export const createTopicInSubjectAction =
  (id, formData, handleClose, handleSelectionPoints) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createTopicInSubject(id, formData);

      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: CREATE_TOPIC_IN_SUBJECT,
        payload: data.subject,
      });

      dispatch({ type: LOADING, payload: false });
      handleSelectionPoints([
        data.subject.topics[data.subject.topics.length - 1],
      ]);
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteTopicAction =
  (id, setIdToDelete, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await deleteTopic(id);

      handleClose();
      setIdToDelete(null);
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: DELETE_TOPIC, payload: id });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  };

export const updateTopicAction =
  (id, formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await updateTopic(id, formData);

      handleClose();
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: UPDATE_TOPIC,
        payload: data.topic,
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };

export const getPointsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getPoints();

    dispatch({
      type: FETCH_POINTS,
      payload: data,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);

    dispatch({ type: LOADING, payload: false });
  }
};

export const getPointsActionByIds = (ids) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getPointsByIds(ids);

    dispatch({
      type: FETCH_POINTS_BY_IDS,
      payload: data,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const importCurriculumAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await importCurriculum(formData);

    dispatch({
      type: CREATE_CURRICULUM,
      payload: data,
    });
    toast.success("Curriculum imported successfully", {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);

    dispatch({ type: LOADING, payload: false });
  }
};
