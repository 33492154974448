import "react-toastify/dist/ReactToastify.css";
import {
  getJson,
  getJsonList,
  getWord,
  saveJson,
  splitText,
  updateJson,
} from "api";
import { GET_JSON_list, GET_WORD, LOADING, SPLIT_STORY } from "./constants";

export const splitTextAction =
  (story, title, teacherId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const fromData = { text: story };

      const oldTask = await getJson(title + "-by-" + teacherId);

      if (!oldTask) {
        await saveJson({ title, teacherId });
      }
      const { data: spliData } = await splitText(fromData);

      if (oldTask.words) {
        const { data } = await updateJson(
          {
            title: title,
            words: [
              ...oldTask.words,
              {
                approved: 0,
                checked: 0,
                index: oldTask.words.length,
                lemma: "\n\n",
                pos: "SPACE",
              },
              ...spliData.words.map((word) => {
                return {
                  ...word,
                  index: word.index + oldTask.words.length + 1,
                };
              }),
            ],
          },
          title + "-by-" + teacherId
        );
        dispatch({ type: SPLIT_STORY, payload: data.words });
      } else {
        const { data } = await updateJson(
          { title: title, words: spliData.words },
          title + "-by-" + teacherId
        );
        dispatch({ type: SPLIT_STORY, payload: data.words });
      }

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.log(error);
    }
  };

export const getJsonAction = (title) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getJson(title);

    dispatch({ type: SPLIT_STORY, payload: data.words });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const updateJsonAction = (story) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await updateJson(story, story.title);

    dispatch({ type: SPLIT_STORY, payload: data.words });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const getWordAction = (word) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getWord(word);
    let resWord = {
      lemma: data.res.c.lemma,
      length: data.res.c.length,
      pos: data.res.c.pos,
      word: data.res.c.word,
      level: data.res.level.level,
    };
    dispatch({ type: GET_WORD, payload: resWord });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: GET_WORD, payload: null });
    dispatch({ type: LOADING, payload: false });
  }
};

