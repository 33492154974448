import {
  ADD__QUIZ,
  ADD__QUIZS_TO_SESSION,
  CLEAR__QUIZ,
  DELETE_QUIZ,
  DELETE__QUIZS_FROM_SESSION,
  GET_ALL_QUIZS,
  GET_ESSAY_QUESTIONS,
  GET_ONE_QUIZ,
  GET_RESULT_FOR_EACH_READER,
  GET_RESULT_IN_SESSION,
  GET__QUIZS_IN_SESSION,
  UPDATE_QUIZ,
  GET_RESULT,
  GET_ALL_CATEGORIES,
  GET_ALL_RESULTS_IN_TASK,
  GET_ALL_RESULTS_IN_BOARD,
  CREATE_QUIZ,
} from "actions/constants";

const quizsReducer = (
  state = {
    quizs: [],
    quizEdited: {},
    session_quizs: [],
    results: [],
    essayQuestions: [],
    resultsForEachReader: [],
    result: {},
    quiz: {
      title: "",
      desc: "",
      pass_mark: 70,
      duration: 20,
      questions: [],
    },
    quizCategories: [],
    studentResults: [],
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_QUIZS:
      return { ...state, quizs: action?.payload };
    case GET_ONE_QUIZ:
      return { ...state, quiz: action?.payload };
    case UPDATE_QUIZ:
      return {
        ...state,
        quizs: [
          ...state.quizs.filter((e) => e._id !== action.payload.id),
          action.payload,
        ],
        quizEdited: {},
      };
    case DELETE_QUIZ:
      return {
        ...state,
        quizs: state.quizs.filter((e) => e._id !== action.payload),
      };
    case GET__QUIZS_IN_SESSION:
      return { ...state, session_quizs: action?.payload };
    case GET_RESULT_FOR_EACH_READER:
      return { ...state, resultsForEachReader: action?.payload };
    case GET_ESSAY_QUESTIONS:
      return { ...state, essayQuestions: action?.payload };
    case GET_RESULT_IN_SESSION:
      return { ...state, results: action.payload };
    case ADD__QUIZS_TO_SESSION:
      return {
        ...state,
        session_quizs: [...state.session_quizs, action?.payload],
      };

    case ADD__QUIZ:
      return {
        ...state,
        quiz: action.payload,
      };
    case CLEAR__QUIZ:
      return {
        ...state,
        quiz: {
          title: "",
          desc: "",
          pass_mark: 70,
          duration: 20,
          questions: [],
        },
      };
    case GET_RESULT:
      return { ...state, result: action.payload };

    case DELETE__QUIZS_FROM_SESSION:
      return {
        ...state,
        session_quizs: state.session_quizs.filter(
          (e) => e._id !== action.payload
        ),
      };
    case GET_ALL_CATEGORIES:
      return { ...state, quizCategories: action?.payload };
    case GET_ALL_RESULTS_IN_TASK:
      return { ...state, results: action.payload };
    case GET_ALL_RESULTS_IN_BOARD:
      return { ...state, studentResults: action.payload };
    case CREATE_QUIZ:
      return { ...state, quizs: [action.payload, ...state.quizs] };
    default:
      return state;
  }
};

export default quizsReducer;
