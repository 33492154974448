// import node module libraries
import { Fragment } from 'react';

// import sub components
import HeroAcademy from './HeroAcademy';
import AcademyStats from './AcademyStats';
import BecomeAnInstructor from './BecomeAnInstructor';
import WhatCustomersSay from './WhatCustomersSay';
import HeroFormLeft from '../request-access/HeroFormLeft';
import HeroFormLeftB from '../request-access/HeroFromLeftB';
import HeroFormLeftC from '../request-access/HeroFromLeftC';


const HomeAcademy = () => {
	return (
		<Fragment>
			{/* Hero Academy banner section */}
			<HeroAcademy />
			<HeroFormLeft />
			<HeroFormLeftB/>
			<HeroFormLeftC/>
			{/* Various acedamy statistics 
			<AcademyStats /> */}

			{/* Most Popular Courses */}
			{/* <MostPopularCourses /> */}

			{/* Become an instructor */}
			<BecomeAnInstructor />

			{/* What our customers say */}
			<WhatCustomersSay />
			
		
		</Fragment>
	);
};
export default HomeAcademy;
