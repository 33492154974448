// import node module libraries
import React, { Fragment, useMemo } from "react";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { Col, Row, Card, Table } from "react-bootstrap";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import { useSelector } from "react-redux";

// import data files

const StudentResults = () => {
  const { studentResults: results } = useSelector((state) => state.quizs);

  const columns = useMemo(
    () => [
      { accessor: "quiz.title", Header: "Quiz" },
      {
        accessor: "score",
        Header: "Score",
        Cell: ({ value, row }) => {
          return (
            <div>
              <h5>
                {value}/{row.original.max_score}
              </h5>
            </div>
          );
        },
      },
      { accessor: "percentage", Header: "Percentage(%)" },
      {
        accessor: "success",
        Header: "Success",
        Cell: ({ value, row }) => {
          return (
            <div>
              {value ? (
                <h5 style={{ color: "green" }}>Passed </h5>
              ) : (
                <h5 style={{ color: "red" }}>Failed </h5>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => results, [results]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          /* 	id: 'selection',
					Header: ({ getToggleAllRowsSelectedProps }) => (
						<Checkbox {...getToggleAllRowsSelectedProps()} />
					),
					Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} /> */
        },
        ...columns,
      ]);
    }
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div className=" overflow-hidden">
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="mb-lg-0 mb-2 px-5 py-4"
                  >
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                      placeholder="Search Quizs"
                    />
                  </Col>
                </Row>
              </div>

              <div className="table-responsive ">
                <Table {...getTableProps()} className="text-nowrap">
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StudentResults;
