import {
    GET_ALL_LOGS,
    
  } from "../actions/constants";
  
  const logsReducer = (state = { logs: [],totalPages:0, total:0, currentPage:0 }, action) => {
    switch (action.type) {
      case GET_ALL_LOGS:
        return {
          ...state,
          logs: action?.payload.data,
          totalPages: action?.payload.totalPages,
          total: action.payload.total,
          currentPage: action.payload.currentPage
        };
      default:
        return state;
    }
  };
  
  export default logsReducer;
  