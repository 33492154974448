import { VIEW_SMS_CLASS } from "actions/constants";


const smsReducer = (state = { smsState:[] }, action) => {
    switch (action.type) {
        case VIEW_SMS_CLASS:
            return { ...state, smsState: action?.payload};
          
        default:
            return state;
    }
}
  
  export default smsReducer;