import { v4 as uuid } from "uuid";
/**
 *  All Dashboard Routes
 *
 *  Understanding name/value pairs for Dashboard routes
 *
 *  Applicable for main/root/level 1 routes
 *  icon 		: String - It's only for main menu or you can consider 1st level menu item to specify icon name.
 * 				: Object - Icon as an object added from v1.4.0.
 *
 *  Applicable for main/root/level 1 and subitems routes
 * 	id 			: Number - You can use uuid() as value to generate unique ID using uuid library, you can also assign constant unique ID for react dynamic objects.
 *  title 		: String - If menu contains childern use title to provide main menu name.
 *  badge 		: String - (Optional - Default - '') If you specify badge value it will be displayed beside the menu title or menu item.
 * 	badgecolor 	: String - (Optional - Default - 'primary' ) - Used to specify badge background color.
 *
 *  Applicable for subitems / children items routes
 *  name 		: String - If it's menu item in which you are specifiying link, use name ( don't use title for that )
 *  children	: Array - Use to specify submenu items
 *
 *  Used to segrigate menu groups
 *  grouptitle : Boolean - (Optional - Default - false ) If you want to group menu items you can use grouptitle = true,
 *  ( Use title : value to specify group title  e.g. COMPONENTS , DOCUMENTATION that we did here. )
 *
 */

// import MDI icons
import Icon from "@mdi/react";
import { mdiTrello, mdiCalendar } from "@mdi/js";
const userDataString = localStorage.getItem("userData");
const userData = JSON.parse(userDataString);

export const DashboardMenu = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "bluid",
    children: [
      { id: uuid(), link: "/dashboard", name: "Overview" },
      { id: uuid(), link: "/dashboard/analytics", name: "Analytics" },
    ],
  },
  {
    id: uuid(),
    title: "User management",
    icon: "user",
    children: [
      userData?.role === "admin" && {
        id: uuid(),
        link: "/users_management/admin",
        name: "Admins",
      },
      userData?.role === "admin" && {
        id: uuid(),
        link: "/users_management/assistants",
        name: "Assistants",
      },

      { id: uuid(), link: "/users_management/teachers", name: "Teachers" },
    ].filter(Boolean),
  },
  //   {
  // 	id: uuid(),
  // 	title: 'Badge management',
  // 	icon: 'aperture',
  // 	children: [
  // 		// { id: uuid(), link: '/courses/all-courses', name: 'All Courses' },
  // 		{ id: uuid(), link: '/badges_management/badges', name: 'Badges' },
  // 	]
  // },

  //   {
  // 	id: uuid(),
  // 	title: 'Classes management',
  // 	icon: 'home',
  // 	children: [
  // 		// { id: uuid(), link: '/courses/all-courses', name: 'All Courses' },
  // 		{ id: uuid(), link: '/classes_management/classes', name: 'Classes' },
  // 	]
  // },
  {
    id: uuid(),
    title: "Client management",
    icon: "user",
    children: [
      { id: uuid(), link: "/clients_management/clients", name: "Client" },
    ],
  },

  {
    id: uuid(),
    title: "Quiz management",
    icon: "archive",
    children: [
      { id: uuid(), link: "/quiz/quiz-list", name: "Quizes List" },
      { id: uuid(), link: "/quiz/create-quiz", name: "Add Quiz +" },
    ],
  },
  {
    id: uuid(),
    title: "Courses Board",
    icon: "external-link",
    children: [
      { id: uuid(), link: "/kanban_board", name: "Project List" },
      {
        id: uuid(),
        link: "/kanban_board?template=true",
        name: "Templates List",
      },
    ],
  },

  // {
  // 	id: uuid(),
  // 	title: 'SMS Management',
  // 	icon: 'mail',
  // 	children: [
  // 		{ id: uuid(), link: '/sms/one_client', name: 'Send SmS To Client', icon:'user' },
  // 		{ id: uuid(), link: '/sms/class', name: 'Send SmS To Class', icon:'users' },
  // 		{ id: uuid(), link: '/code/class', name: 'Send Code To Class', icon:'users' },
  // 		// { id: uuid(), link: '/orders/pack-subscription', name: 'Send To All Client', icon: 'home' },

  // 	]
  // },
  // {
  // 	id: uuid(),
  // 	title: 'Presence management',
  // 	icon: 'pie-chart',
  // 	children: [

  // 		//  { id: uuid(), link: '/presence-management/assistant/start-session', name: 'Start Session' },
  // 		 { id: uuid(), link: '/presence-management/check_presence', name: 'Presence Check' },

  // 	]
  // },

  // {
  // 	id: uuid(),
  // 	title: 'Assistant Notes',
  // 	icon: 'bell',
  // 	children: [
  // 		 { id: uuid(), link: '/presence-management/assistant/my_notes', name: 'My Notes' },

  // 	]
  // },
  {
    id: uuid(),
    title: "Curriculum",
    icon: "external-link",
    children: [{ id: uuid(), link: "/Curriculum", name: "Curriculum List" }],
  },
];

export default DashboardMenu;
export const DashboardTeacher = [
  // {
  // 	id: uuid(),
  // 	title: 'Presence management',
  // 	icon: 'pie-chart',
  // 	children: [
  // 		 { id: uuid(), link: '/presence-management/teacher/start-session', name: 'Start Session' },
  // 	]
  // },
  // {

  // 	id: uuid(),
  // 	title: 'Request management',
  // 	icon: 'bell',
  // 	children: [
  // 	{ id: uuid(), link: '/presence-management/teacher/my_notes', name: 'My Notes' },
  // 	]
  // },
  {
    id: uuid(),
    title: "Quiz management",
    icon: "archive",
    children: [
      { id: uuid(), link: "/quiz/quiz-list", name: "Quizes List" },
      { id: uuid(), link: "/quiz/create-quiz", name: "Add Quiz +" },
    ],
  },
  {
    id: uuid(),
    title: "Courses Board",
    icon: "external-link",
    children: [
      { id: uuid(), link: "/kanban_board_by_member", name: "Project List" },
    ],
  },
];
export const DashboardLearningArchitect = [
  {
    id: uuid(),
    title: "Quiz management",
    icon: "archive",
    children: [
      { id: uuid(), link: "/quiz/quiz-list", name: "Quizes List" },
      { id: uuid(), link: "/quiz/create-quiz", name: "Add Quiz +" },
    ],
  },
  {
    id: uuid(),
    title: "Courses Board",
    icon: "external-link",
    children: [{ id: uuid(), link: "/kanban_board", name: "Project List" }],
  },
];

export const DashboardStudent = [
  {
    id: uuid(),
    title: "Courses Board",
    icon: "external-link",
    children: [
      { id: uuid(), link: "/kanban_board_by_member", name: "Project List" },
    ],
  },
  {
    id: uuid(),
    title: "Placement Test",
    icon: "external-link",
    children: [{ id: uuid(), link: "/placment-test", name: "Start Test" }],
  },
];
