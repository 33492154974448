import { ABSENT_ACTION, GET_ALL_PRESENCE, PRESENT_ACTION } from "../actions/constants";

const presenceReducer = (state = { presences: [], presenceState: {} }, action) => {
  switch (action.type) {
    case GET_ALL_PRESENCE:
      return { ...state, presences: action?.payload };
    case PRESENT_ACTION:
        const present = state.presences.map(presenceState => {
          if (presenceState._id === action.payload._id) {
            return { ...presenceState, presence: false }; 
          }
          return presenceState;
        });
        return { ...state, presences: present };
    case ABSENT_ACTION:
        const absent = state.presences.map(presenceState => {
          if (presenceState._id === action.payload._id) {
            return { ...presenceState, presence: true }; 
          }
          return presenceState;
        });
        return { ...state, presences: absent };
    default:
      return state;
  }
};

export default presenceReducer;
