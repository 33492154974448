// import node module libraries
import { Fragment, useContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  Col,
  Row,
  Form,
  Button,
  Offcanvas,
  Dropdown,
  Modal,
} from "react-bootstrap";
// import hook file
import useTaskKanban from "hooks/useTaskKanban";
// import context file
import { TaskKanbanContext } from "context/Context";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  createListAction,
  editListAction,
  fetchListAction,
  fetchListByBoardAndFilterAction,
} from "actions/boardList";
import { MoreVertical, Trash, User } from "react-feather";
import { Avatar, AvatarGroup } from "components/elements/bootstrap/Avatar";
import { fetchBoardAction, getMembersAction } from "actions/boardKanban";
import KanbanColumnStudent from "./KanbanColumnStudent";
import BoardLogs from "../task-kanban/BoardLogs";
import AutocompleteUsers from "../task-kanban/AutoCompleteUsers";
import cursorImg from "../../../assets/images/png/cursor.png";
import { fetchResultsInBoard } from "actions/quiz";
import StudentResults from "components/marketing/student/quiz-start/StudentResults";
import LearningPathGraph from "./LearningPathGraph/LearningPathGraph";
import { fetchLearningPathAction } from "actions/boardCard";

const KanbanMember = () => {
  const { id: idOfBoard } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [listId, setListId] = useState(null);
  const [listData, setListData] = useState({
    title: "",
    boardId: idOfBoard,
    type: "",
    position: "",
  });

  useEffect(() => {
    if (listId) setShow(true);
  }, [listId]);
  useEffect(() => {
    dispatch(fetchBoardAction(idOfBoard));
  }, [idOfBoard]);

  const { lists, board } = useSelector((state) => state.boards);

  const handleAddList = async (e) => {
    if (listId) {
      dispatch(editListAction({ ...listData }, handleClose, listId, idOfBoard));
    } else {
      dispatch(createListAction({ ...listData }, handleClose));
    }
    e.preventDefault();
  };
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [assignedToLabel, setAssignedToLabel] = useState("Filter");
  const [filterOption, setFilterOption] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [searched, setSearched] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showResultsModal1, setShowResultsModal1] = useState(false);
  const handleShowAddMember = () => {
    setSelectedUser([]);
    setShowAddMember(true);
  };

  const handleClearButtonClick = () => {
    setFilterOption([]);
    setFilterType([]);
    setSearched(false);
    setIsAnyCheckboxChecked(false);
    setAssignedToLabel("Filter");

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleColorSelection = (value, checked) => {
    if (checked) {
      setIsAnyCheckboxChecked(true);
      setFilterType((prevFilterType) => [...prevFilterType, value]);
    } else {
      setFilterType((prevFilterType) =>
        prevFilterType.filter((item) => item !== value)
      );
    }
  };
  useEffect(() => {
    dispatch(fetchListByBoardAndFilterAction(idOfBoard, filterOption));
  }, [dispatch, filterOption]);

  useEffect(() => {
    if (listId) {
      dispatch(fetchListAction(listId));
    }
  }, [dispatch, listId]);
  const { list: listEdited } = useSelector((state) => state.boards);

  useEffect(() => {
    if (listEdited?._id) {
      setListData({
        title: listEdited.title,
        position: listEdited.position,
        type: listEdited.type,
      });
    }
  }, [listEdited]);

  const handleClose = () => {
    setListData({
      title: "",
      boardId: idOfBoard,
      position: "",
      type: "",
    });
    setShow(false);
    setListId(null);
    setShowForm(false);
  };

  const {
    TaskKanbanState: { taskList },
  } = useContext(TaskKanbanContext);

  const {
    handleDragEnd,
    cursors,
    showQuizModal,
    handleCloseQuiz,
    handleCreateQuizAccount,
    handlestartQuiz,
  } = useTaskKanban();

  const { members } = useSelector((state) => state.boards);
  useEffect(() => {
    dispatch(getMembersAction(idOfBoard));
  }, [dispatch, idOfBoard]);

  const [showDropdown, setShowDropdown] = useState(false);

  const handleshowResults = () => {
    setShowResultsModal(true);
    dispatch(
      fetchResultsInBoard({
        board_id: idOfBoard,
        user_id: userData._id,
        userQuiz_id: userData.quiz_id,
      })
    );
  };

  // path

  useEffect(() => {
    dispatch(
      fetchLearningPathAction({
        user_id: userData._id,
        boardId: idOfBoard,
      })
    );
  }, [dispatch, idOfBoard]);
  return (
    <Fragment>
      {Object.keys(cursors).map((userId) => {
        const { userName, x, y } = cursors[userId];

        return (
          <div
            key={userId}
            style={{
              position: "absolute",
              top: y,
              left: x,
              transform: "translate(-50%, -50%)",
              pointerEvents: "none",
              zIndex: "999",
              display: "flex",
            }}
          >
            <img width={17} height={17} src={cursorImg} />

            <div
              style={{
                color: "#593cc1",
                marginLeft: " 5px",

                whiteSpace: "nowrap",
              }}
            >
              {userName}
            </div>
          </div>
        );
      })}

      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Courses Board</h1>
            </div>
            <Button
              variant="link"
              style={{ color: "green" }}
              onClick={() => handleshowResults()}
            >
              Quiz Results
            </Button>
            <Button
              variant="link"
              style={{ color: "green" }}
              onClick={() => setShowResultsModal1(true)}
            >
              Learing Path
            </Button>
            <Col
              lg={3}
              md={3}
              sm={12}
              style={{
                marginRight: isAnyCheckboxChecked || searched ? "-60%" : "-75%",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-checkbox"
                  style={{
                    display: "flex",
                    alignItems: "center", // Center vertically
                    justifyContent: "center",
                    backgroundColor:
                      isAnyCheckboxChecked || searched
                        ? "rgba(128, 128, 128, 0.5)"
                        : "#f1f5f9",
                    border: "none",
                    color: "black",
                    width: "fit-content",
                    color: isAnyCheckboxChecked || searched ? "white" : "black",
                  }}
                >
                  Filter
                  {isAnyCheckboxChecked || searched ? (
                    <>
                      <span
                        style={{
                          position: "absolute",
                          left: 57,
                          top: 0,
                          bottom: 0,
                          width: "3px", // Adjust width as needed for the vertical line
                          backgroundColor: "white", // Color of the vertical line
                        }}
                      />
                      <Button
                        style={{
                          backgroundColor: "rgba(111, 100, 88, 0)",
                          border: "none",
                          color: "white",
                        }}
                        onClick={handleClearButtonClick}
                      >
                        <Trash size={"20px"} style={{ paddingRight: "5px" }} />
                        Clear all
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "100%" }}>
                  <Form style={{ marginLeft: "2%" }}>
                    <Form.Label>Type</Form.Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Check
                        type="checkbox"
                        id="classic"
                        value="classic"
                        onChange={(e) =>
                          handleColorSelection("classic", e.target.checked)
                        }
                      />
                      <span
                        style={{
                          backgroundColor: "#4FC1E8",
                          width: "80%",
                          height: "30px",
                          borderRadius: "4px",
                          marginLeft: "10px",
                        }}
                        // onClick={() => handleColorSelection('classic')}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        id="onlyMe"
                        value="onlyMe"
                        onChange={(e) =>
                          handleColorSelection("onlyMe", e.target.checked)
                        }
                      />
                      <span
                        style={{
                          backgroundColor: "#FFCE54",
                          width: "80%",
                          height: "30px",
                          borderRadius: "4px",
                          marginLeft: "10px",
                        }}
                        // onClick={() => handleColorSelection('onlyMe')}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        id="meta"
                        value="meta"
                        onChange={(e) =>
                          handleColorSelection("meta", e.target.checked)
                        }
                      />
                      <span
                        style={{
                          backgroundColor: "#ED5564",
                          width: "80%",
                          height: "30px",
                          borderRadius: "4px",
                          marginLeft: "10px",
                        }}
                        // onClick={() => handleColorSelection('meta')}
                      />
                    </div>
                  </Form>
                  <hr />
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                {showAddMember ? (
                  <AutocompleteUsers
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    setShowAddMember={setShowAddMember}
                  />
                ) : (
                  <>
                    <div className="d-flex align-items-center">
                      <Dropdown
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                      >
                        <AvatarGroup className="me-0">
                          {members
                            ?.slice(
                              0,
                              members?.length > 6 ? 6 : members?.length
                            )
                            ?.map((member, index) => (
                              <Avatar
                                key={index}
                                size="md"
                                type="initial"
                                name={`${member?.name
                                  ?.split(" ")[0]
                                  ?.charAt(0)}${member?.name
                                  ?.split(" ")[1]
                                  ?.charAt(0)}`}
                                variant="success"
                                className="rounded-circle text-white"
                                showExact
                              />
                            ))}
                          {members?.length > 6 && (
                            <Avatar
                              size="md"
                              type="initial"
                              name="7+"
                              variant="light"
                              className="rounded-circle text-black"
                              showExact
                            />
                          )}
                        </AvatarGroup>

                        <Dropdown.Menu show={showDropdown}>
                          {members?.map((member, index) => (
                            <Dropdown.Item key={index}>
                              {" "}
                              <User size="16px" style={{ marginLeft: "3px" }} />
                              {member.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* icon */}
                      {board?.owner?._id == userData?._id && (
                        <Link
                          onClick={() => handleShowAddMember()}
                          to="#"
                          className="btn btn-icon btn-white border border-2 rounded-circle btn-dashed ms-2"
                        >
                          +
                        </Link>
                      )}
                    </div>
                  </>
                )}
                {/* <MoreVertical
                  style={{ marginLeft: "10px", zIndex: 999 }}
                  onClick={() => handleShowLog()}
                /> */}
              </div>
              {/* <div className="d-flex align-items-center">
                <Dropdown
                  onMouseEnter={() => setShowDropdown(true)}
                  onMouseLeave={() => setShowDropdown(false)}
                >
                  <AvatarGroup className="me-0">
                    {members
                      ?.slice(0, members?.length > 6 ? 6 : members?.length)
                      ?.map((member, index) => (
                        <Avatar
                          key={index}
                          size="md"
                          type="initial"
                          name={`${member.name
                            .split(" ")[0]
                            .charAt(0)}${member?.name
                            ?.split(" ")[1]
                            .charAt(0)}`}
                          variant="success"
                          className="rounded-circle text-white"
                          showExact
                        />
                      ))}
                    {members?.length > 6 && (
                      <Avatar
                        size="md"
                        type="initial"
                        name="7+"
                        variant="light"
                        className="rounded-circle text-black"
                        showExact
                      />
                    )}
                  </AvatarGroup>

                  <Dropdown.Menu show={showDropdown}>
                    {members?.map((member, index) => (
                      <Dropdown.Item key={index}>
                        {" "}
                        <User size="16px" style={{ marginLeft: "3px" }} />
                        {member.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </div>
          </div>
        </Col>
      </Row>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div
          className="task-kanban-container pb-6"
          style={{ overflowX: "auto", whiteSpace: "nowrap" }}
        >
          {lists?.map((item, index) => {
            // if (
            //   userData?.role === "student" &&
            //   item.title === "Product Backlog"
            // ) {
            //   return null;
            // }

            return (
              <div
                key={index}
                style={{ display: "inline-block", marginRight: "10px" }}
              >
                <KanbanColumnStudent
                  columnData={item}
                  setListId={setListId}
                  listId={listId}
                  filterType={filterType}
                  selectedCard={selectedCard}
                  searched={searched}
                />
              </div>
            );
          })}
        </div>
      </DragDropContext>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {listId ? "Update List" : "Add New List"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="List Title"
                id="title"
                required
                value={listData.title}
                onChange={(e) =>
                  setListData({ ...listData, title: e.target.value })
                }
              />
            </Form.Group>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                id="title"
                required
                value={listData.type}
                onChange={(e) =>
                  setListData({ ...listData, type: e.target.value })
                }
              >
                <option value="">Select Type</option>
                <option value="product">Product</option>
                <option value="sprint">Sprint</option>
                <option value="doing">Doing</option>
                <option value="done">Done</option>
              </Form.Select>
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                // disabled={loading}
                onClick={(e) => handleAddList(e)}
              >
                {listId ? "Update List" : "Add New List"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            <li onClick={() => setShowLogs(true)}>LOGS</li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <BoardLogs show={showLogs} setShow={setShowLogs} />
      <Modal show={showQuizModal} onHide={() => handleCloseQuiz()}>
        <Modal.Header closeButton>
          <Modal.Title>Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {userData?.quiz_id
              ? "Start quiz"
              : "Please sign in to our quiz system to take your first test."}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseQuiz()}>
            Cancel
          </Button>
          {!userData?.quiz_id ? (
            <Button variant="primary" onClick={handleCreateQuizAccount}>
              Sign in
            </Button>
          ) : (
            <Button variant="primary" onClick={handlestartQuiz}>
              Start
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        fullscreen={true}
        show={showResultsModal}
        onHide={() => setShowResultsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Quiz Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StudentResults />
        </Modal.Body>
      </Modal>

      <Modal
        fullscreen={true}
        show={showResultsModal1}
        onHide={() => setShowResultsModal1(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Learning path graph</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LearningPathGraph />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default KanbanMember;
