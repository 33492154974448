// import node module libraries
import { Fragment, useEffect, useState } from "react";
import {
  Card,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Col,
} from "react-bootstrap";
// import custom components
import GKAccordionActions from "components/marketing/common/accordions/GKAccordionActions";

// import data files

import { useSelector, useDispatch } from "react-redux";
import {
  createCurriculumAction,
  createSubjectInCurriculumAction,
  createTopicInSubjectAction,
  deleteCurriculumAction,
  getAllCurriculumAction,
  importCurriculumAction,
  updateCurriculumAction,
  updateTopicAction,
} from "actions/curriculum";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { ToastContainer } from "react-toastify";

const AddSection = ({
  showSubject,
  setShowSubject,
  curriculumData,
  setCurriculumData,
  curriculumId,
  setCurriculumId,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowSubject(false);
    setCurriculumData({
      title: "",
      description: "",
    });
    setCurriculumId(null);
  };
  const handleShow = () => setShowSubject(true);
  const handleCreateCurriclum = () => {
    if (curriculumId) {
      dispatch(
        updateCurriculumAction(curriculumId, curriculumData, handleClose)
      );
    } else {
      dispatch(createCurriculumAction(curriculumData, handleClose));
    }
  };

  return (
    <Fragment>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary btn-sm mt-3"
        onClick={handleShow}
      >
        Add Subject
      </Button>
      <Modal
        show={showSubject}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {curriculumId ? "Update Subject" : "Add New Subject"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="formaddnewsection">
            <Form.Control
              type="text"
              placeholder="title"
              value={curriculumData.title}
              onChange={(e) =>
                setCurriculumData({
                  ...curriculumData,
                  title: e.target.value,
                })
              }
            />
            <Form.Control
              className="mt-3"
              type="text"
              placeholder="description"
              value={curriculumData.description}
              onChange={(e) =>
                setCurriculumData({
                  ...curriculumData,
                  description: e.target.value,
                })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button
            variant="primary"
            onClick={() => handleCreateCurriclum(handleClose)}
          >
            {curriculumId ? "Update Subject" : "Add New Subject"}
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const AddLecture = ({
  showTopic,
  setShowTopic,
  subjectData,
  setSubjectData,
  id,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowTopic(false);
    setSubjectData({
      title: "",
      description: "",
    });
  };

  const handleShow = () => setShowTopic(true);

  const handleCreateSubject = () => {
    dispatch(createSubjectInCurriculumAction(id, subjectData, handleClose));
  };
  return (
    <Fragment>
      <Button
        variant="outline-primary"
        className="btn btn-outline-primary btn-sm mt-3"
        onClick={handleShow}
      >
        Add Topic +
      </Button>
      <Modal
        show={showTopic}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="formaddnewlecture">
            <Form.Control
              type="text"
              placeholder="title"
              value={subjectData.title}
              onChange={(e) =>
                setSubjectData({ ...subjectData, title: e.target.value })
              }
            />
            <Form.Control
              type="text"
              className="mt-3"
              placeholder="description"
              value={subjectData.description}
              onChange={(e) =>
                setSubjectData({ ...subjectData, description: e.target.value })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button onClick={() => handleCreateSubject()} variant="primary">
            Add New Topic
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
//point
const AddPoint = ({
  subjectId,
  showPoint,
  pointId,
  setPointId,
  setShowPoint,
  addSubPoint,
  handleSubPointChange,
  deleteSubPoint,
  point,
  setPoint,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowPoint(false);
    setPoint({
      title: "",
      description: "",
      coveredPoints: [],
    });
    setPointId(null);
  };

  const handleCreateSubject = () => {
    if (pointId) {
      dispatch(updateTopicAction(pointId, point, handleClose));
    } else {
      dispatch(createTopicInSubjectAction(subjectId, point, handleClose));
    }
  };
  return (
    <Fragment>
      <Modal
        show={showPoint}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {pointId ? "Update Point" : "Add New Point"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="formaddnewPoint">
            <Form.Control
              type="text"
              placeholder="Title"
              value={point.title}
              name="point title"
              onChange={(e) => setPoint({ ...point, title: e.target.value })}
            />
            <Form.Control
              type="text"
              className="mt-3"
              placeholder="Description"
              value={point.description}
              name="point desc"
              onChange={(e) =>
                setPoint({ ...point, description: e.target.value })
              }
            />
          </Form.Group>
          {point?.coveredPoints?.map((subPoint, index) => (
            <div key={index} className="d-flex align-items-center">
              <Form.Control
                type="text"
                className="mt-3"
                placeholder={`Sub Point ${index + 1}`}
                value={subPoint}
                onChange={(e) => handleSubPointChange(index, e.target.value)}
              />
              <Button
                variant="outline-danger"
                className="ms-2 "
                style={{ marginTop: "16px" }}
                onClick={() => deleteSubPoint(index)}
              >
                Delete
              </Button>
            </div>
          ))}
          <Button
            variant="secondary"
            className="btn btn-secondary btn-sm mt-3"
            onClick={addSubPoint}
          >
            Add Sub Point +
          </Button>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button onClick={() => handleCreateSubject()} variant="primary">
            {pointId ? "Update Point" : "Add New Point"}
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
const Curriculum = () => {
  // componeents
  const dispatch = useDispatch();
  const { curriculum } = useSelector((state) => state.curriculum);
  const { loading } = useSelector((state) => state.auth);
  const [showSubject, setShowSubject] = useState(false);
  useEffect(() => {
    dispatch(getAllCurriculumAction());
  }, []);
  //create curriculum
  const [curriculumData, setCurriculumData] = useState({
    title: "",
    description: "",
  });
  const [curriculumId, setCurriculumId] = useState(null);
  //delete curriculum

  const [showDelete, setShowDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const handleClose = () => setShowDelete(false);
  const handleShow = (id) => {
    setIdToDelete(id);
    setShowDelete(true);
  };

  //create subject

  const [subjectData, setSubjectData] = useState({
    title: "",
    description: "",
  });

  const [showTopic, setShowTopic] = useState(false);

  const handleDeleteCurriclum = () => {
    dispatch(deleteCurriculumAction(idToDelete, setIdToDelete, handleClose));
  };
  const overlayKeyEdit = uuid();
  const overlayKeyDelete = uuid();

  ///  points mangment

  const [point, setPoint] = useState({
    title: "",
    description: "",
    coveredPoints: [],
  });

  const [subjectId, setSubjectId] = useState(null);
  const [showPoint, setShowPoint] = useState(false);
  const [pointId, setPointId] = useState(null);

  const addSubPoint = () => {
    setPoint({
      ...point,
      coveredPoints: [...point.coveredPoints, ""],
    });
  };
  const handleSubPointChange = (index, value) => {
    const updatedCoveredPoints = point.coveredPoints.map((coveredPoint, i) =>
      i === index ? value : coveredPoint
    );
    setPoint({
      ...point,
      coveredPoints: updatedCoveredPoints,
    });
  };

  const deleteSubPoint = (index) => {
    const updatedCoveredPoints = point.coveredPoints.filter(
      (coveredPoint, i) => i !== index
    );
    setPoint({
      ...point,
      coveredPoints: updatedCoveredPoints,
    });
  };
  // upload curriculum
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a JSON file");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(importCurriculumAction(formData));
    }
  };
  return (
    <Form>
      {/* Card */}
      <Card className="mb-3  border-0">
        <Card.Header className="border-bottom px-4 py-3">
          <h4 className="mb-0">Curriculum</h4>
          <Col className="d-flex justify-content-end">
            <Button
              variant="primary"
              disabled={loading}
              style={{ margin: "0 15px 15px 0", width: "40%" }}
              onClick={handleUpload}
            >
              Import Curriculum
            </Button>
            <div>
              <input
                className="form-control"
                type="file"
                accept=".json"
                onChange={handleFileChange}
              />
            </div>
          </Col>
        </Card.Header>
        {/* Card body */}
        <Card.Body>
          {curriculum.map((item) => {
            return (
              <div
                className="bg-light rounded p-2 mb-4"
                style={{ position: "relative" }}
                key={item._id}
              >
                <h4>{item?.title}</h4>
                <p>{item?.description}</p>
                {/* Item list */}
                <GKAccordionActions
                  curriculumId={item?._id}
                  accordionItems={item?.subjects}
                  setSubjectId={setSubjectId}
                  setShowPoint={setShowPoint}
                  setPoint={setPoint}
                  setPointId={setPointId}
                />
                <div style={{ position: "absolute", right: 10, top: 10 }}>
                  <OverlayTrigger
                    key={overlayKeyEdit}
                    placement="top"
                    overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
                  >
                    <Link
                      onClick={() => {
                        setShowSubject(true);
                        setCurriculumData({
                          title: item.title,
                          description: item.description,
                        });
                        setCurriculumId(item._id);
                      }}
                      to="#"
                      className="me-1 text-inherit"
                      title="Edit"
                    >
                      <i className="fe fe-edit fs-6"></i>
                    </Link>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key={overlayKeyDelete}
                    placement="top"
                    overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
                  >
                    <Link
                      to="#"
                      className="me-1 text-inherit"
                      onClick={() => handleShow(item._id)}
                      title="Delete"
                    >
                      <i className="fe fe-trash-2 fs-6"></i>
                    </Link>
                  </OverlayTrigger>
                </div>
                <AddLecture
                  showTopic={showTopic}
                  setShowTopic={setShowTopic}
                  subjectData={subjectData}
                  setSubjectData={setSubjectData}
                  id={item?._id}
                />
              </div>
            );
          })}

          <AddSection
            showSubject={showSubject}
            setShowSubject={setShowSubject}
            curriculumData={curriculumData}
            setCurriculumData={setCurriculumData}
            curriculumId={curriculumId}
            setCurriculumId={setCurriculumId}
          />
        </Card.Body>
      </Card>
      <Modal
        show={showDelete}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Curriculum</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Are you sure you want to delete thus curriclum</p>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button onClick={handleDeleteCurriclum} variant="primary">
            Yes
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <AddPoint
        subjectId={subjectId}
        showPoint={showPoint}
        setShowPoint={setShowPoint}
        pointId={pointId}
        setPointId={setPointId}
        addSubPoint={addSubPoint}
        handleSubPointChange={handleSubPointChange}
        deleteSubPoint={deleteSubPoint}
        point={point}
        setPoint={setPoint}
      />
      <ToastContainer />
    </Form>
  );
};
export default Curriculum;
