// import node module libraries
import { Fragment } from "react";
import { Form, Button, Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";

const ImportNewBoard = ({ handleFileChange, show, setShow, handleUpload }) => {
  const { loading } = useSelector((state) => state.auth);
  return (
    <Fragment>
      {/* <Button variant="primary" onClick={() => handleShow()}>
        {template === "true" ? "Add New Template" : "Add New Board"}
      </Button> */}

      <Offcanvas show={show} onHide={() => setShow(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Upload Board</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label></Form.Label>
              <input
                className="form-control"
                type="file"
                accept=".json"
                onChange={handleFileChange}
              />
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                disabled={loading}
                onClick={handleUpload}
              >
                Import Board
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default ImportNewBoard;
