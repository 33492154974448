import { getAllFollowedSessions, approveFollowedSession, deleteFollowedSession, rejectFollowedSession } from "api";
import {
  GET_ALL_FOLLOWED_SESSIONS,
  APPROVE_FOLLOWED_SESSION,
  DELETE_FOLLOWED_SESSION,
  REJECT_FOLLOWED_SESSION,
  LOADING
} from "./constants";


  export const fetchAllFollowedSessionsAction = (fromData) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getAllFollowedSessions(fromData);
      dispatch({ type: GET_ALL_FOLLOWED_SESSIONS, payload: data.session_follow_requests });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const ApproveFollowedSessionAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
   
    await approveFollowedSession(fromData);
   
    dispatch({ type: APPROVE_FOLLOWED_SESSION, payload: fromData });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
      }
  }
};
export const deleteFollowedSessionRequestAction = (fromData) => async (dispatch) => {
  try {
    
    dispatch({ type: LOADING, payload: true });
    await deleteFollowedSession(fromData);

    dispatch({ type: DELETE_FOLLOWED_SESSION, payload: fromData });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
  }
};
export const RejectFollowedSessionAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
   
    await rejectFollowedSession(fromData);
  
    dispatch({ type: REJECT_FOLLOWED_SESSION, payload: fromData });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
      }
  }
};