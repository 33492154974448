// import node module libraries
import React, { Fragment, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Link, useParams } from "react-router-dom";
import { Dropdown, Card, Button, Modal, Form } from "react-bootstrap";
// import sub custom components
import { deleteListAction } from "actions/boardList";
import { useDispatch } from "react-redux";
import { createCardWithStudentAction } from "actions/boardCard";
import KanbanTaskStudent from "./KanbanTask";

const KanbanColumnStudent = (props) => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { id: idOfBoard } = useParams();
  const dispatch = useDispatch();
  const { columnData, setListId, filterType, selectedCard, searched } = props;
  const [showModal, setShowModal] = useState(false);
  const [idOfList, setIdOfList] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [cardId, setCardId] = useState(false);
  const handleShowModal = (id) => {
    setIdOfList(id);
    setShowForm(true);
  };
  const handleDeleteList = (id) => {
    setListToDelete(id);
    setShowDeleteModal(true);
  };
  const confirmDeleteList = () => {
    if (listToDelete && confirmationMessage === "DELETE LIST") {
      dispatch(deleteListAction(listToDelete, idOfBoard));
      setListToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  //ADD CARD

  const [cardData, setCardData] = useState({
    title: "",
    description: "",
    listId: idOfList,
    boardId: idOfBoard,
    assignedTo: null,
    dueDate: "",
    position: "",
    border: "#4FC1E8",
    createdBy: null,
  });
  const handleAddTask = async (e) => {
    dispatch(
      createCardWithStudentAction(
        {
          ...cardData,
          listId: idOfList,
          boarId: idOfBoard,
          createdBy: userData._id,
          // prevCardId:
          //   columnData.cards[columnData?.cards?.length - 1]?._id || null,
          // type: "onlyMe",
        },
        handleClose,
        idOfBoard
      )
    );

    e.preventDefault();
  };
  const handleClose = () => {
    setCardData({
      title: "",
      description: "",
      listId: idOfList,
      boardId: idOfBoard,
      assignedTo: null,
      dueDate: "",
      position: "",
      border: "#4FC1E8",
      createdBy: null,
    });
  };
  const handleCloseX = () => {
    setCardData({
      title: "",
      description: "",
      listId: idOfList,
      boardId: idOfBoard,
      assignedTo: null,
      dueDate: "",
      position: "",
      border: "#4FC1E8",
      createdBy: null,
    });
    setShowForm(false);
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <Dropdown drop="start">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-horizontal fs-4"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="start">
          <Dropdown.Item
            eventKey="1"
            className="d-flex align-items-center"
            onClick={() => setListId(columnData?._id)}
          >
            <i className="dropdown-item-icon fe fe-users"></i> Edit List
          </Dropdown.Item>
          {/* <Dropdown.Item eventKey="2" className="d-flex align-items-center">
						<i className="dropdown-item-icon fe fe-user-x"></i> Manage
					</Dropdown.Item> */}
          {/* <Dropdown.Item eventKey="3" className="d-flex align-items-center">
						<i className="dropdown-item-icon fe fe-clipboard"></i> Copy Column
						link
					</Dropdown.Item> */}
          {/* <Dropdown.Item eventKey="4" className="d-flex align-items-center">
						<i className="dropdown-item-icon fe fe-edit"></i> Archive All Cards
					</Dropdown.Item> */}
          {userData?._id === columnData?.boardId?.owner ||
          userData?.role === "admin" ||
          userData?.role === "learning-architect" ? (
            <Dropdown.Item
              eventKey="5"
              className="d-flex align-items-center"
              onClick={() => handleDeleteList(columnData?._id)}
            >
              <i className="dropdown-item-icon fe fe-trash-2"></i> Delete List
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Fragment>
      <Card
        className="card bg-gray-200 shadow-none rounded-3 d-inline-block me-4 align-top mb-4 task-card"
        style={{
          height:
            showForm === false && columnData.cards.length === 0
              ? "100px"
              : columnData.cards.length === 0
              ? "150px"
              : "auto",
        }}
      >
        <Card.Body className="card-body p-3">
          {/* task list */}
          <div className="task-list">
            {/* content */}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                {/* column heading */}
                <h4 className="mb-0">{columnData?.title}</h4>
              </div>
              <div className="d-flex align-items-center">
                {/* dropdown */}
                <ActionMenu />
              </div>
            </div>

            {/* task list */}
            <div className="task-kanban">
              <Droppable droppableId={`${columnData?._id}`}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="tasks-container"
                    {...provided.droppableProps}
                  >
                    {columnData?.cards
                      ?.filter(
                        (task) =>
                          !task?.archived &&
                          ((task?.type === "meta" &&
                            task?.assignedTo?._id == userData?._id) ||
                            task.type !== "meta") &&
                          ((task?.type === "onlyMe" &&
                            task?.assignedTo?._id == userData?._id) ||
                            task.type !== "onlyMe") &&
                          (filterType.includes(task.type) ||
                            filterType.length === 0)
                        // &&
                        // (selectedCard.includes(task.title) || selectedCard.length === 0)
                      )
                      ?.map((task, index) => (
                        <KanbanTaskStudent
                          task={task}
                          key={task._id}
                          index={index}
                          cardId={cardId}
                          setCardId={setCardId}
                          showModal={showModal}
                          setShowModal={setShowModal}
                        />
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div
              style={{
                position: "relative",
                top: columnData.cards.length === 0 ? "-110px" : "0",
              }}
            >
              {/* button */}
              {showForm ? (
                <div>
                  <Form onSubmit={(e) => handleAddTask(e)}>
                    <Form.Group className="mb-1">
                      <Form.Control
                        type="text"
                        placeholder="Add Card Title"
                        id="title"
                        value={cardData?.title}
                        required
                        onChange={(e) =>
                          setCardData({ ...cardData, title: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Form>
                  <Button variant="info" onClick={(e) => handleAddTask(e)}>
                    Add a card
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid transparent",
                      color: "black",
                    }}
                    onClick={handleCloseX}
                  >
                    X
                  </Button>
                </div>
              ) : (
                <div className="d-grid">
                  <Button
                    type="button"
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => handleShowModal(columnData._id)}
                  >
                    <i className="fe fe-plus-circle me-1"></i>Add Card
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this list?</p>
          <h4 className="text-secondary">
            you must write "DELETE LIST" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteList}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default KanbanColumnStudent;
