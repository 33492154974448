import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import AvatarCom from "assets/images/avatar/avatar_com.webp";
import { reformatCommentDate } from "helper/utils";
import { Archive } from "react-feather";
import { Avatar } from "components/elements/bootstrap/Avatar";
import TaskDescription from "../task-kanban/KanbanTaskDesc";
import { fetchOneQuizAction } from "actions/quiz";
import { useParams } from "react-router-dom";
import { createCommentAction } from "actions/boardComment";
import { fetchBoardAction } from "actions/boardKanban";
import { fetchCardAction } from "actions/boardCard";

const TaskDetails = ({ showDetailsModal, setShowDetailsModal, cardId }) => {
  const { id: idOfBoard } = useParams();
  const dispatch = useDispatch();
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [idOfCard, setIdOfCard] = useState(null);
  const { loading } = useSelector((state) => state.auth);
  const { card, board } = useSelector((state) => state.boards);
  const [commentText, setCommentText] = useState("");

  const [commentData, setCommentData] = useState({
    text: "",
    cardId: idOfCard,
    authorId: userData._id,
  });
  const [showComments, setShowComments] = useState(false);
  const [isCommenting, setIsCommenting] = useState(false);
  const buttonText = "Add Comment";
  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const inputRef = useRef(null);

  const handleButtonClick = () => {
    // Scroll to the input field
    inputRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleCloseComment = () => {
    setCommentData({
      text: "",
      cardId: idOfCard,
      authorId: userData._id,
    });
    setIsCommenting(false);
    setCommentText("");
  };
  const handleSaveComment = (e) => {
    dispatch(createCommentAction(commentData, handleCloseComment, idOfBoard));
    setShowComments(true);
    e.preventDefault();
  };
  const { quiz } = useSelector((state) => state.quizs);
  useEffect(() => {
    if (card?.quizId) {
      dispatch(fetchOneQuizAction(card?.quizId));
    }
  }, [card]);

  useEffect(() => {
    if (idOfBoard) {
      dispatch(fetchBoardAction(idOfBoard));
    }
  }, [idOfBoard]);
  useEffect(() => {
    if (card) {
      setCommentData((prevState) => ({
        ...prevState,
        cardId: card._id,
      }));
    }
  }, [card?._id]);
  useEffect(() => {
    if (userData) {
      setCommentData((prevState) => ({
        ...prevState,
        authorId: userData._id,
      }));
    }
  }, []);

  return (
    <div>
      <Modal
        fullscreen={true}
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        // className='modal-dialog modal-lg mt-40 mb-30'
        style={{ marginRight: "1%", overflowY: "scroll" }}
      >
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ width: "76%" }}>
            <Modal.Title style={{ fontSize: "14px" }}>
              <Archive /> {card?.boardId?.title}
              <Form.Control
                as="textarea"
                rows="1"
                style={{
                  marginTop: "1%",
                  height: "fit-content",
                  width: "100%",
                  fontSize: "18px",
                  color: "black",
                  border: "none",
                  outline: "none",
                  fontWeight: "bold",
                }}
                value={card?.title}
                readOnly
              ></Form.Control>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  marginBottom: "2%",
                  marginTop: "1%",
                }}
              >
                <Avatar
                  size="md"
                  src={AvatarCom}
                  type="image"
                  className="rounded-circle"
                />
                <Form.Control
                  style={{
                    width: "20%",
                    border: "none",
                    marginLeft: "1%",
                    outline: "none",
                  }}
                  value={card?.assignedTo?.name}
                  readOnly
                ></Form.Control>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2%",
                    marginLeft: "2%",
                    paddingBottom: "2%",
                  }}
                >
                  <i className="fe fe-message-square fs-6 text-muted"></i>{" "}
                  <span
                    className="fw-semi-bold fs-6"
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  >
                    {card?.comments?.length} Comments
                  </span>
                  <Button
                    variant="secondary"
                    onClick={handleButtonClick}
                    style={{
                      padding: "0.25rem 0.5rem",
                      fontSize: buttonText.length > 10 ? "0.75rem" : "1rem",
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
                <div
                  style={{
                    marginLeft: "2%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong>Due Date:</strong>
                  <input
                    style={{
                      width: "60%",
                      border: "none",
                      outline: "none",
                    }}
                    type="date"
                    className="form-control"
                    value={card.dueDate ? card.dueDate.split("T")[0] : ""}
                  />
                </div>

                {card?.type === "onlyMe" && (
                  <Form.Check
                    style={{
                      marginLeft: "1%",
                      width: "130px",
                    }}
                    type="checkbox"
                    label="Invite Teacher"
                    checked={card.inviteTeacher}
                  />
                )}
                {card.quizId && (
                  <div>
                    <h5
                      style={{
                        width: "320px ",
                        position: "relative",
                      }}
                    >
                      <strong>Quiz:</strong> {" " + quiz?.title}
                      <i
                        style={{
                          position: "absolute",
                          zIndex: 999,
                          right: -60,
                          top: 5,
                          cursor: "pointer",
                          display: "flex",
                          color: "green",
                        }}
                        onClick={() =>
                          navigate(
                            `/quiz/quiz-finish?quiz_id=${quiz._id}&user_id=${userData.quiz_id}`
                          )
                        }
                        className="fe fe-file-text fs-6 text-muted"
                      >
                        <p
                          style={{
                            fontFamily: "cursive",
                            fontSize: 14,
                            color: "green",
                            marginLeft: 5,
                          }}
                        >
                          result
                        </p>
                      </i>
                    </h5>
                  </div>
                )}
              </div>
            </Modal.Title>
          </div>
          <div style={{ width: "28%", marginLeft: "20%", marginTop: "5%" }}>
            <span className="align-middle">
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* Button Container */}
                <button
                  disabled={loading}
                  className="btn btn-info"
                  // onClick={handleAddTask}
                  style={{
                    backgroundColor: "red",
                    marginRight: "2%",
                    borderColor: "red",
                    width: "fit-content",
                  }}
                >
                  {loading ? (
                    <Spinner style={{ height: "15px", width: "15px" }} />
                  ) : (
                    <>
                      <i
                        className="fe fe-save"
                        style={{ marginRight: "5px" }}
                      ></i>
                      Save and Close
                    </>
                  )}
                </button>
              </div>
            </span>
          </div>
        </Modal.Header>
        <div className="modal-content">
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ width: "68%" }}>
              <TaskDescription card={card} board={board} />
              <hr />
              <p>
                <strong>Discussion:</strong>{" "}
                <button
                  onClick={toggleComments}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  {showComments ? "↑" : "↓"}
                </button>
              </p>
              <div
                className="form-group"
                ref={inputRef}
                style={{ paddingBottom: "1%" }}
              >
                <Form onSubmit={(e) => handleSaveComment(e)}>
                  <Form.Control
                    type="text"
                    placeholder="Add a comment"
                    // onClick={handleInputClick}
                    style={{
                      height: "fit-content",
                      marginTop: "3%",
                      height: "fit-content",
                      color: "black",
                      border: "none",
                      outline: "none",
                    }}
                    value={commentData?.text}
                    onChange={(e) => {
                      setCommentData({ ...commentData, text: e.target.value });
                      setCommentText(e.target.value);
                    }}
                  ></Form.Control>
                </Form>
              </div>
              {isCommenting && (
                <div style={{ marginTop: "2%", paddingBottom: "3%" }}>
                  <button
                    className="btn btn-info"
                    onClick={(e) => handleSaveComment(e)}
                    disabled={!commentText.trim()}
                    style={{
                      backgroundColor: commentText.trim() ? "" : "#D6D6D6",
                      marginRight: "2%",
                      borderColor: commentText.trim() ? "" : "#D6D6D6",
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => handleCloseComment()}
                    style={{
                      backgroundColor: "#D6D6D6",
                      borderColor: "#D6D6D6",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {showComments &&
                card?.comments?.map((comment) => (
                  <div
                    key={comment?._id}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <p style={{ fontWeight: "bold" }}>
                            {comment?.authorId?.name}
                          </p>
                        </div>
                        <div style={{ marginLeft: "3%" }}>
                          <p>
                            <u>
                              <small>
                                {reformatCommentDate(
                                  comment?.createdAt
                                ).toLocaleString()}
                              </small>
                            </u>
                          </p>
                        </div>
                      </div>
                      <p> {comment?.text}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ width: "25%" }}>
              <p>
                <strong>Last update</strong>{" "}
                {new Date(card?.updatedAt).toLocaleString()}
              </p>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default TaskDetails;
