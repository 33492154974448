// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// import sub components
import ClientTable from './ClientTable';
import AddNewClient from './AddNewClient';
import { fetchAllClientsAction, generateExcelFileAction, getClientFromDataAction } from 'actions/client';
import SpinnerLoading from '../SpinnerLoading';
import { useDispatch } from 'react-redux';



const ClientPage = () => {
	const dispatch = useDispatch();
	const [pageNumber, setPageNumber] = useState(1);
	const [globalFilter, setGlobalFilter] = useState("");
	const [clientId, setClientId] = useState(null)
	//calendar
	const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
	useEffect(() => {
		if (clientId) setShowEventOffcanvas(true)
	}, [clientId])

	useEffect(() => {
		dispatch(fetchAllClientsAction(globalFilter, pageNumber))
	}, [dispatch, globalFilter, pageNumber]);



	return (
		<Fragment>
			<Tab.Container defaultActiveKey="list">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Clients
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">Client management</Breadcrumb.Item>
									<Breadcrumb.Item active>Clients</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							{/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}


						</div>
						<Col className="d-flex justify-content-end">
							<div>
								<AddNewClient
									showOffcanvas={showEventOffcanvas}
									setShowOffcanvas={setShowEventOffcanvas}
									setClientId={setClientId}
									clientId={clientId}
								/>

							</div>
						</Col>
					</Col>
				</Row>
				<>
					<Tab.Content>

						<Tab.Pane eventKey="list" className="pb-4">
							{/* students in list view */}

							<ClientTable
								clientId={clientId}
								setClientId={setClientId}
								globalFilter={globalFilter}
								setGlobalFilter={setGlobalFilter}
								pageNumber={pageNumber}
								setPageNumber={setPageNumber}
							/>

							{/* <StudentsListItems /> */}
							{/* end of students in list view */}
						</Tab.Pane>
					</Tab.Content>


				</>
			</Tab.Container>
			<ToastContainer />
		</Fragment>

	);
};

export default ClientPage;
