// import node module libraries
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, ProgressBar, Dropdown, Modal, Button } from "react-bootstrap";

// import custom components
import { AvatarGroup, Avatar } from "components/elements/bootstrap/Avatar";

// import utility file
import {
  numberWithCommas,
  getStatusColor,
  reformatKanbanDate,
} from "helper/utils";

// import data files
import ProjectTeamMembersData from "data/dashboard/projects/ProjectTeamMembersData";
import { Archive, LogOut, Trash } from "react-feather";
import {
  deleteBoardAction,
  downloadBoardData,
  removeMemberAction,
} from "actions/boardKanban";
import { useDispatch } from "react-redux";

const GridCard = ({ item, setBoardId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDeleteBoard = (id) => {
    setBoardToDelete(id);
    setShowDeleteModal(true);
  };
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  const confirmDeleteBoard = () => {
    if (boardToDelete && confirmationMessage === "DELETE BOARD") {
      dispatch(deleteBoardAction(boardToDelete));
      setBoardToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [boardToDelete, setBoardToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  //Leave Project
  const handleLeaveBoard = (id) => {
    setBoardToLeave(id);
    setShowLeaveModal(true);
  };
  const confirmLeaveBoard = () => {
    if (boardToLeave && confirmationLeaveMessage === "I confirm") {
      dispatch(removeMemberAction(boardToLeave, userData._id));
      setBoardToLeave(null);
      setShowLeaveModal(false);
      setConfirmationMessage("");
    }
  };

  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [boardToLeave, setBoardToLeave] = useState(null);
  const [confirmationLeaveMessage, setConfirmationLeaveMessage] = useState("");

  //EXPORT JSON FILE
  const handleExportBoard = (id, title) => {
    dispatch(downloadBoardData(id, title));
  };
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ id, setBoardId, title }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-vertical text-muted"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>Settings</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={() => setBoardId(id)}>
            <i className="fe fe-edit dropdown-item-icon"></i>Edit Details
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="3"
            onClick={() => handleExportBoard(id, title)}
          >
            <i className="fe fe-printer dropdown-item-icon"></i>Export Project
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="5"
            onClick={() => navigate("/kanban_board/archived_task/" + id)}
          >
            {" "}
            <Archive size="15px" className="dropdown-item-icon" /> Show Archived
            Tasks
          </Dropdown.Item>
          {item?.members.some((member) => member?.userId === userData?._id) ? (
            <Dropdown.Item eventKey="3" onClick={() => handleLeaveBoard(id)}>
              <LogOut
                size="15px"
                className="dropdown-item-icon"
                variant="danger"
                onClick={confirmLeaveBoard}
                disabled={confirmationLeaveMessage !== "I confirm"}
              />
              Leave Project
            </Dropdown.Item>
          ) : null}
          <Dropdown.Divider />
          {/* <Dropdown.Item eventKey="3">
                        <i className="fe fe-archive dropdown-item-icon"></i>Archive
                    </Dropdown.Item> */}
          {userData?._id === item.owner?._id ||
          userData?.role === "admin" ||
          userData?.role === "learning-architect" ? (
            <Dropdown.Item eventKey="3" onClick={() => handleDeleteBoard(id)}>
              <Trash
                size="15px"
                className="dropdown-item-icon"
                variant="danger"
                onClick={confirmDeleteBoard}
                disabled={confirmationMessage !== "DELETE BOARD"}
              />
              Delete Project
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const CardHeading = (item) => {
    if (item.icon != null) {
      return (
        <div className="d-flex align-items-center">
          <div className="icon-shape icon-lg rounded-3 border p-4">
            <i className={`fe fe-${item.icon} fs-3 text-muted`}></i>
          </div>
          <div className="ms-3">
            <h4 className="mb-0">
              <Link
                to={"/kanban_board/list/" + item._id}
                className="text-inherit"
              >
                {item?.title}
              </Link>
            </h4>
            <span className="text-muted">
              <span
                style={{
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "50%",
                  padding: "5px",
                  marginRight: "5px",
                }}
              >
                {item?.owner?.name &&
                  item.owner.name
                    .split(" ")
                    .map((namePart, index) =>
                      index < 2 ? namePart.charAt(0) : ""
                    )
                    .join("")}
              </span>
              {item?.owner?.name}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h4 className="mb-2">
            <Link
              to={"/kanban_board/list/" + item._id}
              className="text-inherit"
            >
              {item?.title}
            </Link>
          </h4>
          <span className="text-muted fs-6">
            <span
              style={{
                backgroundColor: "green",
                color: "white",
                borderRadius: "50%",
                padding: "8px",
                marginRight: "5px",
              }}
            >
              {item?.owner?.name &&
                item.owner.name
                  .split(" ")
                  .map((namePart, index) =>
                    index < 2 ? namePart.charAt(0) : ""
                  )
                  .join("")}
            </span>
            {item?.owner?.name}
          </span>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <Card className="h-100">
        {item.coverimage != null ? (
          <Fragment>
            <Card.Img
              variant="top"
              src={item.coverimage}
              className="img-fluid rounded-top"
            />
            <div className="d-flex position-absolute end-0 pe-3 pt-3">
              <ActionMenu
                id={row.original._id}
                setBoardId={setBoardId}
                title={row.original.title}
              />
            </div>
          </Fragment>
        ) : (
          ""
        )}
        <Card.Body>
          {/* heading*/}
          {item.coverimage == null ? (
            <div className="d-flex align-items-center justify-content-between">
              {CardHeading(item)}
              <div className="d-flex align-items-center">
                <ActionMenu
                  id={item?._id}
                  setBoardId={setBoardId}
                  title={item?.title}
                />
              </div>
            </div>
          ) : (
            CardHeading(item)
          )}

          <div className="mt-3 mb-4">
            <p className="mb-0">
              {item?.description
                ? item.description.split(" ").slice(0, 15).join(" ") +
                  (item.description.split(" ").length > 15 ? "..." : "")
                : ""}
            </p>
          </div>
        </Card.Body>

        {/* card footer */}
        <Card.Footer className="p-0">
          <div className="d-flex " style={{ padding: "10px" }}>
            <h6 className="mb-0 text-muted" style={{ marginLeft: "5%" }}>
              Create at:
            </h6>
            <p
              style={{ marginLeft: "1%" }}
              className="text-dark fs-6 fw-semi-bold mb-0"
            >
              {reformatKanbanDate(item?.createdAt)}
            </p>
          </div>
        </Card.Footer>
      </Card>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this board?</p>
          <h4 className="text-secondary">
            you must write "DELETE BOARD" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteBoard}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLeaveModal} onHide={() => setShowLeaveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Leave Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to leave this project?</p>
          <h4 className="text-secondary">
            you must write "I confirm" to confirm the request
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationLeaveMessage}
            onChange={(e) => setConfirmationLeaveMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLeaveModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmLeaveBoard}>
            Leave
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default GridCard;
