import { fetchRelatedCardsAction } from "actions/boardCard";
import { createDescriptionToChildsAction } from "actions/boardCardDesc";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

function RelatedCardModal({
  handleCloseModal,
  showModal,
  id,
  newDescription,
  descTempalte,
}) {
  const dispatch = useDispatch();
  const { relatedCards } = useSelector((state) => state.boards);
  // State to track selected checkboxes
  const [selectedCards, setSelectedCards] = useState([]);

  // State to track if all checkboxes are selected
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    dispatch(fetchRelatedCardsAction(id));
  }, [dispatch, id]);

  // Handle individual checkbox change
  const handleCheckboxChange = (e, cardId) => {
    if (e.target.checked) {
      setSelectedCards([...selectedCards, cardId]);
    } else {
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
    }
  };

  // Handle select all change
  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const allCardIds = relatedCards.map((card) => card._id);
      setSelectedCards(allCardIds); // Select all cards
    } else {
      setSelectedCards([]); // Deselect all cards
    }
  };
  const handleCreateDescriptions = () => {
    dispatch(
      createDescriptionToChildsAction(
        {
          title: newDescription.title,
          desc: newDescription.desc,
          ids: selectedCards,
          descTempalte,
        },
        handleCloseModal
      )
    );
  };
  return (
    <Modal show={showModal} onHide={() => handleCloseModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Related Cards</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Add Description to related Cards</p>

        {/* Select All Checkbox */}
        <div>
          <label>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span style={{ marginLeft: "5px" }}>Select All</span>
          </label>
        </div>

        {/* Individual card checkboxes */}
        {relatedCards?.map((card) => (
          <div key={card._id}>
            <label>
              <input
                type="checkbox"
                checked={selectedCards?.includes(card._id)}
                onChange={(e) => handleCheckboxChange(e, card._id)}
              />
              <span style={{ marginLeft: "5px" }}>{card?.boardId?.title}</span>
            </label>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" onClick={() => handleCreateDescriptions()}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={() => handleCloseModal()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RelatedCardModal;
