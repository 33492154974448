import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AUTH, LOADING, LOGOUT } from "./constants";
import { signUp, signIn, current, logOut } from "api";
export const signUpAction = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //signup the user
    await signUp(formData);
    // console.log(data?.data?.user);

    dispatch({ type: LOADING, payload: false });
    toast.success(data.message, {
      autoClose: 1000,
    });

    //  window.location.href = "/authentication/mail-confirmation";
    navigate("/authentication/sign-in");
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }
};
export const signInAction = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //login the user
    const { data } = await signIn(formData);
    localStorage.setItem("userData", JSON.stringify(data.result));
    localStorage.setItem("userToken", data.token);
    localStorage.setItem("userId", data.result._id);
    dispatch({ type: LOADING, payload: false });
    toast.success(data.message, {
      autoClose: 1000,
    });

    const userToken = data.token;

    userToken;
    if (userToken) {
      // User is authenticated
      dispatch({ type: AUTH, payload: { is_authenticated: true } });
      // navigate('/')
      window.location.href = "/dashboard";
    } else {
      // User is not authenticated
      dispatch({ type: AUTH, payload: { is_authenticated: false } });
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
// export const authAction = () => async (dispatch) => {
//   try {
//     dispatch({ type: LOADING, payload: true });

//     if (data?.data?.is_authenticated) {
//       dispatch({ type: AUTH, payload: data?.data });
//     }

//     dispatch({ type: LOADING, payload: false });
//   } catch (error) {
//     dispatch({ type: LOADING, payload: false });
//     if (error?.response?.data?.message) {
//       console.log(error.response.data.message);

//     }
//   }
// };

export const authAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      // User is authenticated
      const userData = JSON.parse(userToken);
      dispatch({ type: AUTH, payload: { is_authenticated: true } });
    } else {
      // User is not authenticated
      dispatch({ type: AUTH, payload: { is_authenticated: false } });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const logOutAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    // Perform the logout action, for example, calling a logout API or clearing user data.

    // Remove user data and token from local storage
    localStorage.removeItem("userData");
    localStorage.removeItem("userToken");
    window.location.href = "/authentication/sign-in";

    dispatch({ type: LOGOUT });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
