import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import {
  Gitgraph,
  templateExtend,
  TemplateName,
  Orientation,
} from "@gitgraph/react";

import { useParams } from "react-router-dom";
import { getAllBoardLogsAction, getMembersAction } from "actions/boardKanban";

const BoardLogs = ({ show, setShow }) => {
  const { id } = useParams();
  const { boardLogs, members } = useSelector((state) => state.boards);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMembersAction(id));
    dispatch(getAllBoardLogsAction(id));
  }, [id, dispatch]);
  const colors = [
    "#F58D06",
    "#4B4C5E",
    "#65637B",
    "#97B5A9",
    "#B8CDC6",
    "#DCE6DD",
    "#893326",
    "#4F1009",
    "#22191C",
  ];
  const withoutAuthor = templateExtend(TemplateName.Metro, {
    branch: {
      lineWidth: 4,
      label: {
        font: "normal 10pt Calibri",
      },
      spacing: 48,
    },
    commit: {
      spacing: 44,
      dot: {
        size: 8,
      },
      message: {
        displayAuthor: false,
        font: "normal 10pt Calibri",
        displayHash: false,
      },
    },
    colors: colors,
  });
  const initGraph = (gitgraph) => {
    const branches = {};
    members
      ?.map((e) => e.name)
      .forEach((list) => {
        branches[list] = gitgraph.branch(list);
        branches[list].commit("");
      });
    var previous_user = null;
    boardLogs
      .filter((e) => e?.user?.name)
      ?.forEach((logEntry, index) => {
        const { user, createdAt, action, task, project, list } = logEntry;

        if (
          previous_user != null &&
          previous_user != user?.name &&
          user?.name
        ) {
          // add merge here

          branches[user?.name]?.merge(branches[previous_user]);
        }
        previous_user = user?.name || "test";

        const commitMessage = `${action}
         at ${createdAt.toLocaleString()}`;
        if (!task && !list && user?.name) {
          branches[user?.name]
            ?.commit(commitMessage)
            .tag("#" + (index + 1) + " " + project.title);
        } else if (!task && list && user?.name) {
          branches[user?.name]
            ?.commit(commitMessage)
            .tag("#" + (index + 1) + " " + list.title);
        } else if (user?.name) {
          branches[user?.name]
            ?.commit(commitMessage)
            .tag("#" + (index + 1) + " " + task.title);
        }
      });
  };

  return (
    <div>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Board Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Gitgraph
            options={{
              orientation: Orientation.VerticalReverse,
              reverseArrow: true,
              template: withoutAuthor,
            }}
          >
            {initGraph}
          </Gitgraph>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Hide
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BoardLogs;
