import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllComments,
  getCommentById,
  editComment,
  deleteComment,
  createComment,
  fetchCommentByCard,
  getCardById,
  updateBoardLog,
} from "api";
import {
  GET_ALL_COMMENTS,
  GET_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  CREATE_COMMENT,
  LOADING,
  GET_COMMENT_BY_CARD,
  GET_CARD,
} from "./constants";
const userDataString = localStorage.getItem("userData");
const userData = JSON.parse(userDataString);
export const fetchAllCommentsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllComments();
    dispatch({ type: GET_ALL_COMMENTS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchCommentAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getCommentById(id);
    dispatch({ type: GET_COMMENT, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchCommentByCardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchCommentByCard(id);
    dispatch({ type: GET_COMMENT_BY_CARD, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const editCommentAction =
  (fromData, handleClose, id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await editComment(fromData, id);
      dispatch({ type: EDIT_COMMENT, payload: data });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteCommentAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteComment(id);
    dispatch({ type: DELETE_COMMENT, payload: id });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const createCommentAction =
  (fromData, handleCloseComment, boardId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      await createComment(fromData);
      const { data } = await getCardById(fromData.cardId);
      dispatch({ type: GET_CARD, payload: data });
      handleCloseComment();

      await updateBoardLog({
        task: fromData.cardId,
        project: boardId,
        user: userData._id,
        action: "Comment Added",
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
