import {
    GET_ALL_BOOKS,
    GET_BOOK,
    EDIT_BOOK,
    DELETE_BOOK,
    CREATE_BOOK,
  } from "../actions/constants";
  
  const booksReducer = (state = { books:[], book: {} }, action) => {
    switch (action.type) {
      case GET_ALL_BOOKS: 
        return { ...state, books: action?.payload };
      case GET_BOOK:
        return { ...state, book: action?.payload };
      case EDIT_BOOK:
        return {...state, books: [...state.books.filter(e => e.id !== action.payload.id), action.payload]};
      case DELETE_BOOK:
            return { ...state, books: state.books.filter(e => e.id !== action.payload) }; 
      case CREATE_BOOK: 
            return {  ...state, books: [...state.books, action.payload] }; 
      default:
        return state;
    }
  };
  
  export default booksReducer;
  