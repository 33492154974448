import {
 
    SEARCH_READER,
  } from "../actions/constants";
  
  const readersReducer = (state = { readers:[] }, action) => {
    switch (action.type) {
        case SEARCH_READER:
          return { ...state, readers: action?.payload };

      default:
        return state;
    }
  };
  
  export default readersReducer;
  