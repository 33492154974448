import React, { useCallback } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "./CustomNode";
import ConnectionLine from "./ConnectionLine";
import { useSelector } from "react-redux";
import "reactflow/dist/style.css";
const nodeTypes = {
  custom: CustomNode,
};

const ConnectionLineFlow = () => {
  const { initialNodes, initialEdges } = useSelector((state) => state.boards);

  // const initialEdges = [
  //   {
  //     source: "node-1",
  //     type: "smoothstep",
  //     target: "node-2",
  //     animated: true,
  //   },

  //   {
  //     source: "node-2",
  //     type: "smoothstep",
  //     target: "node-3",
  //     animated: true,
  //   },
  // ];
  // const initialNodes = [
  //   {
  //     id: "node-1",
  //     type: "custom",

  //     data: {
  //       label: "Activity 1",
  //       subcategories: [
  //         { label: "Vocabulary", color: "blue" },
  //         { label: "listening", color: "red" },
  //       ],
  //       sourcePosition: "right",
  //       arrow: {
  //         id: "horizontal-e1-2",
  //       },
  //     },
  //     position: {
  //       x: 250,
  //       y: 0,
  //     },
  //   },
  //   {
  //     id: "node-2",
  //     type: "custom",

  //     data: {
  //       label: "Activity 2",
  //       subcategories: [
  //         { label: "writing", color: "green" },
  //         { label: "listening", color: "red" },
  //       ],
  //       arrow: {
  //         id: "horizontal-e1-4",
  //       },
  //       sourcePosition: "right",
  //       targetPosition: "left",
  //     },
  //     position: {
  //       x: 500,
  //       y: 0,
  //     },
  //   },
  //   {
  //     id: "node-3",
  //     type: "custom",

  //     data: {
  //       label: "Activity 3",
  //       subcategories: [
  //         { label: "Speaking", color: "orange" },
  //         { label: "listening", color: "red" },
  //       ],
  //       // sourcePosition: "right",
  //       targetPosition: "left",
  //     },
  //     position: {
  //       x: 750,
  //       y: 0,
  //     },
  //   },
  // ];

  const [nodes, _, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
 
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      fitView
      attributionPosition="bottom-left"
    >
      <div>
        <h1
          style={{
            color: "#02B0F2",
            borderLeft: "6px solid #02B0F2",
            padding: "6px",
            fontSize: "35px",
          }}
        >
          My Education <br /> Pathway
        </h1>
      </div>
    </ReactFlow>
  );
};

export default ConnectionLineFlow;
