import {
    GET_ALL_BADGES,
    GET_BADGE,
    EDIT_BADGE,
    DELETE_BADGE,
    CREATE_BADGE,
    GET_FREE_BADGES,
  } from "../actions/constants";
  
  const badgesReducer = (state = { badges: [], badge: {},totalPages:{}, freeBadges:[] }, action) => {
    switch (action.type) {
      case GET_ALL_BADGES:
        return {
          ...state,
          badges: action?.payload.data,
          totalPages: action?.payload.totalPages
        };
        case GET_FREE_BADGES:
          return {
            ...state,
            freeBadges: action?.payload.data,
            totalPages: action?.payload.totalPages
          };
      case GET_BADGE:
        return { ...state, badge: action?.payload };
      case EDIT_BADGE:
        return { ...state, badges: state.badges.map(e=>{
          if(e._id==action.payload._id){
            return action.payload
          }
          else{
            return e
          }
        }) };
      case DELETE_BADGE:
        return { ...state, badges: state.badges.filter(e => e._id !== action.payload) };
      case CREATE_BADGE:
        return { ...state, badges: [...state.badges, action.payload] };
      default:
        return state;
    }
  };
  
  export default badgesReducer;
  