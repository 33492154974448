// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { MoreVertical, Trash, Edit, Link2 } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";

// import utility file

// import data files
import {
  checkSessionAction,
  deleteClientAction,
  fetchAllClientsAction,
  linkCodeAction,
} from "actions/client";
import SpinnerLoading from "../SpinnerLoading";
import AutocompleteIread from "./AutocompleteIread";
import { EyeFill, EyeSlash, Eyeglasses } from "react-bootstrap-icons";
import AutocompleteBadge from "./AutocompleteBadge";
import { assigneBadgeToClientAction } from "actions/badge";

const ClientTable = ({
  clientId,
  setClientId,
  globalFilter,
  setGlobalFilter,
  pageNumber,
  setPageNumber,
}) => {
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const dispatch = useDispatch();
  const { clients } = useSelector((state) => state?.clients);
  const { checkSession } = useSelector((state) => state?.checkSession);
  const { totalPages } = useSelector((state) => state?.totalPages);
  const pageCount = pageNumber;
  const search = globalFilter;
  const previousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handleDeleteClient = (id) => {
    setClientToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteClient = () => {
    if (clientToDelete && confirmationMessage === "DELETE CLIENT") {
      dispatch(deleteClientAction(clientToDelete));
      setClientToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [showLinkedCLient, setShowLinkedCLient] = useState(false);
  const [showSession, setShowSession] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [idClient, setIdClient] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedReader, setSelectedReader] = useState([]);
  const [packIdToLink, setPackIdToLink] = useState();
  const [clientCode, setClientCode] = useState();
  const [readerId, setReaderId] = useState();
  useEffect(() => {
    if (selectedReader?.length > 0) {
      setReaderId(selectedReader[0]?.id);
    }
  }, [selectedReader]);
  const handleLinkedClient = (packId, theCode) => {
    setClientCode(theCode);
    setPackIdToLink(packId);
    setShowLinkedCLient(true);
  };
  const confirmLinkClient = () => {
    dispatch(linkCodeAction(readerId, clientCode, handleClose));
  };
  const handleClose = () => {
    setShowLinkedCLient(false);
    setReaderId();
    setClientCode();
    setPackIdToLink();
    setSelectedReader();
  };

  const handleCheckSession = (theCode) => {
    setClientCode(theCode);
    setShowSession(true);
  };

  useEffect(() => {
    if (clientCode) {
      dispatch(checkSessionAction(clientCode, setClientCode));
    }
  }, [clientCode]);
  const handleCloseSession = () => {
    setShowSession(false);
    setClientCode();
  };
  //Assigne Badge
  const handleAssigneBadge = (id) => {
    setIdClient(id);
    setShowBadge(true);
  };
  const handleBadge = () => {
    dispatch(
      assigneBadgeToClientAction(selectedOption, idClient, handleBadgeClose)
    );
  };
  const handleBadgeClose = () => {
    setIdClient(null);
    setShowBadge(false);
    setSelectedOption(null);
  };
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({
    id,
    setClientId,
    packId,
    theCode,
    readerId,
    assigned,
  }) => {
    return (
      <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          {/* <Dropdown.Item eventKey="1">
						{' '}
						<Link to={'/clients_management/clients/client_details/' + id}>
							<Eye size="15px" className="dropdown-item-icon" /> Client Details
						</Link>
					</Dropdown.Item> */}
          <Dropdown.Item eventKey="2" onClick={() => setClientId(id)}>
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          {userData?.role == "admin" && (
            <Dropdown.Item eventKey="3" onClick={() => handleDeleteClient(id)}>
              {" "}
              <Trash
                size="15px"
                className="dropdown-item-icon"
                variant="danger"
                onClick={confirmDeleteClient}
              />{" "}
              Remove
            </Dropdown.Item>
          )}
          <Dropdown.Item
            eventKey="4"
            onClick={() => navigate(`/clients_management/placmentTest/${id}`)}
          >
            <Link2
              size="15px"
              className="dropdown-item-icon"
              variant="danger"
            />{" "}
            Placement Test
          </Dropdown.Item>
          {/* 
					<Dropdown.Item eventKey="5"
						disabled={!readerId}
						onClick={() => { if (readerId) handleCheckSession(theCode) }}
					>
						{' '}
						<EyeFill
							size="15px"
							className="dropdown-item-icon"
							variant="danger"


						/> Check Session
					</Dropdown.Item> */}
          {/* <Dropdown.Item eventKey="5"
					disabled={assigned}
						onClick={() => handleAssigneBadge(id)}
					>
						{' '}
						<PlusCircle
							size="15px"
							className="dropdown-item-icon"
							variant="danger"


						/> Assigne Badge
					</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "_id", Header: "ID", show: false },
      {
        accessor: "name",
        Header: "Name",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              {/* <Image
								src={row.original.img}
								alt=""
								className="rounded-circle avatar-md me-2"
							/> */}
              <Link
                to={
                  "/clients_management/clients/client_details/" +
                  row.original._id
                }
              >
                <h5 className="mb-0">{value}</h5>
              </Link>
            </div>
          );
        },
      },
      { accessor: "defaultPhone", Header: "Main Phone" },

      {
        accessor: "class",
        Header: "Class",
        Cell: ({ value }) => {
          return value;
        },
      },
      // {
      // 	accessor: 'code',
      // 	Header: 'Code',
      // 	Cell: ({ value }) => {
      // 		return (
      // 			<h5 className={value !== undefined && value !== null ? "text-secondary" : "text-danger"}>
      // 				{value !== undefined && value !== null ? value : "No code"}
      // 			</h5>
      // 		)
      // 	}
      // },
      // {
      // 	accessor: 'codeStatus',
      // 	Header: 'Code Status',
      // 	Cell: ({ value }) => {
      // 		let textColor;

      // 		if (value === 'pending') {
      // 			textColor = '#FBBC04';
      // 		} else if (value === 'used') {
      // 			textColor = 'green';
      // 		} else {
      // 			textColor = 'red';
      // 		}

      // 		return (
      // 			<h5 style={{ color: textColor }}>
      // 				{value !== undefined && value !== null ? value : 'No code'}
      // 			</h5>
      // 		);
      // 	},
      // },
      // {
      // 	accessor: 'userLink',
      // 	Header: 'link',
      // 	Cell: ({ value }) => {
      // 		if (value === undefined || value === null) {
      // 			return <span style={{ color: 'red' }}>No</span>;
      // 		} else {
      // 			return <span style={{ color: 'green' }}>Yes</span>;
      // 		}
      // 	}
      // },
      {
        accessor: "badge",
        Header: "Badge",
        Cell: ({ value }) => {
          if (value === undefined || value === null) {
            return <span style={{ color: "red" }}>Not Assigned</span>;
          } else {
            return <span style={{ color: "green" }}>{value.badgeId}</span>;
          }
        },
      },
      // {
      // 	accessor: 'linked_to_coffee_app',
      // 	Header: 'Caffee shop',
      // 	Cell: ({ value }) => {
      // 		if (value === false || value === null || value === undefined) {
      // 			return <span style={{ color: 'red' }}>Not Linked</span>;
      // 		} else if (value === true) {
      // 			return <span style={{ color: 'green' }}>Linked</span>;
      // 		}
      // 	}
      // },
      {
        accessor: "message",
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="hstack gap-4">
              <ActionMenu
                id={row.original._id}
                setClientId={setClientId}
                packId={row.original.pack_id}
                theCode={row.original.code}
                readerId={row.original.userLink}
                assigned={row.original?.badge?.assigned}
              />
            </div>
          );
        },
      },
    ],
    []
  );
  const data = useMemo(() => clients, [clients]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // nextPage,
    // previousPage,
    state,
    gotoPage,
    // pageCount,
    prepareRow,
    // setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex /* , globalFilter */ } = state;

  return (
    <Fragment>
      <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Client"
            />
          </Col>
        </Row>
      </div>

      {loading ? (
        <SpinnerLoading />
      ) : (
        <Table
          hover
          responsive
          {...getTableProps()}
          className="text-nowrap table-centered"
        >
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this client?</p>
          <h4 className="text-secondary">
            you must write "DELETE CLIENT" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteClient}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal link client to iread */}
      <Modal show={showLinkedCLient} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Linked Client To Iread</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AutocompleteIread
            selectedReader={selectedReader}
            setSelectedReader={setSelectedReader}
            packIdToLink={packIdToLink}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={confirmLinkClient}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal check Session */}
      <Modal show={showSession} onHide={handleCloseSession}>
        <Modal.Header closeButton>
          <Modal.Title>Check Sessions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkSession.map((item, index) => (
            <div key={index}>
              <h6>{`Session ${index + 1}`}</h6>
              <div>
                <strong>Reader Name:</strong> {item.username}
              </div>
              <div>
                <strong>Presence:</strong> {item.presence ? "true" : "false"}
              </div>
              <div>
                <strong>Session:</strong> {item.name}
              </div>
              <div>
                <strong>Start Date:</strong> {item.start_date}
              </div>
              <div>
                <strong>End Date:</strong> {item.end_date}
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSession}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Assigne Badge Modal */}

      <Modal show={showBadge} onHide={handleBadgeClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assigne Badge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to assigne badge to this client?</p>
          <AutocompleteBadge
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ padding: "20px" }}
            variant="primary"
            disabled={selectedOption === null || selectedOption === undefined}
            onClick={() => handleBadge()}
          >
            {loading ? <Spinner /> : <span>Confirm</span>}
          </Button>
          <Button
            style={{ padding: "20px" }}
            variant="secondary"
            onClick={handleBadgeClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </Fragment>
  );
};

export default ClientTable;
