// import node module libraries
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// import sub/custom components
import QuizProgress from "./QuizProgress";

// import media files
import quizImage from "../../../../assets/images/quizs/quizlogo.png";

import { useSelector, useDispatch } from "react-redux";
import SpinnerLoading from "./SpinnerLoading";

import QuizTimer from "./QuizTimer";

import Question from "components/marketing/common/quiz/Question";
import { fetchOneQuizAction, getQuizAnswerAction } from "actions/quiz";
import Pagination from "./QuizAppPagination";

const QuizPass = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user = searchParams.get("user_id");
  const placmentTest = searchParams.get("placmentTest");
  const quiz_id = searchParams.get("quiz_id");
  const { loading } = useSelector((state) => state.auth);
  const { quiz, question } = useSelector((state) => state.quizs);

  const nbrQuestion = question?.length || 0;
  const quizDuration = quiz?.duration || 0;
  const quizTitle = quiz?.title || "";
  const quizDesc = quiz?.desc || "";

  // const hours = Math.floor(quizDuration / 60);
  // const minutes = quizDuration % 60;
  // const seconds = 0;

  useEffect(() => {
    dispatch(fetchOneQuizAction(quiz_id));
  }, [dispatch, quiz_id]);
  // useEffect(() => {
  //   dispatch(getQuizAnswerAction({ user, quiz: quiz_id }, placmentTest));
  // }, [dispatch]);

  const tableIds = [];
  for (let i = 1; i <= nbrQuestion; i++) {
    tableIds.push({ _id: i });
  }
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(1);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = quiz?.questions?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(quiz?.questions?.length / recordsPerPage);
  const [remainingTime, setRemainingTime] = useState(quizDuration * 60);
  const index =
    quiz?.questions?.findIndex((e) => e._id === currentRecords[0]._id) + 1;
  useEffect(() => {
    setRemainingTime(quizDuration * 60);
  }, [quiz]);
  useEffect(() => {
    const timer = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [remainingTime]);

  return (
    <>
      {loading ? (
        <SpinnerLoading />
      ) : (
        <Card className="mb-4" style={{ padding: "40px 80px 30px 80px" }}>
          <Card.Body style={{ marginTop: "-50px" }}>
            {/* Question Title + Timer */}
            <div className="d-flex justify-content-between align-items-center border-bottom pb-3 mb-3">
              <div className="d-flex align-items-center">
                {/* quiz img */}
                <Link to="#">
                  {" "}
                  <img src={quizImage} alt="" className="rounded img-4by3-lg" />
                </Link>
                {/* quiz content */}
                <div className="ms-3">
                  <h3 className="mb-0">
                    <Link to="#" className="text-inherit">
                      {quizTitle}
                    </Link>
                  </h3>
                  <h5> {quizDesc}</h5>
                </div>
              </div>
              {/* Timer */}
              <QuizTimer
                hours={Math.floor(remainingTime / 3600)}
                minutes={Math.floor((remainingTime % 3600) / 60)}
                seconds={remainingTime % 60}
              />
            </div>

            {/* Progress */}
            <QuizProgress currentQuestion={index} totalQuestion={nbrQuestion} />

            {/* Question(s) */}
            <div className="mt-5">
              <span>Question {index} </span>
              {currentRecords && currentRecords.length > 0 && (
                <Question
                  item={currentRecords[0]}
                  selectedAnswer={selectedAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  setCurrentQuestionId={setCurrentQuestionId}
                />
              )}
            </div>
          </Card.Body>
          {/* Quiz Pagination */}
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            selectedAnswer={selectedAnswer}
            currentQuestionId={currentQuestionId}
            setSelectedAnswer={setSelectedAnswer}
          />
          <ToastContainer />
        </Card>
      )}
    </>
  );
};

export default QuizPass;
