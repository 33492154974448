import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    GET_ALL_BADGES,
    GET_BADGE,
    EDIT_BADGE,
    DELETE_BADGE,
    CREATE_BADGE,

    LOADING,
    GET_FREE_BADGES,
    EDIT_CLIENT,
    GET_CLIENT_IN_CLASS
} from "./constants";
import {
    LinkToCoffeShop,
    assigneBadge,
    assigneBadgeToClient,
    createBadge,
    deleteBadge,
    editBadge,
    editClient,
    getAllBadges,
    getBadgeById,
    getClientInClass,
    getFreeBadges,
    importBadge
} from 'api';

export const importBadgeAction = (fromData,search, page) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
       await importBadge(fromData);
        const { data } = await getAllBadges(page, search);
        dispatch({ type: GET_ALL_BADGES, payload: data });
        toast.success(data.message, {
            autoClose: 1000,
        });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};

export const fetchAllBadgesAction = (search, page) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await getAllBadges(page, search);
        dispatch({ type: GET_ALL_BADGES, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};
export const getFreeBadgesAction = (search, page) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await getFreeBadges(page, search);
        dispatch({ type: GET_FREE_BADGES, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};

export const fetchBadgeAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await getBadgeById(id);
        dispatch({ type: GET_BADGE, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};
export const editBadgeAction = (fromData, handleClose, setBadgeId, badgeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await editBadge(fromData, badgeId);
        dispatch({ type: EDIT_BADGE, payload: data.data });
        handleClose()
        setBadgeId()
        toast.success(data.message, {

            autoClose: 1000,
        });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
        }
        dispatch({ type: LOADING, payload: false });
    }
};
export const deleteBadgeAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const data = await deleteBadge(id);

        dispatch({ type: DELETE_BADGE, payload: id });
        toast.success(data.data.message, {

            autoClose: 1000,
        });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createBadgeAction = (fromData, handleClose) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await createBadge(fromData);
        dispatch({ type: CREATE_BADGE, payload: data });
        handleClose()
        toast.success(data.message, {

            autoClose: 1000,
        });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
        }
        dispatch({ type: LOADING, payload: false });
    }
};

export const assigneBadgeAction = (textInput,class_id) => async (dispatch) => {
    const fromData = {startFrom:textInput, id:class_id}
    try {
        dispatch({ type: LOADING, payload: true });
         await assigneBadge(fromData);
        const {data} = await getClientInClass(class_id);
      const clients =data.clients.map(client=> client.clientId).filter(e=>e!==undefined)
      dispatch({ type: GET_CLIENT_IN_CLASS, payload:clients});
      toast.success(data.message, {
                 
        autoClose: 1000,
      });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
           
        }
        dispatch({ type: LOADING, payload: false });
    }
};

export const assigneBadgeToClientAction = (badgeId,idClient,handleBadgeClose) => async (dispatch) => {
    const fromData = {badge:badgeId}
    try {
        dispatch({ type: LOADING, payload: true });
        const { data } =await editClient(fromData,idClient);
        dispatch({ type: EDIT_CLIENT, payload: data.data });
        toast.success(data.message, {
                 
          autoClose: 1000,
        });
        handleBadgeClose()
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};



export const LinkToCoffeShopAction = () => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
        const { data } =await LinkToCoffeShop();
        toast.success(data.message, {
          autoClose: 1000,
        });
        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            toast.error(error.response.data.message);
        }
        dispatch({ type: LOADING, payload: false });
      }
};
