// import node module libraries
import React, { Fragment, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination
} from 'react-table';
import { Link, useParams } from 'react-router-dom';
import { Col, Row, Button, Image, Dropdown, Table, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { XCircle, MoreVertical, Mail, Trash, Edit, Eye, Plus, Download, Archive } from 'react-feather';

import {

    allbookstest
} from 'data/courses/AllCoursesData';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import { deleteCardAction, fetchAllArchivedCardsAction } from 'actions/boardCard';

const ArchivedTaskTable = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const { archivedTasks } = useSelector(state => state.boards);
    useEffect(() => {
        dispatch(fetchAllArchivedCardsAction(id))
    }, [dispatch,id]);

    const handleDeleteTask = (id) => {
        setTaskToDelete(id);
        setShowTaskModal(true);
    };

    const confirmDeleteTask = () => {
        if (taskToDelete && confirmationMessage === 'DELETE TASK') {
            dispatch(deleteCardAction(taskToDelete,id));
            setTaskToDelete(null);
            setShowTaskModal(false);
            setConfirmationMessage('');
        }
    };

    const [showTaskModal, setShowTaskModal] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');



    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Link
            to="#"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="btn-icon btn btn-ghost btn-sm rounded-circle"
        >
            {children}
        </Link>
    ));

    const ActionMenu = ({ id }) => {
        return (
            <Dropdown style={{ zIndex: 1000, position: "absolute" }} >
                <Dropdown.Toggle as={CustomToggle}>
                    <MoreVertical size="15px" className="text-secondary" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                    <Dropdown.Header>SETTINGS</Dropdown.Header>
                    <Dropdown.Item
                        eventKey="4"
                        onClick={() => handleDeleteTask(id)}>
                        {' '}
                        <Trash
                            size="15px"
                            className="dropdown-item-icon"
                            variant="danger"
                            onClick={confirmDeleteTask}
                            disabled={confirmationMessage !== 'DELETE TASK'}
                        />
                        Remove
                    </Dropdown.Item>
                 

                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const columns = useMemo(
        () => [
            { accessor: '_id', Header: 'ID', show: false },
            {
                accessor: 'title',
                Header: 'Board',
                Cell: ({ value, row }) => {
                    return (
                        <div className="ms-lg-3 mt-2 mt-lg-0">
                            <Link to={'/kanban_board/list/' + row.original._id}>
                                <h4 className="mb-1 text-primary-hover">{value}</h4>
                            </Link>
                        </div>
                    );
                }
            },
            // { accessor: 'createdAt', Header: 'Created At', show: false },
            // {
            //     accessor: 'owner',
            //     Header: 'Owner',
            //     Cell: ({ value }) => {
            //         return (
            //             <h5 className="text-secondary">
            //                 {value?.name}
            //             </h5>
            //         )
            //     }
            // },


            {
                accessor: '',
                Header: 'Action',
                Cell: ({ row }) => {
                    return (
                        <div className="hstack gap-4">

                            <ActionMenu id={row.original._id} />
                        </div>
                    );
                }
            }
        ],
        []
    );

    const data = useMemo(() => archivedTasks, [archivedTasks]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        state,
        gotoPage,
        pageCount,
        prepareRow,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10,
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) return column.accessor || column.id;
                    else return false;
                })
            }
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, globalFilter } = state;

    return (
        <Fragment>
            <div className="overflow-hidden">
                <Row>
                    <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
                        <GlobalFilter
                            filter={globalFilter}
                            setFilter={setGlobalFilter}
                            placeholder="Search Board"
                        />
                    </Col>
                </Row>
            </div>

            <div className="table-responsive overflow-y-hidden">
                <Table
                    hover
                    {...getTableProps()}
                    className="text-nowrap table-centered"
                >
                    <thead className="table-light" >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>

            <Modal show={showTaskModal} onHide={() => setShowTaskModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this task?</p>
                    <h4 className="text-secondary" >you must write "DELETE TASK" to confirm the deletion</h4>
                    <input
                        className="form-control"
                        rows="3"
                        placeholder="Enter confirmation message"
                        value={confirmationMessage}
                        onChange={(e) => setConfirmationMessage(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowTaskModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteTask}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Pagination @ Footer */}
            <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
            />
        </Fragment>
    );
};

export default ArchivedTaskTable;
