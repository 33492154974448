import {
  GET_ALL_CLIENTS,
  GET_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  CREATE_CLIENT,
  GET_STUDENTS,
  UPDATE_PLACMENT_TEST,
} from "../actions/constants";

const clientsReducer = (
  state = { clients: [], client: {}, totalPages: {}, students: [] },
  action
) => {
  switch (action.type) {
    case GET_ALL_CLIENTS:
      return {
        ...state,
        clients: action?.payload.data,
        totalPages: action?.payload.totalPages,
      };
    case GET_CLIENT:
      return { ...state, client: action?.payload };
    case UPDATE_PLACMENT_TEST:
      return {
        ...state,
        client: { ...state.client, placmentTest: action?.payload },
      };
    case EDIT_CLIENT:
      return {
        ...state,
        clients: state.clients.map((e) => {
          if (e._id == action.payload._id) {
            return action.payload;
          } else {
            return e;
          }
        }),
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((e) => e._id !== action.payload),
      };
    case CREATE_CLIENT:
      return { ...state, clients: [...state.clients, action.payload] };
    case GET_STUDENTS:
      return { ...state, students: action.payload };

    default:
      return state;
  }
};

export default clientsReducer;
