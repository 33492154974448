import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Tab, Card, Nav, Breadcrumb, Button, Modal } from 'react-bootstrap';
import { Element } from "react-scroll";

// import sub custom components
import BooksTable from './BooksTable';

// import data files
import {
	allcourses,
	allapprovedcourses,
	allpendingcourses,
	allbookstest
} from 'data/courses/AllCoursesData';
import AddNewBook from '../AddNewBook';


const AllBooks = () => {
	const [bookId, setBookId] = useState(null)
    const [show, setShow] = useState(false);

	useEffect(() => {
		if (bookId) setShow(true)
	}, [bookId])


	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Books</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Books management</Breadcrumb.Item>
								<Breadcrumb.Item active>Books</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<Col className="d-flex justify-content-end">
						<div>
						{/* 	<Button variant="primary" onClick={handleShow}>
								Add New Books
							</Button>
							<Modal show={show} onHide={handleClose} size="lg">
								<Modal.Header closeButton>
									<Modal.Title>fassa5</Modal.Title>
								</Modal.Header>
								<Modal.Body> */}
									<AddNewBook
										showBook={show}
										setShowBook={setShow}
										setBookId={setBookId}
										bookId={bookId}
									/>
								{/* </Modal.Body> */}
							{/* 	<Modal.Footer className="d-flex justify-content-start border-0 pt-0">
									
									<Button variant="primary" onClick={handleClose}>
										Add New Books
									</Button>
									<Button variant="outline-secondary" onClick={handleClose}>
										Close
									</Button>
								</Modal.Footer> */}
							{/* </Modal> */}
						</div>
						</Col>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							{/* <Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
											Approved
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
											Pending
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header> */}
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4" >
										<BooksTable
											setBookId={setBookId}
										/>
									</Tab.Pane>
									{/* 									<Tab.Pane eventKey="approved" className="pb-4">
										<CoursesTable courses_data={allapprovedcourses} />
									</Tab.Pane>
									<Tab.Pane eventKey="pending" className="pb-4">
										<CoursesTable courses_data={allpendingcourses} />
									</Tab.Pane> */}
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
			<ToastContainer/>
		</Fragment>
	);
};

export default AllBooks;
