import {
 
    CHECK_SESSION,
  } from "../actions/constants";
  
  const checkSessionReducer = (state = { checkSession:[] }, action) => {
    switch (action.type) {
        case CHECK_SESSION:
          return { ...state, checkSession: action?.payload };

      default:
        return state;
    }
  };
  
  export default checkSessionReducer;
  