// import node module libraries
import React, { Fragment, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import { Col, Row, Button, Image, Dropdown, Table, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { XCircle, MoreVertical, Mail, Trash, Edit, Eye} from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import DotBadge from 'components/elements/bootstrap/DotBadge';
import { fetchAllBooksAction, deleteBookAction } from 'actions/books';

const booksTable = ({ setBookId }) => {
	const dispatch = useDispatch();
	
	const {books} = useSelector(state => state.books);
	useEffect(() => {
		dispatch(fetchAllBooksAction())
		 
	  }, [dispatch]);

	  const handleDeleteBook = (id) => {
		setBookToDelete(id);
		setShowDeleteModal(true);
	};

	const confirmDeleteBook = () => {
		if (bookToDelete && confirmationMessage === 'DELETE BOOK') {
		  dispatch(deleteBookAction(bookToDelete));
		  setBookToDelete(null);
		  setShowDeleteModal(false);
		  setConfirmationMessage('');
		}
	  };

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [bookToDelete, setBookToDelete] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState('');


	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to="#"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));

    const ActionMenu = ({id, setBookId}) => {
		return (
			<Dropdown style={{ zIndex: 1000, position:"absolute" }} >
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1">
						{' '}
						<Link to={'/books-management/session/'+id}>
						<Eye size="15px" className="dropdown-item-icon" /> Sessions
						</Link>
					</Dropdown.Item>
					<Dropdown.Item 
					eventKey="2"
					onClick={() => setBookId(id)}>
						{' '}
						<Edit size="15px" className="dropdown-item-icon" /> Edit
					</Dropdown.Item>
					<Dropdown.Item 
					eventKey="3"
					onClick={() => handleDeleteBook(id)}>
						{' '}
						<Trash 
						size="15px" 
						className="dropdown-item-icon"
						variant="danger"
						onClick={confirmDeleteBook}
					    disabled={confirmationMessage !== 'DELETE BOOK'}
						/>
						 Remove
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: true },
			{
				accessor: 'title',
				Header: 'Books',
				Cell: ({ value, row }) => {
					return (
						<Link className="text-inherit" to="#">
							<div className="d-lg-flex align-items-center">
								<div>
									<Image
										src={row.original.img}
										alt=""
										className="img-4by3-lg rounded"
									/>
								</div>
								<div className="ms-lg-3 mt-2 mt-lg-0">
									<h4 className="mb-1 text-primary-hover">{value}</h4>
									<span className="text-inherit">
                                        <h7 className="text-inherit">Released in {row.original.release_date}</h7>
									</span>
								</div>
							</div>
						</Link>
					);
				}
			},
			{ accessor: 'date_added', Header: '', show: false },
			{
				accessor: 'author',
				Header: 'Author',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			},
			/* {
				accessor: 'status',
				Header: 'Status',

				Cell: ({ value, row }) => {
					value = value.toLowerCase();
					return (
						<Fragment>
							<DotBadge
								bg={
									value === 'pending'
										? 'warning'
										: value === 'live'
										? 'success'
										: ''
								}
							></DotBadge>
							{value.charAt(0).toUpperCase() + value.slice(1)}
						</Fragment>
					);
				}
			}, */
            //page number section
            {accessor: 'page_number', Header: 'Page number'},
            //category section
            {accessor: 'category', Header: 'Category'},
/* 			{
				accessor: 'action',
				Header: 'Action',
				Cell: ({ value }) => {
					if (value === 2) {
						return (
							<Fragment>
								<Button
									href="#"
									variant="outline"
									className="btn btn-outline-secondary btn-sm"
								>
									Reject
								</Button>{' '}
								<Button href="#" variant="success" className="btn-sm">
									Approved
								</Button>
							</Fragment>
						);
					}
					if (value === 1) {
						return (
							<Button href="#" variant="secondary" className="btn-sm">
								Change Status
							</Button>
						);
					}
				}
			}, */
            {   id: 'action',
				accessor: 'id',
				Header: 'Action',

				Cell: ({row}) => {
					return (
					
							<ActionMenu  id={row.original.id} setBookId={setBookId}/>
						
					);
				}
			}
		],
		[]
	);

	const data = useMemo(() => books, [books]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} =  useTable (
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className="overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Book"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive overflow-y-hidden">
				<Table
					hover
					{...getTableProps()}
					className="text-nowrap table-centered"
				>
					<thead className="table-light" >
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>

			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Deletion</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Are you sure you want to delete this book?</p>
					<h4 className="text-secondary" >you must write "DELETE BOOK" to confirm the deletion</h4>
					<input
						className="form-control"
						rows="3"
						placeholder="Enter confirmation message"
						value={confirmationMessage}
						onChange={(e) => setConfirmationMessage(e.target.value)}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
						Cancel
					</Button>
					<Button variant="danger" onClick={confirmDeleteBook}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default booksTable;
