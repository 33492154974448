import { toast } from "react-toastify";
import {
  CREATE_MEETING,
  FETCH_MEETING,
  FETCH_MEETINGS,
  LOADING,
  START_MEETING,
} from "./constants";
import { createMeeting, getMeeting, getMeetings, startMeeting } from "api";

export const createMeetingAction =
  (meeting, listId, handleClose, setCardData, cardData) => async (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    try {
      const { data } = await createMeeting(meeting);
      dispatch({
        type: CREATE_MEETING,
        payload: { cardId: meeting.card, listId, meeting: data.meeting },
      });
      toast.success("Meeting has been created", {
        autoClose: 1000,
      });
      handleClose();
      setCardData({ ...cardData, meeting: data.meeting });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
    }
  };
export const getMeetingAction = (id) => async (dispatch) => {
  try {
    const { data } = await getMeeting(id);
    dispatch({ type: FETCH_MEETING, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const getMeetingsAction = (id) => async (dispatch) => {
  try {
    const { data } = await getMeetings(id);

    dispatch({ type: FETCH_MEETINGS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const startMeetingAction =
  (id, setShowVedioCall, setCardData, cardData) => async (dispatch) => {
    try {
      const { data } = await startMeeting(id);
      setCardData({ ...cardData, meeting: data.meeting });
      setShowVedioCall(true);
      dispatch({ type: START_MEETING, payload: id });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
    }
  };
