// import node module libraries
import { Fragment, useEffect, useRef, useState } from 'react';
import { Form, FormControl, InputGroup, Button, Offcanvas, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import custom components
import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
import { FormSelect } from 'components/elements/form-select/FormSelect';
import { fetchPackAction, editPackAction, createPackAction } from 'actions/packs';



const AddNewPack = ({ packId, setPackId, showPack, setShowPack }) => {

	const { pack: packEdited } = useSelector(state => state.packs)
	const dispatch = useDispatch();
	const [pack, setPack] = useState({
		title: "",
		level: "",
		age: "kid",
		price: 0,
		discount: 0,
		desc: "",
		level: "",
	});
	const [img, setImg] = useState("https://cdn-icons-png.flaticon.com/512/46/46862.png");
    //cloudinary
    const cloudinaryRef = useRef();
    const widgetRef = useRef();
    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;

        widgetRef.current = cloudinaryRef.current.createUploadWidget(
            {
                cloudName: "dtwkikmuy",
                uploadPreset: "wuraurta",
            },
            (error, result) => {
                if (result.info.secure_url) {

                    setImg(result.info.secure_url)

                }
            }
        );
    }, []);

	const handleAddPack = async (e) => {
		if (packId) {
			dispatch(editPackAction({ ...pack, img: img }, handleClose, setPackId))
			
		} else {
			dispatch(createPackAction({ ...pack, img: img }, handleClose));
		}
		e.preventDefault();
	};

	useEffect(() => {
		if (packId) {
			dispatch(fetchPackAction(packId))
		}

	}, [dispatch, packId])
	useEffect(() => {
		if (packEdited.id) {
			setPack({
				id: packEdited.id,
				title: packEdited.title,
				age: packEdited.age,
				price: packEdited.price,
				discount: packEdited.discount,
				desc: packEdited.desc,
				level: packEdited.level,
			})
			setImg(
				packEdited.img
		   )
		}
	}, [packEdited])
	const handleClose = () => {
		setPack({
			title: "",
			level: "",
			age: "",
			price: 0,
			discount: 0,
			desc: "",
			level: "",

		})
		setShowPack(false)
		setPackId(null)
	}
	const handleShow = () => setShowPack(true);




	return (
		<Fragment>
			<Button variant="primary" onClick={handleShow}>
				Add New Pack
			</Button>

			<Offcanvas
				show={showPack}
				onHide={handleClose}
				placement="end">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>{packId ? 'Update Pack' : 'Add New Pack'}</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form>
						 {/* Image  */}
						 <Form.Group className="mb-3">
                            <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="me-3"
                                    style={{
                                        width: '130px', 
                                        height: '130px', 
                                    }}
                                />
                                <Button
                                style={{
                                    marginTop:"21px",
                                    width:"100%"
                                }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {packId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group>
						{/* Title  */}
						<Form.Group className="mb-3">
							<Form.Label>Pack Title</Form.Label>
							<Form.Control
								type="text"
								placeholder="Pack Title"
								id="title"
								required
								value={pack.title}
								onChange={(e) =>
									setPack({ ...pack, title: e.target.value })
								}
							/>
						</Form.Group>
						{/* level  */}
						<Form.Group className="mb-3">
							<Form.Label>level</Form.Label>
							<Form.Control
								type="text"
								placeholder="level"
								id="level"
								required
								value={pack.level}
								onChange={(e) =>
									setPack({ ...pack, level: e.target.value })
								}
							/>
						</Form.Group>
						{/* age  */}
						<Form.Group className="mb-3">

							<Form.Label>Age</Form.Label>
							<Form.Select aria-label="Default select example"
							id="age"
							value={pack.age}
							onChange={(e) =>
								setPack({ ...pack, age: e.target.value })}
							>
							
								<option value="kid">KIDS</option>
								<option value="teenager">TEENAGERS</option>
								<option value="adult">ADULTS</option>
							</Form.Select>
							
						</Form.Group>

						{/* price  */}
						<Form.Group className="mb-3">
							<Form.Label>Price</Form.Label>
							<Form.Control
								type="number"
								placeholder="Price Here"
								id="price"
								value={pack.price}
								onChange={(e) =>
									setPack({ ...pack, price: e.target.value })
								}
							/>

						</Form.Group>

						{/* discount  */}
						<Form.Group className="mb-3">
							<Form.Label>Discount</Form.Label>
							<Form.Control
								type="number"
								placeholder="Discount Here"
								id="discount"
								value={pack.discount}
								onChange={(e) =>
									setPack({ ...pack, discount: e.target.value })
								}
							/>

						</Form.Group>

						{/* description */}
						<Form.Group className="mb-3">
							<Form.Label>Description</Form.Label>
							<Form.Control
								type="text"
								placeholder="Description Here"
								id="desc"
								value={pack.desc}
								onChange={(e) =>
									setPack({ ...pack, desc: e.target.value })
								}
							/>

						</Form.Group>



						<div className="mt-3">
							<Button type="submit"
								variant="primary"
								id="add-new-event-btn"
								onClick={(e) => handleAddPack(e)}>
								{packId ? 'Update Pack' : 'Add New Pack'}
							</Button>

							<Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
								Close
							</Button>
						</div>
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Fragment>
	);
};

export default AddNewPack;
