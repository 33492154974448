// import node module libraries
import { Fragment, useContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  Col,
  Row,
  Form,
  Button,
  Offcanvas,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from "react-bootstrap";

// import sub custom components
import KanbanColumn from "./KanbanColumn";

// import hook file
import useTaskKanban from "hooks/useTaskKanban";

// import context file
import { TaskKanbanContext } from "context/Context";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  createListAction,
  editListAction,
  fetchListAction,
  fetchListByBoardAndFilterAction,
} from "actions/boardList";
import { Clock, Eye, MoreVertical, Plus, Trash, User } from "react-feather";
import BoardLogs from "./BoardLogs";
import { Avatar, AvatarGroup } from "components/elements/bootstrap/Avatar";
import { fetchBoardAction, getMembersAction } from "actions/boardKanban";
import AutocompleteUsers from "./AutoCompleteUsers";
import AvatarCom from "assets/images/avatar/avatar_com.webp";
import AutocompleteCardTitle from "./autocompleteCardTitle";
import cursorImg from "../../../assets/images/png/cursor.png";
import SelectUserModal from "./SelectUserModal";
import { createMetaToUsersAction } from "actions/boardCard";
import { getPointsAction } from "actions/curriculum";
import { fetchAllQuizsAction } from "actions/quiz";
import { createMeetingAction, getMeetingsAction } from "actions/meeting";
import { JaaSMeeting } from "@jitsi/react-sdk";

const CustomStyle = {
  button: {
    fontSize: "10px",
    padding: "3px 8px",
    borderRadius: "6px",
  },
  meeting: {
    display: "flex",
    justifyContent: "space-between",

    margin: "5px 0 5px -10%",
  },
  createButton: {
    padding: "6px 13px",
    fontSize: "12px",
    marginTop: "10px",
  },
};
const Kanban = () => {
  const { id: idOfBoard } = useParams();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const handleShowLog = () => {
    setShowMenu(true);
  };
  const [showForm, setShowForm] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [listId, setListId] = useState(null);
  const [listData, setListData] = useState({
    title: "",
    boardId: idOfBoard,
    type: "",
    position: "",
  });
  useEffect(() => {
    if (listId) setShow(true);
  }, [listId]);
  const { lists } = useSelector((state) => state.boards);

  const handleAddList = async (e) => {
    if (listId) {
      dispatch(editListAction({ ...listData }, handleClose, listId, idOfBoard));
    } else {
      dispatch(createListAction({ ...listData }, handleClose));
    }
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(fetchBoardAction(idOfBoard));
  }, [idOfBoard]);
  const { board } = useSelector((state) => state.boards);

  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [assignedToLabel, setAssignedToLabel] = useState("Filter");
  const [filterOption, setFilterOption] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const [searched, setSearched] = useState(false);
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedCheckboxes((prevChecked) => [...prevChecked, value]);
      setFilterOption((prevOptions) => [...prevOptions, value]); // Add value to array
      setAssignedToLabel(e.target.nextSibling.textContent);
      setIsAnyCheckboxChecked(true);
    } else {
      setCheckedCheckboxes((prevChecked) =>
        prevChecked.filter((item) => item !== value)
      );
      setFilterOption((prevOptions) =>
        prevOptions.filter((option) => option !== value)
      ); // Remove value from array
      setAssignedToLabel("Filter");
    }
  };

  const handleClearButtonClick = () => {
    setFilterOption([]);
    setFilterType([]);
    setSearched(false);
    setIsAnyCheckboxChecked(false);
    setAssignedToLabel("Filter");

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const handleColorSelection = (value, checked) => {
    if (checked) {
      setIsAnyCheckboxChecked(true);
      setFilterType((prevFilterType) => [...prevFilterType, value]);
    } else {
      setFilterType((prevFilterType) =>
        prevFilterType.filter((item) => item !== value)
      );
    }
  };
  useEffect(() => {
    dispatch(fetchListByBoardAndFilterAction(idOfBoard, filterOption));
  }, [dispatch, filterOption]);

  // useEffect(() => {
  //   dispatch(fetchListByBoardAction(idOfBoard));
  // }, [dispatch]);

  useEffect(() => {
    if (listId) {
      dispatch(fetchListAction(listId));
    }
  }, [dispatch, listId]);
  const { list: listEdited } = useSelector((state) => state.boards);

  useEffect(() => {
    if (listEdited?._id) {
      setListData({
        title: listEdited.title,
        position: listEdited.position,
        type: listEdited.type,
      });
    }
  }, [listEdited]);

  const handleClose = () => {
    setListData({
      title: "",
      boardId: idOfBoard,
      position: "",
      type: "",
    });
    setShow(false);
    setListId(null);
    setShowForm(false);
  };

  const {
    TaskKanbanState: { taskList },
  } = useContext(TaskKanbanContext);

  const { handleDragEnd, cursors } = useTaskKanban();

  const { members } = useSelector((state) => state.boards);

  useEffect(() => {
    dispatch(getMembersAction(idOfBoard));
  }, [dispatch, idOfBoard]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  // Usage

  const handleShowAddMember = () => {
    setSelectedUser([]);
    setShowAddMember(true);
  };
  // meta
  const [ids, setIds] = useState([]);
  const [showMetaModal, setShowMetaModal] = useState(false);
  const [metaId, setMetaId] = useState(null);

  const handleCloseUsersModal = () => {
    setMetaId(null);
    setShowMetaModal(false);
  };

  const handleAssingeCards = () => {
    dispatch(createMetaToUsersAction(metaId, ids, handleCloseUsersModal));
  };

  const handleSelect = (id) => {
    if (ids.includes(id)) {
      setIds(ids.filter((selectedId) => selectedId !== id));
    } else {
      setIds([...ids, id]);
    }
  };

  // update get details logic
  useEffect(() => {
    dispatch(getPointsAction());
  }, []);
  useEffect(() => {
    dispatch(fetchAllQuizsAction());
  }, [dispatch]);

  return (
    <Fragment>
      <SelectUserModal
        handleCloseModal={handleCloseUsersModal}
        showModal={showMetaModal}
        menbers={members}
        handleSelect={handleSelect}
        ids={ids}
        setIds={setIds}
        handleAssingeCards={handleAssingeCards}
      />
      {Object.keys(cursors).map((userId) => {
        const { userName, x, y } = cursors[userId];

        return (
          <div
            key={userId}
            style={{
              position: "absolute",
              top: y,
              left: x,
              transform: "translate(-50%, -50%)",
              pointerEvents: "none",
              zIndex: "999",
              display: "flex",
            }}
          >
            <img width={17} height={17} src={cursorImg} />

            <div
              style={{
                color: "#593cc1",
                marginLeft: " 5px",

                whiteSpace: "nowrap",
              }}
            >
              {userName}
            </div>
          </div>
        );
      })}
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">{board?.title}</h1>
            </div>
            <Col
              lg={3}
              md={3}
              sm={12}
              style={{
                marginRight: isAnyCheckboxChecked || searched ? "-60%" : "-75%",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-checkbox"
                  style={{
                    display: "flex",
                    alignItems: "center", // Center vertically
                    justifyContent: "center",
                    backgroundColor:
                      isAnyCheckboxChecked || searched
                        ? "rgba(128, 128, 128, 0.5)"
                        : "#f1f5f9",
                    border: "none",
                    color: "black",
                    width: "fit-content",
                    color: isAnyCheckboxChecked || searched ? "white" : "black",
                  }}
                >
                  Filter
                  {isAnyCheckboxChecked || searched ? (
                    <>
                      {/* <span style={{
                        backgroundColor: "black",
                        borderRadius: "5px",
                        marginLeft: "6px",
                        padding: "4px",
                        color: "white"
                      }}>
                        {totalCardsLength}
                      </span> */}
                      <span
                        style={{
                          position: "absolute",
                          left: 57,
                          top: 0,
                          bottom: 0,
                          width: "3px", // Adjust width as needed for the vertical line
                          backgroundColor: "white", // Color of the vertical line
                        }}
                      />
                      <Button
                        style={{
                          backgroundColor: "rgba(111, 100, 88, 0)",
                          border: "none",
                          color: "white",
                        }}
                        onClick={handleClearButtonClick}
                      >
                        <Trash size={"20px"} style={{ paddingRight: "5px" }} />
                        Clear all
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: "100%" }}>
                  <Form style={{ marginLeft: "2%" }}>
                    <Form.Label>Members</Form.Label>
                    <Form.Check
                      type="checkbox"
                      id="unassigned"
                      value="unassigned"
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={AvatarCom}
                            alt="Avatar"
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          />
                          No members
                        </div>
                      }
                      onChange={(e) => handleCheckboxChange(e)}
                    />
                    {members?.map((member) => (
                      <div
                        key={member?._id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <Form.Check
                          type="checkbox"
                          id={member._id}
                          label={
                            <>
                              <span
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  borderRadius: "50%",
                                  padding: "2px",
                                  marginRight: "5px",
                                }}
                              >
                                {member.name &&
                                  member.name
                                    .split(" ")
                                    .map((namePart, index) =>
                                      index < 2 ? namePart.charAt(0) : ""
                                    )
                                    .join("")}
                              </span>
                              {member.name}
                            </>
                          }
                          value={member._id}
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                      </div>
                    ))}
                  </Form>
                  <hr />
                  <Form style={{ marginLeft: "2%" }}>
                    <Form.Label>Type</Form.Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Check
                        type="checkbox"
                        id="classic"
                        value="classic"
                        onChange={(e) =>
                          handleColorSelection("classic", e.target.checked)
                        }
                      />
                      <span
                        style={{
                          backgroundColor: "#4FC1E8",
                          width: "80%",
                          height: "30px",
                          borderRadius: "4px",
                          marginLeft: "10px",
                        }}
                        // onClick={() => handleColorSelection('classic')}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        id="onlyMe"
                        value="onlyMe"
                        onChange={(e) =>
                          handleColorSelection("onlyMe", e.target.checked)
                        }
                      />
                      <span
                        style={{
                          backgroundColor: "#FFCE54",
                          width: "80%",
                          height: "30px",
                          borderRadius: "4px",
                          marginLeft: "10px",
                        }}
                        // onClick={() => handleColorSelection('onlyMe')}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        id="meta"
                        value="meta"
                        onChange={(e) =>
                          handleColorSelection("meta", e.target.checked)
                        }
                      />
                      <span
                        style={{
                          backgroundColor: "#ED5564",
                          width: "80%",
                          height: "30px",
                          borderRadius: "4px",
                          marginLeft: "10px",
                        }}
                        // onClick={() => handleColorSelection('meta')}
                      />
                    </div>
                  </Form>
                  <hr />
                  <Form style={{ marginLeft: "2%" }}>
                    <Form.Label>Search with title</Form.Label>
                    <AutocompleteCardTitle
                      selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                      setSearched={setSearched}
                    />
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <div className="d-flex align-items-center">
              {showAddMember ? (
                <AutocompleteUsers
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  setShowAddMember={setShowAddMember}
                />
              ) : (
                <>
                  <div className="d-flex align-items-center">
                    <Dropdown
                      onMouseEnter={() => setShowDropdown(true)}
                      onMouseLeave={() => setShowDropdown(false)}
                    >
                      <AvatarGroup className="me-0">
                        {members
                          ?.slice(0, members?.length > 6 ? 6 : members?.length)
                          ?.map((member, index) => (
                            <Avatar
                              key={index}
                              size="md"
                              type="initial"
                              name={`${member?.name
                                ?.split(" ")[0]
                                ?.charAt(0)}${member?.name
                                ?.split(" ")[1]
                                ?.charAt(0)}`}
                              variant="success"
                              className="rounded-circle text-white"
                              showExact
                            />
                          ))}
                        {members?.length > 6 && (
                          <Avatar
                            size="md"
                            type="initial"
                            name="7+"
                            variant="light"
                            className="rounded-circle text-black"
                            showExact
                          />
                        )}
                      </AvatarGroup>

                      <Dropdown.Menu show={showDropdown}>
                        {members?.map((member, index) => (
                          <Dropdown.Item key={index}>
                            {" "}
                            <User size="16px" style={{ marginLeft: "3px" }} />
                            {member.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* icon */}
                    <Link
                      onClick={() => handleShowAddMember()}
                      to="#"
                      className="btn btn-icon btn-white border border-2 rounded-circle btn-dashed ms-2"
                    >
                      +
                    </Link>
                  </div>
                </>
              )}
              <MoreVertical
                style={{ marginLeft: "10px", zIndex: 999 }}
                onClick={() => handleShowLog()}
              />
            </div>
          </div>
        </Col>
      </Row>

      <DragDropContext onDragEnd={handleDragEnd}>
        <div
          className="task-kanban-container pb-6"
          style={{ overflowX: "auto", whiteSpace: "nowrap" }}
        >
          {lists?.map((item, index) => {
            if (
              userData?.role === "student" &&
              item.title === "Product Backlog"
            ) {
              return null;
            }

            return (
              <div
                key={index}
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                }}
              >
                <KanbanColumn
                  columnData={item}
                  setListId={setListId}
                  listId={listId}
                  filterType={filterType}
                  selectedCard={selectedCard}
                  searched={searched}
                  setMetaId={setMetaId}
                  setShowMetaModal={setShowMetaModal}
                />
              </div>
            );
          })}

          {showForm ? (
            <div
              style={{
                backgroundColor: "#E2E8F0",
                padding: "10px",
                borderRadius: "5px",
                height: "fit-content",
              }}
            >
              <Form onSubmit={(e) => handleAddList(e)}>
                <Form.Group className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Add List Title"
                    id="title"
                    required
                    onChange={(e) =>
                      setListData({ ...listData, title: e.target.value })
                    }
                  />
                </Form.Group>
              </Form>
              <Button variant="info" onClick={(e) => handleAddList(e)}>
                Add list
              </Button>

              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid transparent",
                  color: "black",
                }}
                onClick={handleClose}
              >
                X
              </Button>
            </div>
          ) : (
            <Button
              style={{
                height: "fit-content",
                backgroundColor: "#E2E8F0",
                border: "1px solid #E2E8F0",
                transition: "background-color 0.3s, color 0.3s",
                color: "black",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "black";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "#E2E8F0";
                e.target.style.color = "black";
              }}
              onClick={() => setShowForm(true)}
            >
              <Plus size="15px" />
              Add another list
            </Button>
          )}
        </div>
      </DragDropContext>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {listId ? "Update List" : "Add New List"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="List Title"
                id="title"
                required
                value={listData.title}
                onChange={(e) =>
                  setListData({ ...listData, title: e.target.value })
                }
              />
            </Form.Group>
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <Form.Select
                id="title"
                required
                value={listData.type}
                onChange={(e) =>
                  setListData({ ...listData, type: e.target.value })
                }
              >
                <option value="">Select Type</option>
                <option value="product">Product</option>
                <option value="sprint">Sprint</option>
                <option value="doing">Doing</option>
                <option value="done">Done</option>
              </Form.Select>
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                id="add-new-event-btn"
                // disabled={loading}
                onClick={(e) => handleAddList(e)}
              >
                {listId ? "Update List" : "Add New List"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showMenu}
        onHide={() => setShowMenu(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul
            style={{
              cursor: "pointer",
            }}
          >
            <li onClick={() => setShowLogs(true)}>LOGS</li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <BoardLogs show={showLogs} setShow={setShowLogs} />
    </Fragment>
  );
};

export default Kanban;
