// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link, useParams } from 'react-router-dom';
import { Col, Row, Button, Image, Dropdown, Table, Modal } from 'react-bootstrap';


// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import DotBadge from 'components/elements/bootstrap/DotBadge';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBookFromPackAction } from 'actions/packs'

const PackTable = ({ books}) => {
	const dispatch = useDispatch()
	const { id: pack_id } = useParams()
	const handleDeleteBookFromPack = (book_id) => {
		setBookFromPackToDelete(book_id);
		setShowDeleteModal(true);
	};

	const confirmDeleteBookFromPack = () => {
		if (bookFromPackToDelete && confirmationMessage === 'DELETE BOOK') {
			dispatch(deleteBookFromPackAction(bookFromPackToDelete, pack_id));
			setBookFromPackToDelete(null);
			setShowDeleteModal(false);
			setConfirmationMessage('');
		}
	};

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [bookFromPackToDelete, setBookFromPackToDelete] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState('');

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'Book ID', show: true },
			{
				accessor: 'title',
				Header: 'Book Title',
				Cell: ({ value, row }) => {
					return (
							<div className="d-lg-flex align-items-center">
								<div>
									<Image
										src={row.original.img}
										alt=""
										className="img-4by3-lg rounded"
									/>
								</div>
								<div className="ms-lg-3 mt-2 mt-lg-0">
									<h4 className="mb-1 text-primary-hover">{value}</h4>
									<span className="text-inherit">
										{row.original.release_date}
									</span>
								</div>
							</div>
						
					);
				}
			},
			{ accessor: 'author', Header: 'The Author', show: true },
			{ accessor: 'category', Header: 'Category', show: true },
			{ accessor: 'page_number', Header: 'Page Number', show: true },

			{
				accessor: 'action',
				Header: '',
				Cell: ({row}) => {

					return (
						<Fragment >

							<Button 
								onClick={() => handleDeleteBookFromPack (row.values.id)}
								variant="danger"
							    // onClick={confirmDeleteBookFromPack}
								

							>
								delete
							</Button>
						</Fragment>
					);
				}


			},
		],
		[]
	);

	const data = useMemo(() => books, [books]);


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className="overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Book"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive overflow-y-hidden">
				<Table
					hover
					{...getTableProps()}
					className="text-nowrap table-centered"
				>
					<thead className="table-light">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>


			<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>Confirm Deletion</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>Are you sure you want to delete this book ?</p>
							<h4 className="text-secondary" >you must write "DELETE BOOK" to confirm the deletion</h4>
							<input
								className="form-control"
								rows="3"
								placeholder="Enter confirmation message"
								value={confirmationMessage}
								onChange={(e) => setConfirmationMessage(e.target.value)}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
								Cancel
							</Button>
							<Button variant="danger" onClick={confirmDeleteBookFromPack}>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>
			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default PackTable;
