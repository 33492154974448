import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getDescriptionById,
  editDescription,
  deleteDescription,
  createDescription,
  fetchDescriptionByCard,
  getCardById,
  updateBoardLog,
  createDescriptionToChilds,
  editRelatedDescriptions,
  deleteRelatedDescriptions,
} from "api";
import {
  GET_DESC,
  LOADING,
  GET_DESC_BY_CARD,
  GET_CARD,
  DELETE_DESCRIPTION,
  CREATE_DESC,
  EDIT_DESC,
} from "./constants";
const userDataString = localStorage.getItem("userData");
const userData = JSON.parse(userDataString);
export const fetchDescriptionAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getDescriptionById(id);
    dispatch({ type: GET_DESC, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchDescriptionByCardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchDescriptionByCard(id);
    dispatch({ type: GET_DESC_BY_CARD, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const editDescriptionAction =
  (fromData, handleClose, setDescId, descId, template) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await editDescription(fromData, descId);
      if (template) {
        await editRelatedDescriptions(descId);
      }
      dispatch({ type: EDIT_DESC, payload: data });
      handleClose();
      setDescId(null);
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteDescriptionAction = (id, template) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    if (template) {
      await deleteRelatedDescriptions(id);
    } else {
      await deleteDescription(id);
    }

    dispatch({ type: DELETE_DESCRIPTION, payload: id });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const createDescriptionAction =
  (
    fromData,
    handleClose,
    boardId,
    setShowRelatedCards,
    setDescTemplate,
    template
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createDescription(fromData);
      if (template) {
        setShowRelatedCards(true);
        setDescTemplate(data._id);
      }

      dispatch({ type: CREATE_DESC, payload: data });

      handleClose();

      await updateBoardLog({
        task: fromData.cardId,
        project: boardId,
        user: userData._id,
        action: "Description Added",
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const createDescriptionToChildsAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createDescriptionToChilds(fromData);

      dispatch({ type: CREATE_DESC, payload: data });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
