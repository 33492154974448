import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect
} from 'react-table';
import {
	Col,
	Row,
	Dropdown,
	Card,
	Breadcrumb,
	Button,
	Image,
	Modal,
	Table,
	
} from 'react-bootstrap';
import { Trash, Send, Inbox, MoreVertical, Edit, Eye } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
import AddNewPack from './AddNewPack';

// import data files
import { packs } from 'data/courses/CoursesCategoryData';
import { deletePackAction, fetchAllPacksAction } from 'actions/packs';



const AllPacks = () => {
	const [packId, setPackId] = useState(null)
	const [showPack, setShowPack] = useState(false);

	useEffect(() => {
		if (packId) setShowPack(true)
	}, [packId])

	const dispatch = useDispatch();
	const { packs } = useSelector(state => state.packs);
	useEffect(() => {
		dispatch(fetchAllPacksAction())

	}, [dispatch]);

	const handleDeletePack = (id) => {
		setPackToDelete(id);
		setShowDeleteModal(true);
	};

	const confirmDeletePack = () => {
		if (packToDelete && confirmationMessage === 'DELETE PACK') {
			dispatch(deletePackAction(packToDelete));
			setPackToDelete(null);
			setShowDeleteModal(false);
			setConfirmationMessage('');
		}
	};
	useEffect(() => {
		if (packId) setShowPack(true)
	}, [packId])

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [packToDelete, setPackToDelete] = useState(null);
	const [confirmationMessage, setConfirmationMessage] = useState('');



	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	/* 	const ActionMenu = () => {
			return (
				<Dropdown>
					<Dropdown.Toggle as={CustomToggle}>
						<MoreVertical size="15px" className="text-secondary" />
					</Dropdown.Toggle>
					<Dropdown.Menu align="end">
						<Dropdown.Header>ACTION</Dropdown.Header>
						<Dropdown.Item eventKey="1">
							{' '}
							<Send size="18px" className="dropdown-item-icon" /> Publish
						</Dropdown.Item>
						<Dropdown.Item eventKey="2">
							{' '}
							<Inbox size="18px" className="dropdown-item-icon" /> Moved Draft
						</Dropdown.Item>
						<Dropdown.Item eventKey="3">
							{' '}
							<Trash size="18px" className="dropdown-item-icon" /> Delete
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			);
		}; */
	const ActionMenu = ({id, setPackId}) => {
		return (
			<Dropdown style={{ zIndex: 1000}}>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" style={{ marginTop: '50px' }}  />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1">
						{' '}
						<Link to={'/books-management/pack_details/'+id}>
						<Eye size="15px" className="dropdown-item-icon" />Pack Details
						</Link>
					</Dropdown.Item>
					<Dropdown.Item
					 eventKey="2"
					 onClick={() => {setPackId(id);}}>
						{' '}
						<Edit size="15px" className="dropdown-item-icon" /> Edit
					</Dropdown.Item>
					<Dropdown.Item eventKey="3"
						onClick={() => handleDeletePack(id)}
					>
						{' '}
						<Trash
							size="15px"
							className="dropdown-item-icon"
							variant="danger"
							onClick={confirmDeletePack}
							disabled={confirmationMessage !== 'DELETE PACK'}
						/> Remove
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: true },
			{
				accessor: 'title',
				Header: 'Packs',
				Cell: ({ value, row }) => {
					return (
							<div className="d-flex align-items-center justify-content-center flex-column">
								<div  style={{marginBottom:"15px"}}>
									<Image
										src={row.original.img}
										alt=""
										className="img-4by3-lg rounded"
									/>
								</div>
								<div className="ms-lg-3 mt-2 mt-lg-0">
									<h4 className="text-secondary">{value}</h4>
								</div>
							</div>
					);
				}
			},
			// { accessor: 'title', Header: 'CATEGORY' },
			{ accessor: 'book_number',
			 Header: 'Book numbers',
			 Cell: ({ value}) => {
				return (
				<div  className="d-flex justify-content-center" style={{ marginTop: '50px' }} >
				<h5 className="text-secondary" >{value}</h5>
			  </div>
			  )
			 }},

			{ accessor: 'level',
			 Header: 'Level',
			Cell: ({ value}) => {
				return (
				<div  className="d-flex justify-content-center" style={{ marginTop: '50px' }} >
				<h5 className="text-secondary" >{value}</h5>
			  </div>
			  )
			 }
		},
			{ accessor: 'age', Header: 'Age',
			Cell: ({ value}) => {
				return (
				<div  className="d-flex justify-content-center" style={{ marginTop: '50px' }} >
				<h5 className="text-secondary" >{value}</h5>
			  </div>
			  )
			 } },
			/* {
				accessor: 'status',
				Header: 'STATUS',
				Cell: ({ value }) => {
					return (
						<DotBadge
							bg={value === 1 ? 'success' : value === 0 ? 'warning' : ''}
						></DotBadge>
					);
				}
			}, */
			{ accessor: 'price', Header: 'Price',
			Cell: ({ value}) => {
				return (
				<div  className="d-flex justify-content-center" style={{ marginTop: '50px' }} >
				<h5 className="text-secondary" >{value}</h5>
			  </div>
			  )
			 } },
			{ accessor: 'discount', Header: 'Discount',
			Cell: ({ value}) => {
				return (
				<div  className="d-flex justify-content-center" style={{ marginTop: '50px' }} >
				<h5 className="text-secondary" >{value}</h5>
			  </div>
			  )
			 } },
			{
				accessor: 'action',
				Header: '',
				Cell: (row) => {
					return <ActionMenu id={row.row.original.id} setPackId={setPackId} />;
				}
			}
		],
		[]
	);

	const data = useMemo(() => packs, [packs]);
	

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		// useRowSelect,
		// (hooks) => {
		// 	hooks.visibleColumns.push((columns) => [
		// 		{
		// 			id: 'selection',
		// 			Header: ({ getToggleAllRowsSelectedProps }) => (
		// 				<Checkbox {...getToggleAllRowsSelectedProps()} />
		// 			),
		// 			Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
		// 		},
		// 		...columns
		// 	]);
		// }
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Packs</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Books management</Breadcrumb.Item>
								<Breadcrumb.Item active>Packs</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<Col className="d-flex justify-content-end">
							<div>
								<AddNewPack
									showPack={showPack}
									setShowPack={setShowPack}
									setPackId={setPackId}
									packId={packId}
								/>
							</div>
						</Col>
					</div>
				</Col>
				{/* <div>
							<Button variant="primary" onClick={handleShow}>
								Add New Pack
							</Button>
							<Modal show={show} onHide={handleClose} size="lg">
								<Modal.Header closeButton>
									<Modal.Title>Create Pack</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<AddNewPack />
								</Modal.Body>
								<Modal.Footer className="d-flex justify-content-start border-0 pt-0">
									<Button variant="primary" onClick={handleClose}>
										Add New Pack
									</Button>
									<Button variant="outline-secondary" onClick={handleClose}>
										Close
									</Button>
								</Modal.Footer>
							</Modal>
						</div> 
			</div>
		</Col>*/}
			</Row >

	<Row>
		<Col lg={12} md={12} sm={12}>
			<Card>
				<Card.Body className="p-0">
					<div className=" overflow-hidden">
						<Row>
							<Col
								lg={12}
								md={12}
								sm={12}
								className="mb-lg-0 mb-2 px-5 py-4"
							>
								<GlobalFilter
									filter={globalFilter}
									setFilter={setGlobalFilter}
									placeholder="Search Pack"
								/>
							</Col>
						</Row>
					</div>

					<div className="table-responsive ">
						<Table {...getTableProps()} className="text-nowrap">
							<thead className="table-light">
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th {...column.getHeaderProps()}>
												{column.render('Header')}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return (
													<td {...cell.getCellProps()}>
														{cell.render('Cell')}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>

					<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>Confirm Deletion</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>Are you sure you want to delete this pack?</p>
							<h4 className="text-secondary" >you must write "DELETE PACK" to confirm the deletion</h4>
							<input
								className="form-control"
								rows="3"
								placeholder="Enter confirmation message"
								value={confirmationMessage}
								onChange={(e) => setConfirmationMessage(e.target.value)}
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
								Cancel
							</Button>
							<Button variant="danger" onClick={confirmDeletePack}>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>

					{/* Pagination @ Footer */}
					<Pagination
						previousPage={previousPage}
						pageCount={pageCount}
						pageIndex={pageIndex}
						gotoPage={gotoPage}
						nextPage={nextPage}
					/>
				</Card.Body>
			</Card>
		</Col>
	</Row>
	<ToastContainer/>
		</Fragment >
	);
};

export default AllPacks;
