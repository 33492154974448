// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import custom components
import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';

// import sub components
import AdminList from './AdminList';
import AddNewAdmin from './AddNewAdmins';


const Admins = () => {
	const [userId, setUserId] = useState(null)
	//calendar
	const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
	useEffect(() => {
		if (userId) setShowEventOffcanvas(true)
	}, [userId])

	return (
		<Fragment>
			<Tab.Container defaultActiveKey="list">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Admins
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">User management</Breadcrumb.Item>
									<Breadcrumb.Item active>Admins</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							{/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}


						</div>
						<Col className="d-flex justify-content-end">
							<div>
								<AddNewAdmin
									showOffcanvas={showEventOffcanvas}
									setShowOffcanvas={setShowEventOffcanvas}
									setUserId={setUserId}
									userId={userId}
								/>

							</div>
						</Col>
					</Col>
				</Row>
				<Tab.Content>

					<Tab.Pane eventKey="list" className="pb-4">
						{/* students in list view */}
						<AdminList
							setUserId={setUserId}
						/>
						{/* <StudentsListItems /> */}
						{/* end of students in list view */}
					</Tab.Pane>
					<Tab.Pane eventKey="grid" className="pb-4">
						<Card className="mb-5 ">
							<Card.Body className="p-0">

								{/* students in list view */}
								{/* <StudentsGridCard /> */}
								{/* <UsersGrid /> */}
								{/* end of students in list view */}
							</Card.Body>
						</Card>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
			<ToastContainer />
		</Fragment>

	);
};

export default Admins;
