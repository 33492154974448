import {
    GET_ALL_KANBAN_TASKS,
    EDIT_KANBAN_TASK,
    DELETE_KANBAN_TASK,
    CREATE_KANBAN_TASK,
    GET_ONE_KANBAN_TASK,
    GET_KANBAN_TASK_BY_TEACHER,
    GET_ALL_STATUS,
  } from "../actions/constants";
  
  const kanbanReducer = (state = { kanbanTasks: [],kanbanStatus:[], kanbanTask: {} }, action) => {
    switch (action.type) {
      case GET_ALL_KANBAN_TASKS:
        return {
          ...state,
          kanbanTasks: action?.payload
        };
        case GET_ALL_STATUS:
            return {
              ...state,
              kanbanStatus: action?.payload
            };
      case GET_KANBAN_TASK_BY_TEACHER:
        return {
            ...state, kanbanTasks:action?.payload
        };
      case GET_ONE_KANBAN_TASK:
        return { ...state, kanbanTask: action?.payload };
      case EDIT_KANBAN_TASK:
        return { ...state, kanbanTasks: state.kanbanTasks.map(e=>{
          if(e._id==action.payload._id){
            return action.payload
          }
          else{
            return e
          }
        }) };
      case DELETE_KANBAN_TASK:
        return { ...state, kanbanTasks: state.kanbanTasks.filter(e => e._id !== action.payload) };
      case CREATE_KANBAN_TASK:
        return { ...state, kanbanTasks: [...state.kanbanTasks, action.payload] };
      default:
        return state;
    }
  };
  
  export default kanbanReducer;
  