// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import sub components
import {
  fetchAllBoardsAction,
  fetchBoardByTeacherAction,
} from "actions/boardKanban";
import { useSelector, useDispatch } from "react-redux";
import GridCard from "./GridCard";
import { useSearchParams } from "react-router-dom";

const BoardKanbanGrid = ({ setBoardId }) => {
  const dispatch = useDispatch();
  const { boards } = useSelector((state) => state.boards);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [searchParams] = useSearchParams();
  const template = searchParams.get("template");

  useEffect(() => {
    if (userData.role === "admin") {
      dispatch(fetchAllBoardsAction(template));
    } else {
      dispatch(fetchBoardByTeacherAction(userData._id));
    }
  }, [dispatch, userData._id, template]);
  const [show, setShow] = useState(false);

 

  const [ProjectsList, setProjectsList] = useState(boards.slice(0, 500));
  // paging start
  const [pageNumber, setPageNumber] = useState(0);
  const projectsPerPage = 400;
  const pagesVisited = pageNumber * projectsPerPage;

  const displayProjects = boards?.map((item, index) => {
    return (
      <Col xxl={3} xl={4} lg={6} xs={12} className="mb-4" key={index}>
        <GridCard item={item} setBoardId={setBoardId} />
      </Col>
    );
  });
  // end of paging

  // searching code started
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Fragment>
      {/* displaying records */}
      <Row style={{ marginTop: "2%" }}>
        {displayProjects.length > 0 ? (
          displayProjects
        ) : (
          <Col>No matching projects found.</Col>
        )}
      </Row>
    </Fragment>
  );
};

export default BoardKanbanGrid;
