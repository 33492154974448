import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changeStatus, createNote, deleteNote, editNote, fetchAllNotes, fetchNoteByTeacher, getNoteById } from "api";
import { CHANGE_STATUS, CREATE_NOTE, DELETE_NOTE, EDIT_NOTE, GET_ALL_NOTES, GET_NOTE_BY_TEACHER, GET_ONE_NOTE, LOADING } from "./constants";

export const createNoteAction = (clientId,message,setMessage) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const teacherId = localStorage.getItem('userId');
    const allUserData = localStorage.getItem('userData')
    const userData = JSON.parse(allUserData);
    const schoolId = userData.schoolId
    const fromData = { clientId,message,teacherId,schoolId };
    const {data} = await createNote(fromData)
    dispatch({ type: CREATE_NOTE, payload: data });
    setMessage('')
    toast.success(data.message, {
         
        autoClose: 1000,
      });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const fetchAllNoteAction = () => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const {data}= await fetchAllNotes()
      dispatch({ type: GET_ALL_NOTES, payload: data.notes });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

export const fetchNoteByTeacherAction = () => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const id = localStorage.getItem('userId');
      const {data} = await fetchNoteByTeacher(id);
      dispatch({ type: GET_NOTE_BY_TEACHER, payload: data.notes });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

  export const deleteNoteAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await deleteNote(id);
      dispatch({ type: DELETE_NOTE, payload: id });
      
        toast.success(data.message, { autoClose: 1000,});
        
       
      
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};

export const fetchOneNoteAction = (id) => async (dispatch) => {
    
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getNoteById(id);
      dispatch({ type: GET_ONE_NOTE, payload: data.note});
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

  export const editNoteAction = (fromData, handleClose,setNoteId,id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
     const {data} = await editNote (fromData,id);
      dispatch({ type: EDIT_NOTE, payload: data.data });
  handleClose()
  setNoteId()
  toast.success(data.message, {
           
    autoClose: 1000,
  });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

  export const changeStatusAction = (id,status) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
       const {data} = await changeStatus(id,status);
      dispatch({ type: CHANGE_STATUS, payload: id});
      toast.success(data.message, {
           
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };