// import node module libraries
import { Fragment, useState, useEffect, useRef } from 'react';
import { Form, FormControl, InputGroup, Button, Offcanvas, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';


// import custom components
import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
import { FormSelect } from 'components/elements/form-select/FormSelect';
import { createBookAction, editBookAction, fetchBookAction } from 'actions/books';
import DatePicker from 'react-flatpickr';
import { date } from 'yup';

const AddNewBook = ({ bookId, setBookId, showBook, setShowBook }) => {

	const { book: bookEdited } = useSelector(state => state.books)
	const dispatch = useDispatch();
	const [book, setBook] = useState({
		title: "",
		author: "",
		release_date: "",
		page_number: 0,
		category: "",
		neo4j_id: null,
		
	});
	const [img, setImg] = useState("https://cdn-icons-png.flaticon.com/512/2702/2702162.png");
    //cloudinary
    const cloudinaryRef = useRef();
    const widgetRef = useRef();
    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;

        widgetRef.current = cloudinaryRef.current.createUploadWidget(
            {
                cloudName: "dtwkikmuy",
                uploadPreset: "wuraurta",
            },
            (error, result) => {
                if (result.info.secure_url) {

                    setImg(result.info.secure_url)

                }
            }
        );
    }, []);


	const handleAddBook = async (e) => {
		if (bookId) {
			dispatch(editBookAction({ ...book, img: img }, handleClose, setBookId))
		} else {
			dispatch(createBookAction({ ...book, img: img }, handleClose));
		}
		e.preventDefault();
	};

	useEffect(() => {
		if (bookId) {
			dispatch(fetchBookAction(bookId))
		}
	}, [dispatch, bookId])
	useEffect(() => {
		if (bookEdited?.id) {
			setBook({
				id: bookEdited.id,
				title: bookEdited.title,
				author: bookEdited.author,
				release_date: bookEdited.release_date,
				page_number: bookEdited.page_number,
				category: bookEdited.category,
			})
			setImg(
                 bookEdited.img
            )
		}
	}, [bookEdited])

	const handleClose = () => {
		setBook({
			title: "",
			author: "",
			release_date: "",
			page_number: 0,
			category: "",
			neo4j_id: null,
		  })
        setShowBook(false)
        setBookId(null)
	}
	const handleShow = () => setShowBook(true);


	const parentOptions = [
		{ value: '', label: 'Select' },
		{ value: 'ocean', label: 'Course' },
		{ value: 'blue', label: 'Tutorial' },
		{ value: 'purple', label: 'Workshop' },
		{ value: 'red', label: 'Company' }
	];

	const initialValue = `<h4>One Ring to Rule Them All</h4>
	<br />
	<p>
	Three Rings for the
	<p>
	One Ring to
	<b>rule</b> them all, <br />
	One Ring to find them, <br />
	One Ring to bring them all and in the darkness bind
	them. <br />
	In the Land of Mordor where the Shadows lie.
	</p>
	<p>
	<br />
	</p>`;

	return (
		<Fragment>
			<Button variant="primary" onClick={handleShow}>
				Add New Book
			</Button>

			<Offcanvas
				show={showBook}
				onHide={handleClose}
				placement="end">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>{bookId ? 'Update Book' : 'Add New Book'}</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Form>
						 {/* Image  */}
						 <Form.Group className="mb-3">
                            <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="me-3"
                                    style={{
                                        width: '130px', 
                                        height: '130px', 
                                    }}
                                />
                                <Button
                                style={{
                                    marginTop:"21px",
                                    width:"100%"
                                }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {bookId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group>
						{/* Title  */}
						<Form.Group className="mb-3">
							<Form.Label>Book Title</Form.Label>
							<Form.Control
								type="text"
								placeholder="Book Title"
								id="title"
								required
								value={book.title}
								onChange={(e) =>
									setBook({ ...book, title: e.target.value })
								}
							/>
						</Form.Group>
						{/* author  */}
						<Form.Group className="mb-3">
							<Form.Label>The Author</Form.Label>
							<Form.Control
								type="text"
								placeholder="The author"
								id="author"
								required
								value={book.author}
								onChange={(e) =>
									setBook({ ...book, author: e.target.value })
								}
							/>
						</Form.Group>
						{/* Releasing date  */}
					
						 <Form.Group className="mb-3">

							<Form.Label>Releasing Date</Form.Label>
						
							<Form.Control
								type="date"
								placeholder="Releasing date"
								id="release_date"
								required
								value={book?.release_date}
								onChange={(e) =>
									setBook({ ...book, release_date: e.target.value })
								}
							/>
						</Form.Group> 
						{/* Page number  */}
						<Form.Group className="mb-3">
							<Form.Label>Page number</Form.Label>
							<Form.Control
								type="number"
								placeholder="Page number here"
								id="page_number"
								value={book.page_number}
								onChange={(e) =>
									setBook({ ...book, page_number: e.target.value })
								}
							/>
						</Form.Group>
						{/* Category  */}
						<Form.Group className="mb-3">
							<Form.Label>Category</Form.Label>
							<Form.Control
								type="text"
								placeholder="Category Here"
								id="category"
								value={book.category}
								onChange={(e) =>
									setBook({ ...book, category: e.target.value })
								}
							/>

						</Form.Group>

						<div className="mt-3">
							<Button type="submit"
								variant="primary"
								id="add-new-event-btn"
								onClick={(e) => handleAddBook(e)}>
								{bookId ? 'Update Book' : 'Add New Book'}
							</Button>

							<Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
								Close
							</Button>
						</div>
					</Form>
				</Offcanvas.Body>
			</Offcanvas>
		</Fragment>



	);
};

export default AddNewBook;
