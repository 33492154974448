// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";

import { Dropdown, Table } from "react-bootstrap";
// import custom components
import { Edit } from "react-feather";
import SpinnerLoading from "../SpinnerLoading";
import { useDispatch } from "react-redux";
import { updateTestSpeakingAction } from "actions/authClient";

const TestTable = () => {
  const dispatch = useDispatch();
  const levels = ["A1", "A2", "B1", "B2", "C1"];
  const { loading } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const [placmentTest, setPlacmentTest] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editTest, setEditTest] = useState(false);
  const columns = useMemo(
    () => [
      {
        accessor: "indexs",
        Header: "Test",
        Cell: ({ row }) => {
          return Number(row.id) == 0 ? "Listening" : "Test " + Number(row.id);
        },
      },
      {
        accessor: "success",
        Header: "Success",
        Cell: ({ row }) => {
          if (!row.original.success && row.original.completed) {
            return <span style={{ color: "red" }}>Failed</span>;
          } else if (row.original.success && row.original.completed) {
            return <span style={{ color: "green" }}>Success</span>;
          } else {
            return <span>----</span>;
          }
        },
      },
      {
        accessor: "Percentage",
        Header: "Percentage",
        Cell: ({ row }) => {
          if (!row.original.result) {
            return <span>----</span>;
          } else {
            return (
              <span style={{ color: row.original.success ? "green" : "red" }}>
                {Math.floor(row.original.result.percentage) + " %"}
              </span>
            );
          }
        },
      },
      {
        accessor: "score",
        Header: "Score",
        Cell: ({ row }) => {
          if (!row.original.result) {
            return <span>----</span>;
          } else {
            return (
              <span style={{ color: row.original.success ? "green" : "red" }}>
                {row.original.result.score +
                  "/" +
                  row.original.result.max_score}
              </span>
            );
          }
        },
      },
    ],
    []
  );

  const handleAddLevel = (level) => {
    dispatch(
      updateTestSpeakingAction(setEditTest,setEdit, client?.placmentTest?._id, {
        speaking: level,
      })
    );
  };

  const handleAddTestLevel = (level) => {
    dispatch(
      updateTestSpeakingAction(setEditTest,setEdit, client?.placmentTest?._id, {
        level: level,
      })
    );
  };
  useEffect(() => {
    if (client.placmentTest) {
      setPlacmentTest([
        client?.placmentTest?.test1,
        client?.placmentTest?.test2,
        client?.placmentTest?.test3,
        client?.placmentTest?.test4,
      ]);
    } else {
      setPlacmentTest([]);
    }
  }, [client]);

  const data = useMemo(() => placmentTest, [placmentTest]);
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 10,
          hiddenColumns: columns.map((column) => {
            if (column.show === false) return column.accessor || column.id;
            else return false;
          }),
        },
      },
      useFilters,
      useGlobalFilter,
      usePagination
    );

  return (
    <Fragment>
      {loading ? (
        <SpinnerLoading />
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <h4 style={{ marginRight: "30px", marginTop: "5px" }}>
              Speaking level:
            </h4>
            {client?.placmentTest?.speaking && !edit ? (
              <h3 style={{ color: "green" }}>
                {client?.placmentTest?.speaking}
                <Edit
                  style={{
                    color: "#1361BA",
                    margin: "0 0 5px 18px",
                    width: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => setEdit(true)}
                />
              </h3>
            ) : (
              <Dropdown>
                <Dropdown.Toggle> Select level</Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {levels.map((level, index) => (
                    <Dropdown.Item
                      eventKey={index}
                      onClick={() => handleAddLevel(level)}
                    >
                      {level}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <Table
            hover
            responsive
            {...getTableProps()}
            className="text-nowrap table-centered"
          >
            <thead className="table-light">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div style={{ display: "flex" }}>
            <h4 style={{ marginRight: "30px", marginTop: "5px" }}>
              Test's level:
            </h4>
            {!editTest ? (
              <h3 style={{ color: "green" }}>
                {client?.placmentTest?.level}
                <Edit
                  style={{
                    color: "#1361BA",
                    margin: "0 0 5px 18px",
                    width: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => setEditTest(true)}
                />
              </h3>
            ) : (
              <Dropdown>
                <Dropdown.Toggle> Select level</Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {levels.map((level, index) => (
                    <Dropdown.Item
                      eventKey={index}
                      onClick={() => handleAddTestLevel(level)}
                    >
                      {level}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </>
      )}
    </Fragment>
  );
};

export default TestTable;
