import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import { getAllClients, getClientById, editClient, deleteClient, createClient, getClientFromData, addClientInClass, generateExcelFile, getCode, searchReader, linkCode, checkSession } from "api";
import {
  GET_ALL_CLIENTS,
  GET_CLIENT,
  EDIT_CLIENT,
  DELETE_CLIENT,
  CREATE_CLIENT,
  LOADING,
  GET_CLIENT_FROM_DATA,
  ADD_CLIENT_FROM_DATA,
  ADD_CLIENT_IN_CLASS,
  GENERATE_EXCEL,
  SEARCH_READER,
  LINK_CODE,
  CHECK_SESSION
} from "./constants";
export const fetchAllClientsAction = (search, page) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllClients(page, search);

    if (data.data.length > 0) {
      const clientData = await Promise.all(
        data.data.map(async (client) => {
          if (client.code) {
            const code = client.code;
            const codeResponse = await getCode(code);
            const status = codeResponse.data.code.status;
            const packId = codeResponse.data.code.pack_id;
            const userId = codeResponse.data.code.user_id;
            return { ...client, codeStatus: status,pack_id:packId,userLink:userId ,totalPages: data.totalPages, totalClients: data.totalClients };
          } else {
            return { ...client, codeStatus: null, pack_id: null,userLink:null, totalPages: data.totalPages, totalClients: data.totalClients };
          }
        })
      );


      dispatch({ type: GET_ALL_CLIENTS, payload: { data: clientData, totalPages: data.totalPages, totalClients: data.totalClients } });
    } else {
      dispatch({ type: GET_ALL_CLIENTS, payload: data });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error('Error fetching clients:', error);
    if (error?.response?.data?.message) {
      console.error(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

// export const fetchAllClientsAction = (search,page) => async (dispatch) => {
//   try {
//     dispatch({ type: LOADING, payload: true });
//     const {data} = await getAllClients(page,search);
//     console.log(data,"client")
//     if (data.data.length > 0) {
//       const clientData = await Promise.all(
//         data.data.map(async (client) => {
          
//           if (client.code) {
//             const code = client.code;
//             console.log(client.code,"client code")
//             const codeResponse = await getCode(code);
//             console.log(codeResponse,"codeResp")
//             const status = codeResponse.data.code.status;
//             console.log(status,"status")
//             return { ...client, codeStatus: status };
//           } else {
//             return client;
//           }
//         })
//       );

//       dispatch({ type: GET_ALL_CLIENTS, payload: { data: clientData, totalPages: data.totalPages, totalClients: data.totalClients } });
//     } else {
//       dispatch({ type: GET_ALL_CLIENTS, payload: data });
//     }
//     console.log(clientData)
//     dispatch({ type: LOADING, payload: false });
//   } catch (error) {
//     console.log(error);
//     if (error?.response?.data?.message) {
//       console.log(error.response.data.message);
//       dispatch({ type: LOADING, payload: false });
//     }
//   }
// };
export const fetchClientAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getClientById(id);
      dispatch({ type: GET_CLIENT, payload: data});
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editClientAction = (fromData, handleClose,setClientId,id) => async (dispatch) => {
  // let fromData;

  // if (badgeClient !== null && badgeClient !== undefined) {
  //     fromData = { ...clientData, badge: badgeClient };
  // } else {
  //     fromData = { ...clientData };
  // }

  try {
    dispatch({ type: LOADING, payload: true });

   const {data} = await editClient (fromData,id);
    dispatch({ type: EDIT_CLIENT, payload: data.data });
handleClose()
setClientId()
toast.success(data.message, {
         
  autoClose: 1000,
});
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const deleteClientAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await deleteClient(id);
      dispatch({ type: DELETE_CLIENT, payload: id });
      
        toast.success(data.message, { autoClose: 1000,});
        
       
      
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createClientAction = (fromData, handleClose) => async (dispatch) => {
 

  // let fromData;

  // if (badgeClient !== null && badgeClient !== undefined) {
  //     fromData = { ...clientData, badge: badgeClient };
  // } else {
  //     fromData = { ...clientData };
  // }
    try {
      dispatch({ type: LOADING, payload: true });
      const {data} = await createClient(fromData);
      dispatch({ type: CREATE_CLIENT, payload: data.data });
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      handleClose()
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

  export const getClientFromDataAction = (fromData) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const {data} = await getClientFromData(fromData);
      dispatch({ type: GET_CLIENT_FROM_DATA, payload: data.data});
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
  export const addClientFromDataAction = (userData,classId,setSelectedClient) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const {data} = await createClient(userData);
      const clientId =data.data._id;
      const fromData = { classId: classId, clientId: clientId };
      const { data: clientAdded } = await addClientInClass(fromData);
      dispatch({ type: ADD_CLIENT_IN_CLASS, payload: clientAdded.client});
      setSelectedClient(null);
      toast.success(clientAdded.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.clientAdded?.message) {
        console.log(error.response.clientAdded.message);
        toast.error(error.response.clientAdded.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

  export const generateExcelFileAction = (class_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const {data} = await generateExcelFile(class_id);
      const downloadLink = document.createElement('a');
      downloadLink.href = data.path;
      downloadLink.download = 'filename.xlsx'; // You can set the desired filename here
      downloadLink.style.display = 'none';
  
      // Appending the link to the body and triggering the click
      document.body.appendChild(downloadLink);
      downloadLink.click();
  
      // Removing the link from the body
      document.body.removeChild(downloadLink);
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        console.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
  export const searchReaderAction = (packId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const fromData = {pack_id:packId};
      const {data} = await searchReader(fromData);
      dispatch({ type: SEARCH_READER, payload: data.users});
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
  export const linkCodeAction = (userId,code,handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const fromData = {user_id:userId,code:code};
      const {data} = await linkCode(fromData);
      dispatch({ type: LINK_CODE, payload: data});
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose()
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };

  export const checkSessionAction = (code,setClientCode) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const {data} = await checkSession(code);
      dispatch({ type: CHECK_SESSION, payload: data.session_follow_requests});
      toast.success(data.message, {
        autoClose: 1000,
      });
      setClientCode()
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };