import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllBoards,
  getBoardById,
  editBoard,
  deleteBoard,
  createBoard,
  fetchBoardByTeacher,
  getMembers,
  importBoard,
  exportBoard,
  getAllBoardLogs,
  updateBoardLog,
  removeMember,
  gentrateBoardForStudent,
  genrateTemplate,
} from "api";
import {
  GET_ALL_BOARDS,
  GET_BOARD,
  EDIT_BOARD,
  DELETE_BOARD,
  CREATE_BOARD,
  LOADING,
  GET_BOARD_FOR_TEACHER,
  GET_MEMBERS,
  GET_ALL_BOARD_LOGS,
  FETCH_TEMPLATES,
  GENERATE_TEMPLATE,
} from "./constants";
const userDataString = localStorage.getItem("userData");
const userData = JSON.parse(userDataString);
export const fetchAllBoardsAction = (template) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllBoards(template);
    dispatch({ type: GET_ALL_BOARDS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchBoardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getBoardById(id);
    dispatch({ type: GET_BOARD, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchBoardByTeacherAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchBoardByTeacher(id);
    dispatch({ type: GET_BOARD_FOR_TEACHER, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const editBoardAction =
  (boardData, boardId, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await editBoard(boardData, boardId);
      dispatch({ type: EDIT_BOARD, payload: data });
      const { data: members } = await getMembers(boardId);
      dispatch({ type: GET_MEMBERS, payload: members });
      handleClose();
      await updateBoardLog({
        project: boardId,
        user: userData._id,
        action: "Board Updated",
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteBoardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteBoard(id);
    dispatch({ type: DELETE_BOARD, payload: id });

    await updateBoardLog({
      project: id,
      user: userData._id,
      action: "Board Deleted",
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const createBoardAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createBoard(fromData);
      dispatch({ type: CREATE_BOARD, payload: data });
      handleClose();
      await updateBoardLog({
        project: data._id,
        user: userData._id,
        action: "Board Created",
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const getMembersAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getMembers(id);
    dispatch({ type: GET_MEMBERS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const removeMemberAction = (fromData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    await removeMember(fromData);
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const importBoardAction = (fromData, ownerId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await importBoard(fromData, ownerId);
    dispatch({ type: CREATE_BOARD, payload: data.board });
    await updateBoardLog({
      project: data.board._id,
      user: userData._id,
      action: "Board Created From Json File",
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const downloadBoardData = (boardId, title) => async (dispatch) => {
  try {
    // Make a GET request to download board data
    dispatch({ type: LOADING, payload: true });
    const { data } = await exportBoard(boardId);

    // Convert response data to JSON string
    const jsonData = data.board;

    // Create a Blob object from the JSON data
    const blob = new Blob([jsonData], { type: "application/json" });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${title}_data.json`);

    // Append the link to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    await updateBoardLog({
      project: boardId,
      user: userData._id,
      action: "Board Exported",
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const getAllBoardLogsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllBoardLogs(id);
    dispatch({ type: GET_ALL_BOARD_LOGS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const genrateBoardAction = (fromData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await gentrateBoardForStudent(fromData);
    dispatch({ type: CREATE_BOARD, payload: data });

    await updateBoardLog({
      project: data._id,
      user: userData._id,
      action: "Board Created",
    });
    navigate("/kanban_board_by_member");
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchTemplatsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllBoards("true");
    dispatch({ type: FETCH_TEMPLATES, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const genrateTemplatsAction = (template, close) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await genrateTemplate(template);
    dispatch({ type: GENERATE_TEMPLATE, payload: data });
    close();
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
