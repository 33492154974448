import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
function SelectUserModal({
  handleCloseModal,
  showModal,
  menbers,
  setIds,
  ids,
  handleSelect,
  handleAssingeCards,
}) {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      // Deselect all
      setIds([]);
    } else {
      // Select all
      const allIds = menbers.map((menber) => menber._id);
      setIds(allIds);
    }
    setSelectAll(!selectAll);
  };
  return (
    <Modal show={showModal} onHide={() => handleCloseModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Meta Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Assign Menbers to Card</p>
        <div>
          <label>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            <span style={{ marginLeft: "5px" }}>All</span>
          </label>
        </div>
        {menbers.map((menber) => (
          <div key={menber._id}>
            <label>
              <input
                type="checkbox"
                value={menber._id}
                checked={ids.includes(menber._id)}
                onChange={() => handleSelect(menber._id)}
              />
              <span style={{ marginLeft: "5px" }}>{menber?.name}</span>
            </label>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" onClick={() => handleAssingeCards()}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={() => handleCloseModal()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectUserModal;
