// import node module libraries

import { Fragment, useEffect, useRef, useState } from "react";
import { ListGroup, Form } from "react-bootstrap";
import { Pause, Play } from "react-feather";
const Question = (props) => {
  const { item, selectedAnswer, setSelectedAnswer, setCurrentQuestionId } =
    props;
  useEffect(() => {
    setCurrentQuestionId(item._id);
  }, [item]);

  const handleEssayChange = (element) => {
    setSelectedAnswer([element.target.value]);
    setCurrentQuestionId(item.question_id);
  };
  const handleChange = (e) => {
    const selectedValue = e.currentTarget.value;

    const isTrueFalseQuestion = item.type == "true-false";
    const isCheckbox = item.type == "checkbox";
    const isChecked = e.currentTarget.checked;
    if (isTrueFalseQuestion) {
      setSelectedAnswer(selectedValue);
    } else if (isCheckbox) {
      let updatedSelectedAnswers =
        selectedAnswer == null ? [] : [...selectedAnswer];

      if (isChecked) {
        updatedSelectedAnswers.push(Number(selectedValue)); // Add the ID to the array
      } else {
        updatedSelectedAnswers = updatedSelectedAnswers.filter(
          (id) => id !== Number(selectedValue)
        ); // Remove the ID from the array if unchecked
      }

      setSelectedAnswer(updatedSelectedAnswers); // Update the state with new array
    } else {
      setSelectedAnswer(Number(selectedValue));
    }
  };
  //audio
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    }
    // else {
    //   audio.pause();
    //   setIsPlaying(false);
    // }
  };
  return (
    <Fragment>
      <h3 className="mb-3">{item?.title}</h3>
      <p>{item?.desc}</p>
      {item.audio ? (
        <div style={{ width: "800px", marginBottom: "20px" }}>
          <audio ref={audioRef} style={{ display: "none" }}>
            <source src={item.audio} type="audio/mp3" />
          </audio>
          {isPlaying ? <Pause /> : <Play onClick={handlePlayPause} />}

          {/* <button style={{ marginBottom: "10px" }}>
            {isPlaying ? "Pause" : "Play"}
          </button> */}
        </div>
      ) : null}
      {item.type === "Essay" ? (
        <Fragment>
          <Form.Group className="mb-2">
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Type your response here"
              value={selectedAnswer[0]}
              onChange={handleEssayChange}
            />
          </Form.Group>
        </Fragment>
      ) : item.type === "true-false" ? (
        <ListGroup>
          <ListGroup.Item
            className={`list-group-item-action ${
              selectedAnswer === "true" ? "bg-light" : ""
            }`}
            aria-current="true"
          >
            <Form.Check
              checked={selectedAnswer === "true"}
              type="radio"
              id="1"
              name={"answer-1"}
              label="True"
              value="true"
              onChange={(e) => handleChange(e)}
            />
          </ListGroup.Item>
          <ListGroup.Item
            className={`list-group-item-action ${
              selectedAnswer === "false" ? "bg-light" : ""
            }`}
            aria-current="true"
          >
            <Form.Check
              checked={selectedAnswer === "false"}
              type="radio"
              id="2"
              name={"answer-2"}
              label="False"
              value="false"
              onChange={(e) => handleChange(e)}
            />
          </ListGroup.Item>
        </ListGroup>
      ) : item.type === "checkbox" ? (
        <ListGroup>
          {item?.data?.map((answer, index) => {
            const label = `${answer.content}`;
            const value = answer._id;
            const checked = selectedAnswer === value;

            return (
              <ListGroup.Item
                key={index}
                className={`list-group-item-action ${
                  selectedAnswer === answer.value ? "bg-light" : ""
                }`}
                aria-current="true"
              >
                <Form.Check
                  // checked={checked}
                  type="checkbox"
                  id={index}
                  name={"answer-" + index + 1}
                  label={label}
                  value={value}
                  onChange={(e) => handleChange(e, checked)}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : (
        <ListGroup>
          {item?.data?.map((answer, index) => {
            const label = `${answer.content}`;
            const value = answer._id;
            const checked = selectedAnswer === value;

            return (
              <ListGroup.Item
                key={index}
                className={`list-group-item-action ${
                  selectedAnswer === answer.value ? "bg-light" : ""
                }`}
                aria-current="true"
              >
                <Form.Check
                  checked={checked}
                  type="radio"
                  id={index}
                  name={"answer-" + index + 1}
                  label={label}
                  value={value}
                  onChange={(e) => handleChange(e, checked)}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      )}
    </Fragment>
  );
};

export default Question;
