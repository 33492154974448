import { PacksDetailsData } from 'data/courses/BootstrapCoursesData';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Import Typeahead CSS
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllTeachersAction } from 'actions/teachers';
import { searchReaderAction } from 'actions/client';

const AutocompleteIread = ({selectedReader, setSelectedReader,packIdToLink}) => {
	const dispatch = useDispatch();
	
	const {readers} = useSelector(state => state.users);
	// const teacher= teachers.filter(e=>e._id===session.teacherId)
 
//   useEffect(() => {
// if (teacher)
//  setSelectedReader(teacher)
//   },[session.teacherId]);

	useEffect(() => {
		dispatch(searchReaderAction(packIdToLink))
		 
	  }, [dispatch]);

  const handleSelection = (selected) => {
    if(selected?.length>0){
        setSelectedReader(selected);
        
    }
  };

  const options = [...readers];

  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="username" 
        options={options}
        placeholder="Search the Reader here"
        selected={selectedReader}
        onChange={handleSelection}
      />
    </div>
  );
};

export default AutocompleteIread;
