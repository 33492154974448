import { genrateDescToActivityAction } from "actions/boardCard";
import {
  createDescriptionAction,
  deleteDescriptionAction,
  editDescriptionAction,
  fetchDescriptionAction,
  fetchDescriptionByCardAction,
} from "actions/boardCardDesc";
import { fetchBoardAction } from "actions/boardKanban";
import { splitTextAction } from "actions/words";
import { generateText } from "helper/utils";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, Spinner } from "react-bootstrap";
import { Edit, MoreVertical, Trash } from "react-feather";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import RelatedCardModal from "./RelatedCardModal";

const TaskDescription = ({ card, board }) => {
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const { loading } = useSelector((state) => state.auth);
  const [showPromptDesc, setshowPromptDescs] = useState(false);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { cardDesc } = useSelector((state) => state.boards);
  const { taskPoints } = useSelector((state) => state.curriculum);
  const [showAddForm, setShowAddForm] = useState(false);
  const [descTemplate, setDescTemplate] = useState(null);
  const [promptActivity, setPromptActivity] = useState("");
  const [newDescription, setNewDescription] = useState({
    title: "",
    desc: "",
    forTeacher: false,
    cardId: card._id,
  });
  const [showRelatedCards, setShowRelatedCards] = useState(false);

  const handleCloseRelatedCards = () => {
    setShowRelatedCards(false);
    setNewDescription({
      title: "",
      desc: "",
      forTeacher: false,
      cardId: card._id,
    });
  };


  useEffect(() => {
    if (card._id) {
      dispatch(fetchDescriptionByCardAction(card._id));
    }
  }, [dispatch, card]);

  const handleAddButtonClick = () => {
    setShowAddForm(true);
    setEditingTitleId(null);
    setEditingDescId(null);
  };
  const handleClose = () => {
    setShowAddForm(false);
  };

  const handleSaveClick = () => {
    dispatch(
      createDescriptionAction(
        { ...newDescription, cardId: card._id },
        handleClose,
        boardId,
        setShowRelatedCards,
        setDescTemplate,
        board.template
      )
    );
    dispatch(
      splitTextAction(generateText([newDescription]), card.title, userData.name)
    );
  };

  const handleCheckboxChange = (e) => {
    setNewDescription({ ...newDescription, forTeacher: e.target.checked });
  };

  //react Quill

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ color: [] }],
        [{ background: [] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video"],
        ["clean"],
      ],
      // handlers: {
      //   image: handleImageUpload
      // }
    },
    // imageUploader: {
    //   upload: handleImageUpload
    // }
  };

  const formats = [
    "header",
    "font",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];

  const [descId, setDescId] = useState(null);
  const [editingTitleId, setEditingTitleId] = useState(null);
  const [editingDescId, setEditingDescId] = useState(null);

  const { desc: descEdited } = useSelector((state) => state.boards);

  useEffect(() => {
    if (descId) {
      dispatch(fetchDescriptionAction(descId));
    }
  }, [dispatch, descId]);

  useEffect(() => {
    if (descEdited?._id) {
      setNewDescription({
        _id: descEdited._id,
        title: descEdited.title,
        desc: descEdited.desc,
        forTeacher: descEdited.forTeacher,
        cardId: descEdited.cardId,
      });
    }
  }, [descEdited]);
  const handleTitleChange = (e) => {
    setNewDescription({ ...newDescription, title: e.target.value });
  };
  const handleDescriptionChange = (value) => {
    setNewDescription({ ...newDescription, desc: value });
  };
  const handleTtitleClick = (id) => {
    setEditingTitleId(id);
    setDescId(id);
  };

  const handleDescClick = (id) => {
    setEditingDescId(id);
    setDescId(id);
  };

  const [openedDropdowns, setOpenedDropdowns] = useState({});

  const toggleDropdown = (id) => {
    setOpenedDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const handleDeleteDesc = (id) => {
    dispatch(deleteDescriptionAction(id, board.template));
  };
  const handleCloseEdit = () => {
    setEditingTitleId(null);
    setEditingDescId(null);
    setNewDescription({
      title: "",
      desc: "",
      forTeacher: false,
      cardId: card._id,
    });
  };
  const handleSaveChange = () => {
    dispatch(
      editDescriptionAction(
        { ...newDescription, cardId: card._id },
        handleCloseEdit,
        setDescId,
        descId,
        board.template
      )
    );
    dispatch(
      splitTextAction(generateText([newDescription]), card.title, userData.name)
    );
  };

  const handleGenrateDesc = () => {
    const AIPoints = taskPoints.map((p) => {
      return {
        _id: p._id,
        title: p.title,
      };
    });
    dispatch(
      genrateDescToActivityAction(
        {
          cardId: card._id,
          covredPoints: AIPoints,
          promptActivity,
        },
        setshowPromptDescs
      )
    );
  };
  return (
    <div>
      {!showAddForm && !editingTitleId && !editingDescId && (
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <Button onClick={handleAddButtonClick} style={{ fontSize: "12px" }}>
            Add Description
          </Button>
          {userData.role === "admin" && (
            <Button
              onClick={() => setshowPromptDescs(true)}
              style={{ marginLeft: "10px", fontSize: "12px" }}
            >
              AI Activity
            </Button>
          )}
        </div>
      )}
      {showAddForm && (
        <div>
          <Form>
            <Form.Group controlId="formTitle">
              {<Form.Label>Title</Form.Label>}
              <Form.Control
                type="text"
                placeholder="Enter Title"
                value={newDescription.title}
                onChange={(e) => handleTitleChange(e)}
                style={{
                  marginTop: "1%",
                  marginBottom: "1%",
                  color: "black",
                  border: "none",
                  outline: "none",
                }}
              />
            </Form.Group>
            <Form.Group controlId="formDesc">
              {<Form.Label>Description</Form.Label>}
              <ReactQuill
                value={newDescription?.desc}
                onChange={(value) => handleDescriptionChange(value)}
                placeholder="Enter Description"
                modules={modules}
                formats={formats}
                style={{
                  marginTop: "1%",
                  marginBottom: "1%",
                  color: "black",
                  border: "none",
                  outline: "none",
                }}
              />
            </Form.Group>
            <Form.Group controlId="formForTeacher">
              <Form.Check
                style={{
                  marginLeft: "85%",
                }}
                type="checkbox"
                id="formForTeacherCheckbox"
                label="For Teacher"
                checked={newDescription?.forTeacher}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <div style={{ marginBottom: "2%" }}>
              <Button variant="primary" onClick={handleSaveClick}>
                Save
              </Button>{" "}
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      )}
      {cardDesc
        ?.filter((description) => description?.forTeacher === true)
        ?.map((filteredDescription) => (
          <div style={{ marginTop: "2%" }} key={filteredDescription?._id}>
            {userData?._id === board?.owner?._id &&
              !card.cardParent(
                <div>
                  <MoreVertical
                    size="15px"
                    style={{ marginLeft: "95%" }}
                    onClick={() => toggleDropdown(filteredDescription?._id)}
                  />

                  {openedDropdowns[filteredDescription?._id] && (
                    <Dropdown
                      style={{ zIndex: 10000, marginLeft: "95%" }}
                      show={openedDropdowns[filteredDescription?._id]}
                      align="end"
                      onSelect={() => toggleDropdown(filteredDescription?._id)}
                    >
                      <Dropdown.Menu align="end">
                        <Dropdown.Header>SETTINGS</Dropdown.Header>
                        {(userData?.role == "admin" ||
                          userData?.role === "learning-architect") && (
                          <Dropdown.Item
                            eventKey="3"
                            onClick={() =>
                              handleDeleteDesc(filteredDescription?._id)
                            }
                          >
                            {" "}
                            <Trash
                              size="15px"
                              className="dropdown-item-icon"
                              variant="danger"
                            />{" "}
                            Remove
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  {!editingTitleId ||
                  editingTitleId !== filteredDescription?._id ? (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#333",
                          marginBottom: "5px",
                        }}
                        onClick={() =>
                          handleTtitleClick(filteredDescription?._id)
                        }
                        dangerouslySetInnerHTML={{
                          __html: filteredDescription?.title,
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <Form.Control
                        type="text"
                        style={{ marginBottom: "2%" }}
                        value={newDescription?.title}
                        onChange={(e) => handleTitleChange(e)}
                        placeholder="Title Here"
                      />
                    </div>
                  )}
                  {!editingDescId ||
                  editingDescId !== filteredDescription?._id ? (
                    <div>
                      <p
                        style={{ marginLeft: "3%" }}
                        onClick={() =>
                          handleDescClick(filteredDescription?._id)
                        }
                        dangerouslySetInnerHTML={{
                          __html: filteredDescription?.desc,
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <ReactQuill
                        value={newDescription?.desc}
                        onChange={(value) => handleDescriptionChange(value)}
                        placeholder="Description Here"
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  )}
                  <hr />

                  {(editingTitleId || editingDescId) &&
                    (editingDescId === filteredDescription?._id ||
                      editingTitleId === filteredDescription?._id) && (
                      <div style={{ marginBottom: "2%" }}>
                        <Button
                          variant="primary"
                          onClick={() => handleSaveChange()}
                        >
                          Save change
                        </Button>{" "}
                        <Button variant="secondary" onClick={handleCloseEdit}>
                          Cancel
                        </Button>
                      </div>
                    )}
                </div>
              )}
          </div>
        ))}

      {cardDesc
        ?.filter((description) => description?.forTeacher === false)
        ?.map((filteredDescription) => (
          <div style={{ marginTop: "2%" }} key={filteredDescription?._id}>
            <div>
              <MoreVertical
                size="15px"
                style={{ marginLeft: "95%" }}
                onClick={() => toggleDropdown(filteredDescription?._id)}
              />

              {openedDropdowns[filteredDescription?._id] && (
                <Dropdown
                  style={{ zIndex: 10000, marginLeft: "95%" }}
                  show={openedDropdowns[filteredDescription?._id]}
                  align="end"
                  onSelect={() => toggleDropdown(filteredDescription?._id)}
                >
                  <Dropdown.Menu align="end">
                    <Dropdown.Header>SETTINGS</Dropdown.Header>
                    {(userData?.role == "admin" ||
                      userData?.role === "learning-architect") && (
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() =>
                          handleDeleteDesc(filteredDescription?._id)
                        }
                      >
                        {" "}
                        <Trash
                          size="15px"
                          className="dropdown-item-icon"
                          variant="danger"
                        />{" "}
                        Remove
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {!editingTitleId ||
              editingTitleId !== filteredDescription?._id ? (
                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#333",
                      marginBottom: "5px",
                    }}
                    onClick={() => handleTtitleClick(filteredDescription?._id)}
                    dangerouslySetInnerHTML={{
                      __html: filteredDescription.title,
                    }}
                  />
                </div>
              ) : (
                <div>
                  <Form.Control
                    type="text"
                    style={{ marginBottom: "2%" }}
                    value={newDescription?.title}
                    onChange={(e) => handleTitleChange(e)}
                    placeholder="Title Here"
                  />
                </div>
              )}
              {!editingDescId || editingDescId !== filteredDescription?._id ? (
                <div>
                  <p
                    style={{ marginLeft: "3%" }}
                    onClick={() => handleDescClick(filteredDescription?._id)}
                    dangerouslySetInnerHTML={{
                      __html: filteredDescription?.desc,
                    }}
                  />
                </div>
              ) : (
                <div>
                  <ReactQuill
                    value={newDescription?.desc}
                    onChange={(value) => handleDescriptionChange(value)}
                    placeholder="Description Here"
                    modules={modules}
                    formats={formats}
                  />
                </div>
              )}
              <hr />

              {(editingTitleId || editingDescId) &&
                (editingDescId === filteredDescription?._id ||
                  editingTitleId === filteredDescription?._id) && (
                  <div style={{ marginBottom: "2%" }}>
                    <Button
                      variant="primary"
                      onClick={() => handleSaveChange()}
                    >
                      Save change
                    </Button>{" "}
                    <Button variant="secondary" onClick={handleCloseEdit}>
                      Cancel
                    </Button>
                  </div>
                )}
            </div>
          </div>
        ))}
      <Modal show={showPromptDesc} onHide={() => setshowPromptDescs(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Generate Activity </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows="1"
            style={{
              marginTop: "1%",
              height: "fit-content",
              width: "100%",
              // fontSize: "18px",

              color: "black",
              border: "none",
              outline: "none",
            }}
            value={promptActivity}
            onChange={(e) => setPromptActivity(e.target.value)}
          ></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner style={{ height: "15px", width: "15px" }} />
          ) : (
            <Button onClick={() => handleGenrateDesc()}>Generate</Button>
          )}
        </Modal.Footer>
      </Modal>
      <RelatedCardModal
        handleCloseModal={handleCloseRelatedCards}
        showModal={showRelatedCards}
        id={card._id}
        newDescription={newDescription}
        descTempalte={descTemplate}
      />
    </div>
  );
};

export default TaskDescription;
