// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Tab, Breadcrumb } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import sub components
import { fetchClientAction } from "actions/client";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import TestTable from "./TestTable";

const PlacmentTestPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  //calendar

  useEffect(() => {
    dispatch(fetchClientAction(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <Tab.Container defaultActiveKey="list">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">Clients</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">Client</Breadcrumb.Item>
                  <Breadcrumb.Item active>Placement Test</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </Col>
        </Row>
        <>
          <Tab.Content>
            <Tab.Pane eventKey="list" className="pb-4">
              {/* students in list view */}

              <TestTable />

    
              {/* end of students in list view */}
            </Tab.Pane>
          </Tab.Content>
        </>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};

export default PlacmentTestPage;
