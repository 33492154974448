import { Button, Form, Image, Offcanvas } from "react-bootstrap";
import React, { Fragment, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker"; // Import the date picker component
import "react-datepicker/dist/react-datepicker.css"; // Import the date picker styles
import { useDispatch } from "react-redux";
import {
  createTeacherAction,
  editTeacherAction,
  fetchTeacherAction,
} from "actions/teachers";
import { useSelector } from "react-redux";

const AddNewTeacher = ({
  showOffcanvas,
  setShowOffcanvas,
  teacherId,
  setTeacherId,
}) => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { loading } = useSelector((state) => state.auth);
  const { teacher: teacherEdited } = useSelector((state) => state.teachers);
  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState({
    name: "",
    email: "",
    password: "",
    schoolId: "",
    role: "teacher",
  });

  useEffect(() => {
    if (userData) {
      setTeacher((teacher) => ({
        ...teacher,
        schoolId: userData.schoolId,
      }));
    }
  }, []);

  const handleClose = () => {
    setTeacher({
      name: "",
      email: "",
      password: "",
      schoolId: "",
      role: "teacher",
    });
    setShowOffcanvas(false);
    setTeacherId(null);
  };
  const handleShow = () => setShowOffcanvas(true);

  const handleAddTeacher = async (e) => {
    if (teacherId) {
      dispatch(
        editTeacherAction({ ...teacher }, handleClose, setTeacherId, teacherId)
      );
    } else {
      dispatch(createTeacherAction({ ...teacher }, handleClose));
    }
    e.preventDefault();
  };
  useEffect(() => {
    if (teacherId) {
      dispatch(fetchTeacherAction(teacherId));
    }
  }, [dispatch, teacherId]);
  useEffect(() => {
    if (teacherEdited._id) {
      setTeacher({
        name: teacherEdited.name,
        email: teacherEdited.email,
        _id: teacherEdited._id,
        schoolId: teacherEdited.schoolId,
        role: teacherEdited.role,
      });
    }
  }, [teacherEdited]);
  return (
    <Fragment>
      <Button variant="primary" onClick={handleShow}>
        Add New Teacher
      </Button>

      <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {teacherId ? "Update Teacher" : "Add New Teacher"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {/* Image  */}
            {/* <Form.Group className="mb-3">
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="rounded-circle me-3"
                                    style={{
                                        width: '130px',
                                        height: '130px',
                                    }}
                                />
                                <Button
                                    style={{
                                        marginTop: "21px",
                                        width: "100%"
                                    }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {teacherId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group> */}
            {/* Title  */}
            <Form.Group className="mb-3">
              <Form.Label>Teacher Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Teacher Name"
                id="name"
                required
                value={teacher.name}
                onChange={(e) =>
                  setTeacher({ ...teacher, name: e.target.value })
                }
              />
            </Form.Group>
            {/* author  */}
            <Form.Group className="mb-3">
              <Form.Label>Teacher Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="teacher Email"
                id="email"
                value={teacher.email}
                required
                onChange={(e) =>
                  setTeacher({ ...teacher, email: e.target.value })
                }
              />
            </Form.Group>
            {/* Releasing date  */}
            {/* <Form.Group className="mb-3">
                          
                                <Form.Label>Joined At</Form.Label>
                            <div className="d-flex flex-grow-1 align-items-center">
                                <DatePicker
                                    selected={selectedDate} // Pass the selected date value here
                                    onChange={handleDateChange} 
                                    placeholderText="Click Here" 
                                    className="form-control me-2" 
                                    id="release_date"
                                />
                            </div>
                        </Form.Group> */}
            {/* Page number  */}
            <Form.Group className="mb-3">
              <Form.Label>Teacher Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="**********"
                id="password"
                required
                value={teacher.password}
                onChange={(e) =>
                  setTeacher({ ...teacher, password: e.target.value })
                }
              />
            </Form.Group>
            {/* Category  */}
            {/* <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Category Here"
                                id="category"
                            />
                            <Form.Text className="text-muted">
                                Field must contain a unique value
                            </Form.Text>
                        </Form.Group> */}
            {/* Discount  */}
            {/*   <Form.Group className="mb-3">
                            <Form.Label>Discount</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Put de discount"
                                id="category-name"
                            />
                            <Form.Text className="text-muted">
                                Field must contain a unique value
                            </Form.Text>
                        </Form.Group>
 */}
            {/* <Form.Group className="mb-3">
                            <Form.Label>School ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="School ID"
                                id="schoolId"
                                required
                                value={teacher.schoolId}
                                onChange={(e) =>
                                    setTeacher({ ...teacher, schoolId: e.target.value })
                                }
                            />
                        </Form.Group> */}
            {/* User role  */}
            <Form.Group className="mb-3">
              <Form.Label>User Role</Form.Label>
              <Form.Select
                aria-label="Default select example"
                id="role"
                value={teacher?.role || "teacher"}
                onChange={(e) =>
                  setTeacher({ ...teacher, role: e.target.value })
                }
              >
                <option value="teacher">Teacher</option>
                <option value="assistant">Assistant</option>
                <option value="admin">Admin</option>
                <option value="learning-architect">Learning Architect</option>
              </Form.Select>
            </Form.Group>

            <div className="mt-3">
              <Button
                type="submit"
                variant="primary"
                disabled={loading}
                id="add-new-event-btn"
                onClick={(e) => handleAddTeacher(e)}
              >
                {teacherId ? "Update Teacher" : "Add New Teacher"}
              </Button>

              <Button
                className="ms-2"
                variant="outline-secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
};

export default AddNewTeacher;
