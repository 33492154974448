import { Button, Form, Image, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createClientAction, editClientAction, fetchClientAction } from 'actions/client';




const AddNewClient = ({ showOffcanvas, setShowOffcanvas, clientId, setClientId }) => {
    const {loading }= useSelector(state=>state.auth)
    const [clientData, setClientData] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        schoolId: "",
        birthday: "",
        fatherFullName: "",
        motherFullName: "",
        fatherPhone: "",
        motherPhone: "",
    });
    // const[badgeClient,setBadgeClient] = useState(null)
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    useEffect(() => {
        
        if (userData) {
          setClientData(clientData => ({
            ...clientData,
            schoolId: userData.schoolId
            
          }));
        }
      }, [userData.schoolId]);

   
   
    const { client: clientEdited } = useSelector(state => state.clients)
    const dispatch = useDispatch();

    const handleClose = () => {
        setClientData({
            name: "",
            email: "",
            phone: "",
            address: "",
            schoolId: "",
            birthday: "",
            fatherFullName: "",
            motherFullName: "",
            fatherPhone: "",
            motherPhone: "",
        })
        setShowOffcanvas(false)
        setClientId(null)
        // setBadgeClient(null)
    }
    const handleShow = () => setShowOffcanvas(true);
    const handleAddClient = async (e) => {
        if (clientId) {
            dispatch(editClientAction(clientData, handleClose, setClientId, clientId))
        } else {
            dispatch(createClientAction( clientData, handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (clientId) {
            dispatch(fetchClientAction(clientId))
        }
    }, [dispatch, clientId])
    useEffect(() => {
        if (clientEdited?._id) {
            setClientData({

                name: clientEdited.name,
                email: clientEdited.email,
                phone: clientEdited.phone,
                address: clientEdited.address,
                schoolId: clientEdited.schoolId,
                birthday: clientEdited.birthday,
                fatherFullName: clientEdited.fatherFullName,
                motherFullName: clientEdited.motherFullName,
                fatherPhone: clientEdited.fatherPhone,
                motherPhone: clientEdited.motherPhone,
            })
            // setBadgeClient(
            //    clientEdited.badge
            // )
        }
    }, [clientEdited])




    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow}>
                Add New Client
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{clientId ? 'Update Client' : 'Add New Client'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* Image  */}
                        {/* <Form.Group className="mb-3">
                            <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="rounded-circle me-3"
                                    style={{
                                        width: '100px', 
                                        height: '100px', 
                                    }}
                                />
                                <Button
                                style={{
                                    marginTop:"21px",
                                    width:"100%"
                                }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {ClientId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group> */}
                        {/* Main Phone Number */}
                        {clientId ? (
                            <Form.Group className="mb-3">
                                <Form.Label>Main Phone Number</Form.Label>
                                <div>
                                    <Form.Check
                                        type="switch"
                                        label={`Client Phone : ${clientData.phone}`}
                                        name="phoneType"
                                        id="phone"
                                        checked={clientData.phone === clientData.defaultPhone}
                                        onChange={() => setClientData({ ...clientData, defaultPhone: clientData.phone })}
                                    />
                                    <Form.Check
                                        type="switch"
                                        label={`Father Phone : ${clientData.fatherPhone}`}
                                        name="phoneType"
                                        id="fatherPhone"
                                        checked={clientData.defaultPhone === clientData.fatherPhone}
                                        onChange={() => setClientData({ ...clientData, defaultPhone: clientData.fatherPhone })}
                                    />
                                    <Form.Check
                                        type="switch"
                                        label={`Mother Phone : ${clientData.motherPhone}`}
                                        name="phoneType"
                                        id="motherPhone"
                                        checked={clientData.defaultPhone === clientData.motherPhone}
                                        onChange={() => setClientData({ ...clientData, defaultPhone: clientData.motherPhone })}
                                    />
                                </div>
                            </Form.Group>
                        ) : null}
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Client Name"
                                id="name"
                                required
                                value={clientData.name}
                                onChange={(e) =>
                                    setClientData({ ...clientData, name: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Client Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Client Email"
                                id="email"
                                value={clientData.email}
                                required
                                onChange={(e) =>
                                    setClientData({ ...clientData, email: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* Phone Number  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Phone Number </Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Phone Number "
                                id="phone"
                                value={clientData.phone}
                                required
                                onChange={(e) =>
                                    setClientData({ ...clientData, phone: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Address"
                                id="address"
                                required
                                value={clientData.address}
                                onChange={(e) =>
                                    setClientData({ ...clientData, address: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* School ID  */}
                        {/* <Form.Group className="mb-3">
                            <Form.Label>School ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="School ID Here"
                                id="schoolId"
                                value={clientData.schoolId}
                                required
                                onChange={(e) =>
                                    setClientData({ ...clientData, schoolId: e.target.value })
                                }
                            />
                        </Form.Group> */}
                        {/* Birthday  */}
                        <Form.Group className="mb-3" >
                            <Form.Label>Birthday</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Birthday"
                                id="birthday"
                                value={clientData.birthday}
                                selected={clientData.birthday}
                                onChange={(e) =>
                                    setClientData({ ...clientData, birthday: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* Father Full Name  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Father Full Name </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Father Full Name "
                                id="fatherFullName"
                                required
                                value={clientData.fatherFullName}
                                onChange={(e) =>
                                    setClientData({ ...clientData, fatherFullName: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* Father Phone Number  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Father Phone Number </Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Father Phone Number "
                                id="fatherPhone"
                                value={clientData.fatherPhone}
                                required
                                onChange={(e) =>
                                    setClientData({ ...clientData, fatherPhone: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* Mother Full Name  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Mother Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Mother Full Name"
                                id="motherFullName"
                                required
                                value={clientData.motherFullName}
                                onChange={(e) =>
                                    setClientData({ ...clientData, motherFullName: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* Mother Phone Number  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Mother Phone Number </Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Mother Phone Number "
                                id="motherPhone"
                                value={clientData.motherPhone}
                                required
                                onChange={(e) =>
                                    setClientData({ ...clientData, motherPhone: e.target.value })
                                }
                            />
                        </Form.Group>
                        {/* <Form.Group className="mb-3">
                            <Form.Label>Badge ID  </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="badge ID "
                                id="bade"
                                value={badgeClient?.badgeId}
                                required
                                onChange={(e) =>
                                    setBadgeClient(e.target.value)
                                }
                            />
                        </Form.Group> */}
                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                disabled={loading}
                                onClick={(e) => handleAddClient(e)}>
                                {clientId ? 'Update Client' : 'Add New Client'}
                            </Button>

                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewClient;