// import node module libraries
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";

// import custom components
import ApexCharts from "components/elements/charts/ApexCharts";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SpinnerLoading from "./SpinnerLoading";
//translate component

import { fetchResult } from "actions/quiz";

const QuizAppResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const token = searchParams.get("token");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const user_id = user?.quiz_id;
  useEffect(() => {
    if (user_id) dispatch(fetchResult(token, user_id, navigate));
  }, [dispatch, user_id]);

  const { result } = useSelector((state) => state.result);

  const userPassedQuiz = result?.success;
  let score = result?.percent;
  const QuizResultChartSeries = [score];
  const QuizResultChartOptions = {
    colors: userPassedQuiz ? ["#38a169"] : ["#F1170D"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: -2,
          size: "50%",
          background: userPassedQuiz ? "#d1f5ea" : "#FF9B88",
        },
        dataLabels: {
          name: { show: true, fontSize: "18px", fontWeight: 600, offsetY: 7 },
          value: { show: false },
        },
      },
    },
    labels: [score + "%"],
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
          }}
        >
          <SpinnerLoading />
        </div>
      ) : (
        <Card>
          <Card.Body className="p-10 text-center">
            {userPassedQuiz ? (
              <div className="mb-4">
                <h2>{t("congratulations_you")}</h2>
                <p className="mb-0 px-lg-14">{t("you_have_succes")}</p>
              </div>
            ) : (
              <div className="mb-4">
                <h2>{"sorry_you_failed"}</h2>
                <p className="mb-0 px-lg-14">{t("you_did_pass")}</p>
              </div>
            )}
            <div className="d-flex justify-content-center">
              <div className="resultChart">
                <ApexCharts
                  options={QuizResultChartOptions}
                  series={QuizResultChartSeries}
                  type="radialBar"
                  height={150}
                />
              </div>
            </div>
            <div className="mt-3">
              <span>
                {"your_score"}{" "}
                <span className="text-dark">
                  {" "}
                  {"is"} ({result?.score} {"points"}) {"out_of"} (
                  {result?.max_score} {t("points")})
                </span>
              </span>
              <br />
            </div>
            <div className="mt-5">
              <Link
                to={`/instructor/quiz/${session_id}`}
                className="btn btn-primary"
              >
                {"finish"}
              </Link>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default QuizAppResult;
