import { GET_CLIENT_FROM_DATA }
     from "actions/constants";

const clientsFromDataReducer = (state = { clientsFromData: [], clientFromData:{} }, action) => {
   switch (action.type) {
       case GET_CLIENT_FROM_DATA:
           return { ...state, clientsFromData: action?.payload};
     default:
       return state;
   }
 };
 
 export default clientsFromDataReducer;
 
