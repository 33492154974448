// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import {
  Dropdown,
  Image,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Table,
  Button,
  Modal,
} from "react-bootstrap";
import { MoreVertical, Trash, Edit, Mail } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";

// import utility file

// import data files
import { approveUserAction } from "actions/approveUser";
import {
  deleteAssistantAction,
  fetchAllAssistantsAction,
} from "actions/assistant";

const AssistantsList = ({ setUserId }) => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const dispatch = useDispatch();
  const { assistants } = useSelector((state) => state.assistants);
  useEffect(() => {
    dispatch(fetchAllAssistantsAction());
  }, [dispatch]);

  const handleDeleteUser = (id) => {
    setUserToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteUser = () => {
    if (userToDelete && confirmationMessage === "DELETE ASSISTANT") {
      dispatch(deleteAssistantAction(userToDelete));
      setUserToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const handleApproveUser = (userId, e) => {
    dispatch(approveUserAction(userId));
    e.preventDefault();
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ id, setUserId }) => {
    return (
      <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={() => setUserId(id)}>
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          {(userData?.role == "admin" ||
            userData?.role === "learning-architect") && (
            <Dropdown.Item eventKey="2" onClick={() => handleDeleteUser(id)}>
              {" "}
              <Trash
                size="15px"
                className="dropdown-item-icon"
                variant="danger"
                onClick={confirmDeleteUser}
              />{" "}
              Remove
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "_id", Header: "ID", show: false },
      {
        accessor: "name",
        Header: "Name",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              {/* <Image
								src={row.original.img}
								alt=""
								className="rounded-circle avatar-md me-2"
							/> */}
              <h5 className="mb-0">{value}</h5>
            </div>
          );
        },
      },

      {
        accessor: "email",
        Header: "Email",
        Cell: ({ value }) => {
          return value;
        },
      },
      { accessor: "role", Header: "Role" },
      // {
      // 	accessor: 'created_at', Header: 'Joined At',
      // 	Cell: ({ value }) => {
      // 		return value;
      // 	}
      // },

      // {
      // 	accessor: 'approved',
      // 	Header: 'Approved',

      // 	Cell: ({ value, row }) => {

      // 		return (
      // 			<Fragment>
      // 				<DotBadge
      // 					bg={
      // 						value === false
      // 							? 'warning'
      // 							: value === true
      // 								? 'success'
      // 								: ''
      // 					}
      // 				></DotBadge>
      // 				{value == true ? 'Approved' :
      // 					<Fragment>
      // 						 <Button
      // 							href="#"
      // 							variant="outline"
      // 							className="btn btn-outline-secondary btn-sm"
      // 						>
      // 							Reject
      // 						</Button>{' '}
      // 						<Button
      // 							variant="success"
      // 							className="btn-sm"
      // 							onClick={(e) => handleApproveUser(row.original.id, e)}
      // 						>
      // 							Approved
      // 						</Button>
      // 					</Fragment>}
      // 			</Fragment>
      // 		);
      // 	}
      // },
      //students Grid hadhi
      /* {	
</div>
<div className="d-flex justify-content-between border-bottom py-2 mt-6">
<span>Payments</span>
<span className="text-dark">
    ${numberWithCommas(students.payment)}
</span>
</div> }, */
      /* {
                accessor: 'confirmed',
                Header: 'Action',
                Cell: ({ value }) => {
                    if (value === 'true') {
                        return (
                            <Fragment>
                                <Button
                                    href="#"
                                    variant="outline"
                                    className="btn btn-outline-secondary btn-sm"
                                >
                                    Reject
                                </Button>{' '}
                                <Button href="#" variant="success" className="btn-sm">
                                    Approved
                                </Button>
                            </Fragment>
                        );
                    }
                    if (value === 'false') {
                        return (
                            <Button href="#" variant="secondary" className="btn-sm">
                                Change Status
                            </Button>
                        );
                    }
                }
            }, */
      {
        accessor: "message",
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <div className="hstack gap-4">
              {/* <OverlayTrigger
								key="top"
								placement="top"
								overlay={<Tooltip id={`tooltip-top`}>Message</Tooltip>}
							>
								<Link to="#">
									<Mail size="15px" className="dropdown-item-icon" />
								</Link>
							</OverlayTrigger>
 */}
              {/* <OverlayTrigger
								key="top"
								placement="top"
								overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}
							>
								<Link to="#">
									<Trash size="15px" className="dropdown-item-icon" />
								</Link>
							</OverlayTrigger> */}

              <ActionMenu id={row.original._id} setUserId={setUserId} />
            </div>
          );
        },
      },
    ],
    []
  );

  // const data = useMemo(() => users, [users]);
  const data = useMemo(() => assistants, [assistants]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Assistant"
            />
          </Col>
        </Row>
      </div>

      <Table
        hover
        responsive
        {...getTableProps()}
        className="text-nowrap table-centered"
      >
        <thead className="table-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this assistant?</p>
          <h4 className="text-secondary">
            you must write "DELETE ASSISTANT" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default AssistantsList;
