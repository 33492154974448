import { group } from "d3";
import {
  GET_ALL_BOARDS,
  GET_BOARD,
  EDIT_BOARD,
  DELETE_BOARD,
  CREATE_BOARD,
  GET_BOARD_FOR_TEACHER,
  GET_LIST_BY_BOARD,
  GET_ALL_LISTS,
  GET_LIST,
  EDIT_LIST,
  DELETE_LIST,
  CREATE_LIST,
  GET_ALL_CARDS,
  GET_CARD_BY_LIST,
  GET_CARD,
  EDIT_CARD,
  DELETE_CARD,
  CREATE_CARD,
  GET_MEMBERS,
  GET_NOTE_CARD,
  GET_ALL_TASK_LOGS,
  GET_ALL_ARCHIVED_CARDS,
  GET_ALL_BOARD_LOGS,
  GET_CARD_IN_BOARD,
  GET_DESC_BY_CARD,
  DELETE_DESCRIPTION,
  CREATE_DESC,
  EDIT_DESC,
  GET_DESC,
  MOVE_TASK,
  FETCH_LEARNING_PATH,
  ARCHIVE_TASK,
  SET_TASK,
  REMOVE_CARD,
  CREATE_KANBAN_GROUP,
  EDIT_KANBAN_GROUP,
  HIDE_GROUPS,
  FETCH_TEMPLATES,
  GENERATE_TEMPLATE,
  GET_RELATED_CARDS,
  CREATE_MEETING,
} from "../actions/constants";

const boardKanbanReducer = (
  state = {
    boards: [],
    board: {},
    members: [],
    lists: [],
    list: {},
    cards: [],
    card: {},
    tasksLogs: [],
    noteCards: [],
    archivedTasks: [],
    boardLogs: [],
    cardInBoard: [],
    cardDesc: [],
    desc: {},
    initialNodes: [],
    initialEdges: [],
    templates: [],
    relatedCards: [],
  },
  action
) => {
  switch (action.type) {
    //board
    case GET_ALL_BOARDS:
      return {
        ...state,
        boards: action?.payload,
      };
    case GET_ALL_BOARD_LOGS:
      return {
        ...state,
        boardLogs: action?.payload,
      };
    case GET_BOARD_FOR_TEACHER:
      return {
        ...state,
        boards: action?.payload,
      };
    case GET_BOARD:
      return { ...state, board: action?.payload };
    case GET_MEMBERS:
      return { ...state, members: action?.payload };
    case EDIT_BOARD:
      return {
        ...state,
        boards: state.boards.map((e) => {
          if (e._id == action.payload._id) {
            return action.payload;
          } else {
            return e;
          }
        }),
      };
    case DELETE_BOARD:
      return {
        ...state,
        boards: state.boards.filter((e) => e._id !== action.payload),
      };
    case CREATE_BOARD:
      return { ...state, boards: [...state.boards, action.payload] };

    // list of board
    case GET_ALL_LISTS:
      return {
        ...state,
        lists: action?.payload,
      };
    case GET_LIST_BY_BOARD:
      return {
        ...state,
        lists: action?.payload,
      };
    case GET_LIST:
      return { ...state, list: action?.payload };
    case EDIT_LIST:
      return {
        ...state,
        lists: state.lists.map((e) => {
          if (e._id == action.payload._id) {
            return action.payload;
          } else {
            return e;
          }
        }),
      };
    case DELETE_LIST:
      return {
        ...state,
        lists: state.lists.filter((e) => e._id !== action.payload),
      };
    case CREATE_LIST:
      return { ...state, lists: [...state.lists, action.payload] };

    // CArd of board
    case GET_ALL_CARDS:
      return {
        ...state,
        cards: action?.payload,
      };
    case GET_CARD_IN_BOARD:
      return {
        ...state,
        cardInBoard: action?.payload,
      };
    case GET_ALL_ARCHIVED_CARDS:
      return {
        ...state,
        archivedTasks: action?.payload,
      };
    case GET_ALL_TASK_LOGS:
      return {
        ...state,
        tasksLogs: action?.payload,
      };
    case GET_NOTE_CARD:
      return {
        ...state,
        noteCards: action?.payload,
      };
    case GET_CARD_BY_LIST:
      return {
        ...state,
        cards: action?.payload,
      };
    case GET_CARD:
      return { ...state, card: action?.payload };
    case EDIT_CARD:
      return {
        ...state,
        lists: state.lists.map((l) => {
          if (l._id == action.payload.listId) {
            return {
              ...l,
              cards: l.cards.map((c) => {
                if (c._id == action.payload._id) {
                  return action.payload;
                } else {
                  return c;
                }
              }),
            };
          } else {
            return l;
          }
        }),
      };
    case DELETE_CARD:
      return {
        ...state,
        cards: state.cards.filter((e) => e._id !== action.payload),
      };
    case ARCHIVE_TASK:
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list._id == action.payload.listId) {
            return {
              ...list,
              cards: list.cards.filter(
                (card) => card._id !== action.payload._id
              ),
            };
          } else {
            return list;
          }
        }),
      };

    case SET_TASK:
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list._id === action.payload.listId) {
            let nextCardIndex = list.cards.findIndex(
              (card) =>
                card.prevCardId === action.payload.prevCardId &&
                card.listId === action.payload.listId
            );

            let newCards;

            // If nextCard is found, insert the new card before nextCard
            if (nextCardIndex !== -1) {
              // Update the prevCardId of nextCard to point to the new card
              newCards = [
                ...list.cards.slice(0, nextCardIndex), // Cards before nextCard
                action.payload, // Insert the new card
                {
                  ...list.cards[nextCardIndex], // The next card with updated prevCardId
                  prevCardId: action.payload._id,
                },
                ...list.cards.slice(nextCardIndex + 1), // Cards after nextCard
              ];
            } else {
              // If nextCard is not found, push the card to the end
              newCards = [...list.cards, action.payload];
            }

            // Return the updated list with the new cards array
            return {
              ...list,
              cards: newCards,
            };
          }

          // Return the list unchanged if listId doesn't match
          return list;
        }),
      };

    case REMOVE_CARD:
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list._id === action.payload.listId) {
            const cardToRemove = list.cards.find(
              (card) => card._id === action.payload._id
            );

            if (!cardToRemove) return list;

            // Find the next card (the one that follows the removed card)
            const nextCard = list.cards.find(
              (card) => card.prevCardId === cardToRemove._id
            );

            return {
              ...list,
              cards: list.cards
                .filter((card) => card._id !== action.payload._id)
                .map((card) => {
                  // Update the prevCardId of the next card
                  if (nextCard && card._id === nextCard._id) {
                    return { ...card, prevCardId: cardToRemove.prevCardId };
                  }
                  return card;
                }),
            };
          }

          return list; // Return other lists unchanged
        }),
      };

    case CREATE_CARD:
      return {
        ...state,
        lists: state.lists.map((l) => {
          if (l._id === action.payload.listId) {
            // Add the entire card (action.payload) to the cards array
            const newCards = [...l.cards, action.payload];

            return { ...l, cards: newCards };
          } else {
            return l;
          }
        }),
      };

    case CREATE_KANBAN_GROUP:
      return {
        ...state,
        lists: state.lists.map((l) => {
          if (l._id === action.payload.listId) {
            const newGroups = [...l.groups, action.payload];

            return { ...l, groups: newGroups };
          } else {
            return l;
          }
        }),
      };
    case EDIT_KANBAN_GROUP:
      return {
        ...state,
        lists: state.lists.map((l) => {
          if (l._id === action.payload.listId) {
            return {
              ...l,
              groups: l.groups.map((group) => {
                if (group._id == action.payload._id) {
                  return action.payload;
                } else {
                  return group;
                }
              }),
            };
          } else {
            return l;
          }
        }),
      };
    //TAsk DESC
    case GET_DESC_BY_CARD:
      return { ...state, cardDesc: action?.payload };
    case GET_DESC:
      return { ...state, desc: action?.payload };
    case DELETE_DESCRIPTION:
      return {
        ...state,
        cardDesc: state.cardDesc.filter((e) => e._id !== action.payload),
      };
    case CREATE_DESC:
      return { ...state, cardDesc: [...state.cardDesc, action.payload] };
    case EDIT_DESC:
      return {
        ...state,
        cardDesc: state.cardDesc.map((e) => {
          if (e._id == action.payload._id) {
            return action.payload;
          } else {
            return e;
          }
        }),
      };
    case MOVE_TASK:
      const {
        source,
        newListId,
        taskId,
        newIndex,
        startGroup,
        destinationGroup,
      } = action.payload;

      // Find the source and destination lists
      const sourceList = state.lists.find((list) => list._id === source);
      const destList = state.lists.find((list) => list._id === newListId);

      if (!sourceList || !destList) {
        return state; // Return unchanged state if lists are not found
      }

      // Find the task to move in the source list
      var movedTask;

      if (startGroup && destinationGroup) {
        movedTask = sourceList.groups
          .find((group) => group._id == startGroup)
          .cards.find((card) => card._id === taskId);

        // Move from one group to another in different lists
        let updatedSourceGroups = sourceList.groups.map((group) => {
          if (group._id == startGroup) {
            return {
              ...group,
              cards: group.cards.filter((card) => card._id !== taskId),
            };
          } else {
            return group;
          }
        });

        let updatedDestGroups = destList.groups.map((group) => {
          if (group._id == destinationGroup) {
            let newCards = [...group.cards];
            // Insert the movedTask at the newIndex
            newCards.splice(newIndex, 0, movedTask);
            return {
              ...group,
              cards: newCards,
            };
          } else {
            return group;
          }
        }); // Clone the destination list cards

        // updatedDestCards.splice(newIndex, 0, movedTask); // Insert the task into the destination list at the new index

        return {
          ...state,
          lists:
            sourceList != destList
              ? state.lists.map((list) =>
                  list._id === source
                    ? { ...list, groups: updatedSourceGroups }
                    : list._id === newListId
                    ? { ...list, groups: updatedDestGroups }
                    : list
                )
              : state.lists.map((list) => {
                  if (list._id == sourceList._id) {
                    return {
                      ...list,
                      groups: updatedSourceGroups.map((group) => {
                        if (group._id == destinationGroup) {
                          let newCards = [...group.cards];
                          // Insert the movedTask at the newIndex
                          newCards.splice(newIndex, 0, movedTask);
                          return {
                            ...group,
                            cards: newCards,
                          };
                        } else {
                          return group;
                        }
                      }),
                    };
                  } else {
                    return list;
                  }
                }),
        };
      } else if (startGroup) {
        movedTask = sourceList.groups
          .find((group) => group._id == startGroup)
          .cards.find((card) => card._id === taskId);
        let updatedSourceGroups = sourceList.groups.map((group) => {
          if (group._id == startGroup) {
            return {
              ...group,
              cards: group.cards.filter((card) => card._id !== taskId),
            };
          } else {
            return group;
          }
        });

        let updatedDestCards = [...destList.cards];
        updatedDestCards.splice(newIndex, 0, movedTask);

        return {
          ...state,
          lists:
            sourceList != destList
              ? state.lists.map((list) => {
                  if (list._id == sourceList._id) {
                    return { ...list, groups: updatedSourceGroups };
                  } else if (list._id == destList._id) {
                    return { ...list, cards: updatedDestCards };
                  } else {
                    return list;
                  }
                })
              : state.lists.map((list) => {
                  if (list._id == sourceList._id) {
                    return {
                      ...list,
                      groups: updatedSourceGroups,
                      cards: updatedDestCards,
                    };
                  } else {
                    return list;
                  }
                }),
        };
      } else if (destinationGroup) {
        movedTask = sourceList.cards.find((card) => card._id === taskId);
        const updatedSourceCards = sourceList.cards.filter(
          (card) => card._id !== taskId
        ); // Remove the task from the source list;

        const updatedDestCards = destList.groups.map((group) => {
          if (group._id == destinationGroup) {
            // Create a copy of the cards array
            let newCards = [...group.cards];
            // Insert the movedTask at the newIndex
            newCards.splice(newIndex, 0, movedTask);
            return {
              ...group,
              cards: newCards, // Use the updated cards array
            };
          } else {
            return group;
          }
        }); // Clone the destination list cards

        // updatedDestCards.splice(newIndex, 0, movedTask); // Insert the task into the destination list at the new index

        return {
          ...state,
          lists:
            sourceList != destList
              ? state.lists.map((list) =>
                  list._id == sourceList._id
                    ? { ...list, cards: updatedSourceCards } // Update source list cards
                    : list._id == destList._id
                    ? { ...list, groups: updatedDestCards } // Update destination list cards
                    : list
                )
              : state.lists.map((list) => {
                  if (list._id == sourceList._id) {
                    return {
                      ...list,
                      cards: updatedSourceCards,
                      groups: updatedDestCards,
                    };
                  } else {
                    return list;
                  }
                }),
        };
      } else {
        movedTask = sourceList.cards.find((card) => card._id === taskId);

        if (source === newListId) {
          // Moving the task within the same list (reordering)
          const updatedSourceCards = [...sourceList.cards]; // Clone the cards array to avoid mutation
          const sourceIndex = updatedSourceCards.findIndex(
            (card) => card._id === taskId
          );

          // Remove the task from its current position and insert at the new index
          updatedSourceCards.splice(sourceIndex, 1);
          updatedSourceCards.splice(newIndex, 0, movedTask); // Insert task at new position

          return {
            ...state,
            lists: state.lists.map((list) =>
              list._id === source
                ? { ...list, cards: updatedSourceCards }
                : list
            ),
          };
        } else {
          // Moving the task to a different list
          const updatedSourceCards = sourceList.cards.filter(
            (card) => card._id !== taskId
          ); // Remove the task from the source list

          const updatedDestCards = [...destList.cards]; // Clone the destination list cards
          updatedDestCards.splice(newIndex, 0, movedTask); // Insert the task into the destination list at the new index

          return {
            ...state,
            lists: state.lists.map((list) =>
              list._id === source
                ? { ...list, cards: updatedSourceCards } // Update source list cards
                : list._id === newListId
                ? { ...list, cards: updatedDestCards } // Update destination list cards
                : list
            ),
          };
        }
      }
    case FETCH_LEARNING_PATH:
      return {
        ...state,
        initialNodes: action.payload.initialNodes,
        initialEdges: action.payload.initialEdges,
      };
    case FETCH_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case GENERATE_TEMPLATE:
      return {
        ...state,
        templates: [...state.templates, action.payload],
      };
    case GET_RELATED_CARDS:
      return {
        ...state,
        relatedCards: action.payload,
      };

    case HIDE_GROUPS:
      return {
        ...state,
        lists: state.lists.map((list) => {
          if (list._id === action.payload) {
            const allCardsFromGroups = list.groups.reduce((acc, group) => {
              return [...acc, ...group.cards];
            }, []);

            return {
              ...list,
              cards: [...list.cards, ...allCardsFromGroups],
              groups: [],
              hide: true,
            };
          } else {
            return list;
          }
        }),
      };
    case CREATE_MEETING:
      return {
        ...state,
        lists: state.lists.map((l) => {
          if (l._id == action.payload.listId) {
            return {
              ...l,
              cards: l.cards.map((c) => {
                if (c._id == action.payload.cardId) {
                  return { ...c, meeting: action.payload.meeting };
                } else {
                  return c;
                }
              }),
            };
          } else {
            return l;
          }
        }),
      };
    default:
      return state;
  }
};

export default boardKanbanReducer;
