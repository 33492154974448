import {
     ADD_CLIENT_IN_CLASS,
     DELETE_CLIENT_FROM_CLASS,
     GET_CLIENT_IN_CLASS
     }
      from "actions/constants";

const clientsInClassReducer = (state = { clientsInClasses: [], clientInClass:{} }, action) => {
    switch (action.type) {
        case GET_CLIENT_IN_CLASS:
            return { ...state, clientsInClasses: action?.payload};
        case ADD_CLIENT_IN_CLASS:
            return { ...state, clientsInClasses: [...state.clientsInClasses, action.payload] };
            case DELETE_CLIENT_FROM_CLASS:
                return { ...state, clientsInClasses: state.clientsInClasses.filter(e => e._id !== action.payload) };
      default:
        return state;
    }
  };
  
  export default clientsInClassReducer;
  
