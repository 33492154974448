import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css"; // Import Typeahead CSS
import { useSelector } from "react-redux";

const AutoCompleteCurriculum = ({ setBoardData, boardData }) => {
  const { curriculum } = useSelector((state) => state.curriculum);

  // Filter out already selected curricula
  const availableCurriculums = curriculum?.filter(
    (cur) =>
      !boardData?.curriculum?.some((selected) => selected._id === cur._id)
  );

  const handleSelection = (selected) => {
    if (selected.length > 0) {
      setBoardData({
        ...boardData,
        curriculum: [...boardData.curriculum, selected[0]],
      });
    }
  };

  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="title"
        options={availableCurriculums}
        placeholder="Add New Curriculum..."
        onChange={handleSelection}
      />
    </div>
  );
};

export default AutoCompleteCurriculum;
