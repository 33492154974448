// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Form, Offcanvas, Button } from 'react-bootstrap';

// import custom components
import { FormSelect } from 'components/elements/form-select/FormSelect';

// import sub components

// import data files
import { fetchBoardByTeacherAction } from 'actions/boardKanban';
import { useSelector,useDispatch } from 'react-redux';
import GridCardTeacher from './GridCardTeacher';

const BoardGridTeacher = ({ setBoardId }) => {
    const userDataString = localStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    const dispatch = useDispatch();
    const { boards } = useSelector(state => state.boards);
    useEffect(() => {
        dispatch(fetchBoardByTeacherAction(userData._id))
    }, [dispatch]);

  






	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const filterOptions = [
		{ value: 'In Progress', label: 'In Progress' },
		{ value: 'Pending', label: 'Pending' },
		{ value: 'Modified', label: 'Modified' },
		{ value: 'Finished', label: 'Finished' },
		{ value: 'Cancel', label: 'Cancel' }
	];

	const [ProjectsList, setProjectsList] = useState(
		boards.slice(0, 500)
	);
// console.log(boards)
	// paging start
	const [pageNumber, setPageNumber] = useState(0);
	const projectsPerPage = 400;
	const pagesVisited = pageNumber * projectsPerPage;

	const displayProjects = boards?.map((item, index) => {
		return (
			<Col xxl={3} xl={4} lg={6} xs={12} className="mb-4" key={index}>
				<GridCardTeacher item={item} setBoardId={setBoardId} />
			</Col>
		);
	});
	// end of paging

	// searching code started
	const [searchTerm, setSearchTerm] = useState('');

	

	return (
		<Fragment>
			

			{/* displaying records */}
			<Row style={{marginTop:"2%"}}>
				{displayProjects.length > 0 ? (
					displayProjects
				) : (
					<Col>No matching projects found.</Col>
				)}
			</Row>
		</Fragment>
	);
};

export default BoardGridTeacher;
