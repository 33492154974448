import {
    GET_ALL_CLASSES,
    GET_CLASS,
    EDIT_CLASS,
    DELETE_CLASS,
    CREATE_CLASS,
  } from "../actions/constants";
  
  const classesReducer = (state = { classes: [], class: {},totalClassesPages:{} }, action) => {
    switch (action.type) {
      case GET_ALL_CLASSES:
        return {
          ...state,
          classes: action?.payload.data,
          totalClassesPages: action?.payload.totalPages
        };
      case GET_CLASS:
        return { ...state, class: action?.payload };
      case EDIT_CLASS:
        return { ...state, classes: state.classes.map(e=>{
          if(e._id==action.payload._id){
            return action.payload
          }
          else{
            return e
          }
        }) };
      case DELETE_CLASS:
        return { ...state, classes: state.classes.filter(e => e._id !== action.payload) };
      case CREATE_CLASS:
        return { ...state, classes: [...state.classes, action.payload] };
      default:
        return state;
    }
  };
  
  export default classesReducer;
  