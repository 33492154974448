import { getAllCurriculumAction } from "actions/curriculum";
import { createQuizAccountAction } from "actions/quiz";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const StartTest = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { curriculum } = useSelector((state) => state.curriculum);

  const [selected, setSelected] = useState(null);
  useEffect(() => {
    dispatch(getAllCurriculumAction());
  }, []);
  const handleCreateQuizAccount = () => {
    dispatch(createQuizAccountAction(userData._id));
  };
  return (
    <div style={{ height: "30vh" }}>
      <div style={{ marginTop: "5%" }}>
        <label>Slect course</label>
        <Form.Select
          style={{
            // width: "20%",
            marginTop: "2%",
            // border: "none",
            // outline: "none",
          }}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          {curriculum.map((e) => (
            <option key={e._id} value={e.quizId}>
              {e.title}
            </option>
          ))}
        </Form.Select>
      </div>

      {selected && (
        <div style={{ marginTop: "10%" }}>
          {userData?.quiz_id ? (
            <Button
              onClick={() =>
                navigate(
                  "/quiz/quiz_start?quiz_id=" + selected + "&placmentTest=77"
                )
              }
            >
              Start Placement Test
            </Button>
          ) : (
            <Button onClick={() => handleCreateQuizAccount()}>
              Move to Placement Test!
            </Button>
          )}

          {/* <Button>Close</Button> */}
        </div>
      )}
    </div>
  );
};

export default StartTest;
