
import { clientDetails } from "api";
import {

    GET_CLIENT_DETAILS,
    GET_CLIENT_NAME,
    LOADING,
} from "./constants";

export const fetchClientDetailsAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
        const { data } = await clientDetails(id);
        dispatch({ type: GET_CLIENT_DETAILS, payload: data.Presences });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};