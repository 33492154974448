import {
  GET_ALL_ADMINS,
  GET_ADMIN,
  EDIT_ADMIN,
  DELETE_ADMIN,
  CREATE_ADMIN,
} from "../actions/constants";

const adminsReducer = (state = { admins: [], admin: {} }, action) => {
  switch (action.type) {
    case GET_ALL_ADMINS:
      return { ...state, admins: action?.payload };
    case GET_ADMIN:
      return { ...state, admin: action?.payload };
    case EDIT_ADMIN:
      return {
        ...state,
        admins: state.admins.map((e) => {
          if (e._id == action.payload._id) {
            return action.payload;
          } else {
            return e;
          }
        }),
      };
    case DELETE_ADMIN:
      return {
        ...state,
        admins: state.admins.filter((e) => e._id !== action.payload),
      };
    case CREATE_ADMIN:
      return { ...state, admins: [...state.admins, action.payload] };
    default:
      return state;
  }
};

export default adminsReducer;
