import {
  GET_ALL_USERS,
  GET_USER,
  EDIT_USER,
  DELETE_USER,
  CREATE_USER,
  APPROVE_USER_SUCCESS,
  SEARCH_READER,
} from "../actions/constants";

const usersReducer = (state = { users: [], user: {}, readers:[] }, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return { ...state, users: action?.payload };
      case SEARCH_READER:
        return { ...state, readers: action?.payload };
    case GET_USER:
      return { ...state, user: action?.payload };
    case EDIT_USER:
      return { ...state, users: [...state.users.filter(e => e.id !== action.payload.id), action.payload] };
    case DELETE_USER:
      return { ...state, users: state.users.filter(e => e.id !== action.payload) };
    case CREATE_USER:
      return { ...state, users: [...state.users, action.payload] };
    case APPROVE_USER_SUCCESS:
      const updatedUsers = state.users.map(user => {
        if (user.id === action.payload.userId) {
          return { ...user, approved: true }; 
        }
        return user;
      });
      return { ...state, users: updatedUsers };

    default:
      return state;
  }
};

export default usersReducer;
