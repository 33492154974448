import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllTeachers, getTeacherById, editTeacher, deleteTeacher, createTeacher, approveTeacher } from "api";
import {
  GET_ALL_TEACHERS,
  GET_TEACHER,
  EDIT_TEACHER,
  DELETE_TEACHER,
  CREATE_TEACHER,
  LOADING,
  EDIT_ADMIN,
} from "./constants";

export const fetchAllTeachersAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const {data} = await getAllTeachers();
    dispatch({ type: GET_ALL_TEACHERS, payload: data.users });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchTeacherAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data }= await getTeacherById(id);
      dispatch({ type: GET_TEACHER, payload: data.user});
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editTeacherAction = (fromData, handleClose, setTeacherId,id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await editTeacher (fromData,id);
    if (data.data.role == "teacher"){
      dispatch({ type: EDIT_TEACHER, payload: data.data });
     }else{
      dispatch({ type: DELETE_TEACHER, payload: id });
     }
    setTeacherId()
    handleClose()
    toast.success(data.message, {
         
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const deleteTeacherAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const data = await deleteTeacher(id);
  
      dispatch({ type: DELETE_TEACHER, payload: id });
      toast.success(data.data.message, {
         
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
         
        }
        dispatch({ type: LOADING, payload: false });
    }
};
export const createTeacherAction = (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
 
      const {data} = await createTeacher(fromData);
      if (data.data.role == "teacher"){
        dispatch({ type: CREATE_TEACHER, payload: data.data });
       }else{ }
      
      handleClose()
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
        
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
  export const approveUserAction = (teacherId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      
      const data = await approveTeacher(teacherId);
      
      dispatch({ type: APPROVE_TEACHER_SUCCESS, payload: teacherId });
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
