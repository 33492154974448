import React from "react";
import { Modal, Button } from "react-bootstrap";
function MetaModal({
  handleCloseModal,
  showModal,
  handleCheckboxChange,
  handleConfirm,
  metaCards,
}) {
  return (
    <Modal show={showModal} onHide={() => handleCloseModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Meta Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Empower with Meta card; add it for this new member ?</p>
        {metaCards.map((card) => (
          <div key={card._id}>
            <label>
              <input
                type="checkbox"
                value={card._id}
                onChange={(e) => handleCheckboxChange(e, card._id)}
              />
              <span style={{ marginLeft: "5px" }}>{card?.title}</span>
            </label>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="info" onClick={() => handleConfirm()}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={() => handleCloseModal()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MetaModal;
