// import node module libraries
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

// import custom components
import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';
import { FormSelect } from 'components/elements/form-select/FormSelect';

// import context file
import { TaskKanbanContext } from 'context/Context';
import AutocompleteTeacher from '../class/AutocompliteTeacher';
import { useSelector } from 'react-redux';
import { createCardAction, editCardAction, fetchCardAction } from 'actions/boardCard';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import AutocompleteStatus from './autocompleteCardTitle';
import DatePicker from 'react-flatpickr';
import { useParams } from 'react-router-dom';
import AutocompleteMembers from './autocompleteMembers';
import { getMembersAction } from 'actions/boardKanban';

const KanbanModal = (props) => {
	const dispatch = useDispatch();
	const { id: idOfBoard } = useParams();
	const [selectedMember, setSelectedMember] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const { showModal, setShowModal, cardId, setCardId, listId } = props;
	const { loading } = useSelector(state => state.auth)
	const { cards } = useSelector(state => state.boards)
	const [cardData, setCardData] = useState({
		title: "",
		description: "",
		listId: "",
		boardId: idOfBoard,
		assignedTo:"",
		dueDate: "",
		position: ""

	});
	useEffect(() => {
		if (selectedMember?.length > 0)
			setCardData({ ...cardData, assignedTo: selectedMember[0]?._id });
	}, [selectedMember]);
	useEffect(() => {
		if (selectedStatus?.length > 0)
			setCardData({ ...cardData, status: selectedStatus[0]?._id });
	}, [selectedStatus]);

	const handleAddTask = async (e) => {
		if (cardId) {
			dispatch(editCardAction({ ...cardData, id: cardId }, handleClose, cardId, setCardId,idOfBoard))

		} else {
			dispatch(createCardAction({ ...cardData, listId: listId, boarId:idOfBoard }, handleClose, idOfBoard));
		}
		e.preventDefault();

	};
	const { card: cardEdited } = useSelector(state => state.boards)
	useEffect(() => {
		if (cardId) {
			dispatch(fetchCardAction(cardId))
		}
	}, [dispatch, cardId])
	useEffect(() => {
		if (cardEdited?._id) {
			setCardData({
				title: cardEdited.title,
				description: cardEdited.description,
				listId: cardEdited.listId,
				boardId: cardEdited.boarId,
				dueDate: cardEdited.dueDate,
				assignedTo : cardEdited.assignedTo,
				position: cardEdited.position

			})
		}
	}, [cardEdited])
	const handleClose = () => {
		setCardData({
			title: "",
			description: "",
			listId: "",
			boardId: idOfBoard,
			assignedTo:"",
			dueDate: "",
			position: ""
		})
		setShowModal(false)

	}
	const priorityOptions = [
		{ value: 'Must', label: 'Must' },
		{ value: 'Should', label: 'Should' },
		{ value: 'Could', label: 'Could' },
		{ value: "Won't", label: "Won't" }
	];
	const {members} = useSelector(state => state.boards);
 

	useEffect(() => {
		dispatch(getMembersAction(idOfBoard))
	  }, [dispatch, idOfBoard]);



	return (
		<Modal
			{...props}
			show={showModal}
			onHide={handleClose}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{cardId ? 'Edit Task' : 'Create New Task'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col xs={12} className="mb-2">
							<Form.Group controlId="taskTitle">
								<Form.Label>Title</Form.Label>
								<Form.Control
									type="text"
									placeholder="title"
									value={cardData?.title}
									onChange={(e) =>
										setCardData({ ...cardData, title: e.target.value })
									}
									autoFocus
									required
								/>
							</Form.Group>
						</Col>
						<Col xs={6}>
							<Form.Group controlId="priority">
								<Form.Label>Priority</Form.Label>
								<Form.Control
									as={FormSelect}
									options={priorityOptions}
									placeholder={cardData?.position ? cardData.position : "Priority"}
									defaultselected={cardData?.position}
									value={cardData?.position}
									onChange={(e) =>
										setCardData({ ...cardData, position: e.target.value })
									}
									required
								/>
							</Form.Group>
						</Col>
						<Col xs={12} className="mb-2">
							<Form.Group className="mb-3" controlId="descriptions">
								<Form.Label>Description</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
									placeholder="Description"
									value={cardData?.description}
									onChange={(e) =>
										setCardData({ ...cardData, description: e.target.value })
									}
								/>
							</Form.Group>
						</Col>
						<Col xs={12} className="mb-2">
							<Form.Group className="mb-3" controlId="dueDate">
								<Form.Label>Due Date</Form.Label>
								<input
									type="date"
									className="form-control"
									value={cardData.dueDate ? cardData.dueDate.split('T')[0] : ''}
									onChange={(e) => setCardData({ ...cardData, dueDate: e.target.value })}
								/>
							</Form.Group>
						</Col>
						<Col xs={12} className="mb-3">
                            <Form.Group controlId="assignTo">
                                <Form.Label>Assign To</Form.Label>
                                <Form.Select
                                    value={cardData?.assignedTo}
                                    onChange={(e) => setCardData({ ...cardData, assignedTo: e.target.value })}
                                >
                                    <option value="">{cardData?.assignedTo?.name ? cardData.assignedTo.name : "Select a member"}</option> {/* default option */}
                                    {members?.map((member) => (
                                        <option key={member._id} value={member._id}>
                                            {member.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
						{/* Buttons */}
						<Col xs={12} className="d-flex justify-content-end">
							<Button
								variant="outline-secondary"
								className="me-2"
								onClick={() => handleClose()}
							>
								Cancel
							</Button>
							<Button variant="primary" type="submit" disabled={loading} onClick={(e) => handleAddTask(e)}>
								{cardId ? 'Edit Task' : 'Create Task'}
							</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<ToastContainer />
		</Modal>
	);
};

export default KanbanModal;
