// import node module libraries
import { Card } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

// import media files
import SurveyImg from "assets/images/svg/survey-img.svg";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { startQuizAction } from "actions/quiz";

const QuizStart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const quiz_id = searchParams.get("quiz_id");
  const placmentTest = searchParams.get("placmentTest");
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { loading } = useSelector((state) => state.auth);
  const user_id = userData.quiz_id;
  const handleStartQuiz = async () => {
    dispatch(startQuizAction(quiz_id, user_id, placmentTest, navigate));
  };

  return (
    <Card className="border-0">
      <Card.Body className="p-10">
        <div className="text-center">
          <img src={SurveyImg} alt="" className="img-fluid" />
          <div className="px-lg-18">
            <h1>Welcome to quiz </h1>
            <Link
              onClick={() => handleStartQuiz()}
              disabled={loading}
              className="btn btn-primary mt-4"
            >
              Start your quiz
            </Link>
          </div>
        </div>
      </Card.Body>
      <ToastContainer />
    </Card>
  );
};

export default QuizStart;
