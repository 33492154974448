import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Row,
  Tab,
  Card,
  Breadcrumb,
  Button,
  Modal,
} from "react-bootstrap";

// import sub custom components

import AddNewBoard from "./AddNewBoard";
import { useSelector } from "react-redux";
import { importBoardAction } from "actions/boardKanban";
import { useDispatch } from "react-redux";
import BoardGridTeacher from "./BoardGridTeacher";
import StartTest from "components/dashboard/placementTest/StartTest";
import { useLocation } from "react-router-dom";

const BoardKanbanByTeacher = () => {
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const PlacmentTest = searchParams.get("PlacmentTest");
  const { loading } = useSelector((state) => state.auth);
  const [boardId, setBoardId] = useState(null);
  const [Test, setTest] = useState(false);
  const [file, setFile] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [showTest, setShowTest] = useState(true);
  const dispatch = useDispatch();
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  useEffect(() => {
    if (userData) {
      setOwnerId(userData._id);
    }
  }, []);
  useEffect(() => {
    if (boardId) setTest(true);
  }, [boardId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a JSON file");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(importBoardAction(formData, ownerId));
    }
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0" style={{ marginBottom: "5%" }}>
              <h1 className="mb-1 h2 fw-bold">List of Boards </h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Kanban Board</Breadcrumb.Item>
                <Breadcrumb.Item active>List of Boards</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
        <Col className="d-flex justify-content-start">
          <div>
            {userData?.role !== "student" && (
              <AddNewBoard
                TestBoard={Test}
                setShowBoard={setShowTest}
                setBoardId={setBoardId}
                boardId={boardId}
              />
            )}
          </div>
        </Col>
        {userData?.role !== "student" && (
          <Col className="d-flex justify-content-end">
            <Button
              variant="primary"
              disabled={loading}
              style={{ margin: "0 15px 15px 0", width: "40%" }}
              onClick={handleUpload}
            >
              Import Board
            </Button>
            <div>
              <input
                className="form-control"
                type="file"
                accept=".json"
                onChange={handleFileChange}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card style={{ backgroundColor: "#f1f5f9" }}>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4">
                    <BoardGridTeacher setBoardId={setBoardId} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
      <Modal
        // fullscreen={true}
        show={showTest}
        onHide={() => setShowTest(false)}
        // className='modal-dialog modal-lg '
        // style={{ marginRight: "1%", overflowY: "scroll" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Placement Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StartTest />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default BoardKanbanByTeacher;
