// import node module libraries
import { Row, Col, Image, Form, Button, Container } from 'react-bootstrap';

// import media files
import Analytics from 'assets/images/background/analytics.jpg';

const HeroFormLeft = () => {
	return (
		<div >
		<section className="p-1 bg-white">
			<Container>
				<Row>
					<Col xl={{ offset: 0, span: 12 }} sm={12}>
						<Row className="px-0 pt-0 pb-0 pe-lg-1 pt-lg-3 align-items-center  rounded-3 ">
							<Col lg={6} className="p-4 p-md-6 pt-lg-0" >
								<h1 className="display-5 fw-bold lh-1 mb-3" style={{color:"#6778c9"}}>
								Curriculum 
								</h1>
								<p className="lead mb-5">
								A curriculum template organizes course plans by levels A,B and C to support progressive learning. Each level includes specific goals, content, and assessments tailored to student abilities, ensuring consistent standards and easy customization for educators. This approach streamlines course design and supports clear, differentiated learning paths.
								</p>
								{/* <Form>
									<Row>
										<Col md={7} sm={12} className="mb-3">
											<Form.Control
												type="email"
												className="form-control"
												placeholder="Work Email"
												required=""
											/>
										</Col>
										<Col md={5} sm={12} className="d-grid mb-3 ps-md-0">
											<Button variant="primary" type="submit">
												Request Access
											</Button>
										</Col>
									</Row>
								</Form> */}
							</Col>
							<Col
								lg={{ offset: 0, span: 6 }}
								className="p-0 overflow-hidden rounded-end-md shadow-lg"
							>
								<Image  
									className="rounded-top-md card-img-size-600"
									src={Analytics}
									alt=""
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			
		</section>	
		</div>
	);
};
export default HeroFormLeft;
