import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllUsers, getUserById, editUser, deleteUser, createUser } from "api";
import {
  GET_ALL_USERS,
  GET_USER,
  EDIT_USER,
  DELETE_USER,
  CREATE_USER,
  LOADING
} from "./constants";

export const fetchAllUsersAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const {data} = await getAllUsers();
    dispatch({ type: GET_ALL_USERS, payload: data.users });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchUserAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getUserById(id);
      dispatch({ type: GET_USER, payload: data.user });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const editUserAction = (fromData, handleClose,id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

   const {data} = await editUser (fromData,id);
    dispatch({ type: EDIT_USER, payload: data });
handleClose()
setUserId()
toast.success(data.message, {
         
  autoClose: 1000,
});
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const deleteUserAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await deleteUser(id);
      dispatch({ type: DELETE_USER, payload: id });
      
        toast.success(data.message, { autoClose: 1000,});
        
       
      
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
          console.log(error.response.data.message);
          dispatch({ type: LOADING, payload: false });
        }
    }
};
export const createUserAction = (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const {data} = await createUser(fromData);
      dispatch({ type: CREATE_USER, payload: data.result });
      toast.success(data.message, {
         
        autoClose: 1000,
      });
      handleClose()
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
  