import { Button, Form, Image, Offcanvas } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css'; // Import the date picker styles
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createAssistantAction, editAssistantAction, fetchAssistantAction } from 'actions/assistant';




const AddNewAssistant = ({ showOffcanvas, setShowOffcanvas, userId, setUserId }) => {
    const {loading }= useSelector(state=>state.auth)
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        schoolId:""

    });

    useEffect(() => {
        const userDataString = localStorage.getItem('userData');
        const userData = JSON.parse(userDataString);
    
        if (userData) {
          setUser(user => ({
            ...user,
            schoolId: userData.schoolId
          }));
        }
      }, []);

   
   

    const { assistant: assistantEdited } = useSelector(state => state.assistants)
    const dispatch = useDispatch();

    const handleClose = () => {
        setUser({
            name: "",
            email: "",
            password: "",
            schoolId:""
        })
        setShowOffcanvas(false)
        setUserId(null)
    }
    const handleShow = () => setShowOffcanvas(true);
    const handleAddReader = async (e) => {
        if (userId) {
            dispatch(editAssistantAction({ ...user}, handleClose, setUserId, userId))
        } else {
            dispatch(createAssistantAction({ ...user}, handleClose));
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (userId) {
            dispatch(fetchAssistantAction(userId))
        }
    }, [dispatch, userId])
    useEffect(() => {
        
        
        if (assistantEdited?._id) {
            setUser({
                name: assistantEdited.name,
                email: assistantEdited.email,
                _id: assistantEdited._id,
                schoolId: assistantEdited.schoolId,
                role: assistantEdited?.role

            })
        }
    }, [assistantEdited])




    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow}>
                Add New Assistant
            </Button>

            <Offcanvas
                show={showOffcanvas}
                onHide={handleClose}
                placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{userId ? 'Update Assistant' : 'Add New Assistant'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        {/* Image  */}
                        {/* <Form.Group className="mb-3">
                            <div style={{  display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                                <Image
                                    src={img}
                                    alt=""
                                    className="rounded-circle me-3"
                                    style={{
                                        width: '100px', 
                                        height: '100px', 
                                    }}
                                />
                                <Button
                                style={{
                                    marginTop:"21px",
                                    width:"100%"
                                }}
                                    variant="primary"
                                    size="sm"
                                    onClick={() => widgetRef.current.open()}
                                    id="img"
                                >
                                    {userId ? 'Update Image' : 'Upload New Image'}
                                </Button>
                            </div>
                        </Form.Group> */}
                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Assistant Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Assistant Name"
                                id="name"
                                required
                                value={user.name}
                                onChange={(e) =>
                                    setUser({ ...user, name: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* author  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Assistant Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Assistant Email"
                                id="email"
                                value={user.email}
                                required
                                onChange={(e) =>
                                    setUser({ ...user, email: e.target.value })
                                }
                            />

                        </Form.Group>
                        {/* Releasing date  */}
                        {/* <Form.Group className="mb-3">
                          
                                <Form.Label>Joined At</Form.Label>
                            <div className="d-flex flex-grow-1 align-items-center">
                                <DatePicker
                                    selected={selectedDate} // Pass the selected date value here
                                    onChange={handleDateChange} 
                                    placeholderText="Click Here" 
                                    className="form-control me-2" 
                                    id="release_date"
                                />
                            </div>
                        </Form.Group> */}
                        {/* PassWord  */}
                        <Form.Group className="mb-3">
                            <Form.Label>Assistant Password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder="**********"
                                id="password"
                                required
                                value={user.password}
                                onChange={(e) =>
                                    setUser({ ...user, password: e.target.value })
                                }
                            />


                        </Form.Group>

                        {/* School ID  */}
                         {/* <Form.Group className="mb-3">
                            <Form.Label>School ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="School ID Here"
                                id="schoolId"
                                value={user.schoolId}
                                required
                                onChange={(e) =>
                                    setUser({ ...user, schoolId: e.target.value })
                                }
                            />
                        </Form.Group>  */}
          						{/* User role  */}
						 <Form.Group className="mb-3">

							<Form.Label>User Role</Form.Label>
							<Form.Select aria-label="Default select example"
							id="role"
							value={user?.role || "assistant"}
							onChange={(e) =>
								setUser({ ...user, role: e.target.value })}
							>   
								<option value="admin">ADMIN</option>
                                <option value="assistant">ASSISTANT</option>
								<option value="teacher">TEACHER</option>
								
							</Form.Select>
						</Form.Group> 
                        <div className="mt-3">
                            <Button type="submit"
                                variant="primary"
                                id="add-new-event-btn"
                                disabled={loading}
                                onClick={(e) => handleAddReader(e)}>
                                {userId ? 'Update Assistant' : 'Add New Assistant'}
                            </Button>

                            <Button className="ms-2" variant="outline-secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
};

export default AddNewAssistant;