// import node module libraries
import { Link } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";

// import MDI icons
import Icon from "@mdi/react";
import { mdiFacebook, mdiTwitter, mdiGithub } from "@mdi/js";

// import BS icons
import { Telephone, EnvelopeOpen, GeoAlt } from "react-bootstrap-icons";

// import sub components
import ContactForm from "./ContactForm";

// import media files
import Logo from "assets/images/partner/school1.png";
import SignUp from "components/dashboard/authentication/SignUp";

const Contact = () => {
  return (
    <main>
      <section className="container-fluid ">
        <Row
          className="align-items-center min-vh-100"
          style={{ background: "white" }}
        >
          <Col lg={6} md={12} sm={12}>
            <div
              className="px-xl-20 px-md-8 px-4 py-8 py-lg-0"
              style={{ textAlign: "center" }}
            >
              {/* content */}
              <div className="text-dark">
                <h1 className="display-4 fw-bold align-items-center">
                  Become a partner.
                </h1>
                <p className="lead text-dark align-items-center">
                  In the best case, you will be able to demonstrate very strong
                  alignment between your organisation’s strategic objectives and
                  those identified by the partnership.
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {/* <Link to="/"> */}
                <Image
                  src={Logo}
                  style={{
                    maxWidth: "70%",
                    margin: "auto",

                    objectFit: "center",
                    // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",  // Adds a soft shadow for a more elegant look
                  }}
                  alt="Logo"
                />
                {/* </Link> */}
              </div>
            </div>
          </Col>

          {/* right side form section with background color */}

          <Col
            lg={6}
            className="d-lg-flex align-items-center w-lg-50 min-vh-lg-100 position-fixed-lg bg-cover  top-0 right-0"
          >
            <SignUp />
          </Col>
        </Row>
      </section>
    </main>
  );
};

export default Contact;
