import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Col, Row, Tab, Card, Nav, Breadcrumb } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// import sub custom components


// import data files
import {
	PacksDetailsData
} from 'data/courses/BootstrapCoursesData';

import PackTable from './PackTable';
import AddBookToPack from './AddBookToPack';
import { fetchPackAction, fetchBooksInPackAction } from 'actions/packs';

const PackDetails = () => {
	const { id } = useParams()
	const dispatch = useDispatch();
	const { pack, books } = useSelector(state => state.packs);
	useEffect(() => {
		if (id) dispatch(fetchPackAction(id));
		dispatch(fetchBooksInPackAction(id));
	}, [dispatch, id]);

	const [showModal, setShowModal] = useState(false);

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>


					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h3 className="mb-1  fw-bold" > Pack Title : <span size="10px" className="text-secondary">{pack?.title}</span> </h3>
						</div>
					</div>
					<div>
						<h4 className="mb-1  fw-bold">
						Level : 
						<span size="10px" className="text-secondary">  {pack?.level}</span>
							
						</h4>
					</div>
					<div>
					<h4 className="mb-1  fw-bold">
					Age : 
					<span size="10px" className="text-secondary">  {pack?.age}</span>
						</h4>
					</div>
					<div>
					<h4 className="mb-1 fw-bold">
					Book Number :
					<span size="10px" className="text-secondary">  {pack?.book_number}</span>	 
						</h4>
					</div>
				</Col>
				<Row>
					<Col className="d-flex justify-content-end mb-4"  >

						<AddBookToPack
							showModal={showModal}
							setShowModal={setShowModal}
						/>

					</Col>
				</Row>

			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">

							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">


										<PackTable
											books={books}

										/>
									</Tab.Pane>

								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
			<ToastContainer/>
		</Fragment>
	);
};

export default PackDetails;
