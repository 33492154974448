import {
  addQuizToSession,
  assignQuizToUser,
  createNotification,
  createQuiz,
  createQuizAccount,
  deleteQuiz,
  deleteQuizFromSession,
  editClient,
  exportQuiz,
  fetchAllQuizs,
  fetchOneQuiz,
  getAllCategories,
  getEssayQuestion,
  getQuestion,
  getQuiz,
  getQuizAnswer,
  getQuizByToken,
  getQuizesInMeta,
  getQuizsInSession,
  getReaderInSession,
  getResult,
  getStudentsInMeta,
  importQuiz,
  importQuizJson,
  startQuiz,
  submitAnswer,
  updateQuiz,
  validationEssay,
} from "api";
import { toast } from "react-toastify";
import {
  ADD__QUIZ,
  ADD__QUIZS_TO_SESSION,
  CLEAR__QUIZ,
  CREATE_QUIZ,
  DELETE_QUIZ,
  DELETE__QUIZS_FROM_SESSION,
  GET_ALL_CATEGORIES,
  GET_ALL_QUIZS,
  GET_ALL_RESULTS_IN_BOARD,
  GET_ALL_RESULTS_IN_TASK,
  GET_ESSAY_QUESTIONS,
  GET_ONE_QUIZ,
  GET_RESULT,
  GET_RESULT_FOR_EACH_READER,
  GET_RESULT_IN_SESSION,
  GET__QUIZS_IN_SESSION,
  LOADING,
  UPDATE_QUIZ,
  VALIDATE_ESSAY_QUESTIONS,
} from "./constants";
import { updateTestAction } from "./authClient";

export const fetchAllQuizsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchAllQuizs();
    dispatch({ type: GET_ALL_QUIZS, payload: data.quizs });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const fetchOneQuizAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuiz(id);

    dispatch({ type: GET_ONE_QUIZ, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const deleteQuizAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await deleteQuiz(id);

    dispatch({ type: DELETE_QUIZ, payload: id });
    toast.success(data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);

    dispatch({ type: LOADING, payload: false });
  }
};

export const importQuizAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await importQuizJson(formData);

    dispatch({ type: CREATE_QUIZ, payload: data });
    toast.success("Quiz imported successfully", {
      autoClose: 500,
      onClose: () => window.location.reload(),
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);

    dispatch({ type: LOADING, payload: false });
  }
};

export const assignQuizToUserAction =
  (formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await assignQuizToUser(formData);
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };
export const addQuizToSessionAction =
  (formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await addQuizToSession(formData);
      const id = data.quiz_session.quiz_token;
      const { data: quiz } = await fetchOneQuiz(id);
      toast.success(data.message, {
        autoClose: 1000,
      });
      const noti = {
        title: "New Quiz Added",
        desc: "Complete your assigned quiz assessments",
        link: `https://www.iread.tn/student/student-books/`,
        cat: "65ce32accc85c73cd2f5e723",
        target: formData.session_id,
      };

      dispatch({ type: ADD__QUIZS_TO_SESSION, payload: quiz });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };
export const dleteQuizFromSessionAction =
  (formData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await deleteQuizFromSession(formData);
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();
      dispatch({
        type: DELETE__QUIZS_FROM_SESSION,
        payload: formData.quiz_token,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };

export const getQuizInSessionAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getQuizsInSession(formData);
    if (data.quizes.length > 0) {
      const quizData = await Promise.all(
        data.quizes.map(async (e) => {
          const id = e.quiz_token;
          const { data: quiz } = await fetchOneQuiz(id);

          if (quiz) {
            return quiz;
          }
        })
      );
      dispatch({ type: GET__QUIZS_IN_SESSION, payload: quizData });
    } else {
      dispatch({ type: GET__QUIZS_IN_SESSION, payload: [] });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);

    dispatch({ type: LOADING, payload: false });
  }
};

export const getResultInSessionAction =
  (session_id, user_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await getQuizsInSession({ session_id });
      if (data.quizes.length > 0) {
        const quizData = await Promise.all(
          data.quizes.map(async (e) => {
            const id = e.quiz_token;
            const { data: quiz } = await getQuiz(id);
            if (quiz) {
              try {
                const result = await getQuizAnswer({
                  quiz: quiz._id,
                  user: user_id,
                });
                if (
                  result.data.score !== undefined &&
                  result.data.score !== null
                ) {
                  return {
                    ...quiz,
                    percentage: Math.round(result.data.percentage),
                    maxScore: result?.data?.max_score,
                    userScore: result?.data?.score,
                    success: result?.data?.success,
                    completed: result?.data?.completed,
                  };
                }
              } catch (error) {
                return { ...quiz, final_results: "incomplete" };
              }
            }
          })
        );

        dispatch({ type: GET_RESULT_IN_SESSION, payload: quizData });
      } else {
        dispatch({ type: GET_RESULT_IN_SESSION, payload: [] });
      }

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.error(error);

      dispatch({ type: LOADING, payload: false });
    }
  };

export const fetchEssayQuestionAction =
  (token, user_id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await getEssayQuestion(token, user_id);
      dispatch({ type: GET_ESSAY_QUESTIONS, payload: data });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);

      dispatch({ type: LOADING, payload: false });
    }
  };
export const validationEssayAction =
  (fromData, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await validationEssay(fromData);
      dispatch({ type: VALIDATE_ESSAY_QUESTIONS, payload: data });
      toast.success(data.message, {
        autoClose: 1000,
      });
      handleClose();
      // window.location.href = "/teacher-dashboard/sessions";
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
        });
      }
    }
  };

export const addQuiz = (quiz) => (dispatch) => {
  dispatch({ type: ADD__QUIZ, payload: quiz });
};

export const createQuizAction = (fromData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await createQuiz(fromData);
    dispatch({ type: CREATE_QUIZ, payload: data });
    dispatch({ type: CLEAR__QUIZ });
    navigate("/quiz/quiz-list");

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const updateQuizAction =
  (fromData, setQuizId, navigate) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await updateQuiz(fromData);
      dispatch({ type: UPDATE_QUIZ, payload: data });
      setQuizId();
      dispatch({ type: CLEAR__QUIZ });
      navigate("/quiz/quiz-list");

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const startQuizAction =
  (quiz_id, user_id, placmentTest, navigate) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const data = await startQuiz(quiz_id, user_id);
      navigate(
        `/quiz/quiz_pass?quiz_id=${quiz_id}&user_id=${user_id}&placmentTest=${placmentTest}`
      );
      dispatch({ type: GET_FIRST_QUESTION, payload: data });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message;
      if (errorMessage) {
        console.log(errorMessage);
        toast.error(errorMessage);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const fetchResult = (token, user_id, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getResult(token, user_id);
    dispatch({ type: GET_RESULT, payload: data.final_results });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      const errorMessage = error.response.data.message;
      console.log(errorMessage);
      if (
        errorMessage ===
        "The results will be shown after teacher validation,please wait for the validation"
      ) {
        navigate(`/quiz/wait_validation`);
      }
    }
  }
};
export const getQuizAnswerAction =
  (formData, placmentTest) => async (dispatch) => {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await getQuizAnswer(formData);

      dispatch({ type: GET_RESULT, payload: data });
      dispatch({ type: LOADING, payload: false });

      if (placmentTest == "1") {
        dispatch(
          updateTestAction(userData.placmentTest._id, {
            test1: {
              result: data,
              success: data?.success,
              completed: true,
            },
            completed: !data?.success,
          })
        );
      } else if (placmentTest == "2") {
        dispatch(
          updateTestAction(userData.placmentTest._id, {
            test2: {
              result: data,
              success: data?.success,
              completed: true,
            },
            completed: !data?.success,
          })
        );
      } else if (placmentTest == "3") {
        dispatch(
          updateTestAction(userData.placmentTest._id, {
            test3: {
              result: data,
              success: data?.success,
              completed: true,
            },
            completed: !data?.success,
          })
        );
      } else if (placmentTest == "4") {
        dispatch(
          updateTestAction(userData.placmentTest._id, {
            test4: {
              result: data,
              success: data?.success,
              completed: true,
            },
            completed: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }

      dispatch({ type: LOADING, payload: false });
    }
  };

export const createQuizAccountAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    console.log(id);
    const { data: quizUser } = await createQuizAccount();
    console.log(quizUser);

    if (quizUser) {
      const { data } = await editClient({ quiz_id: quizUser._id }, id);
      const userDataWithRole = { ...data.data, role: "student" };
      localStorage.setItem("userData", JSON.stringify(userDataWithRole));
      localStorage.setItem("userId", data.data._id);
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }

    dispatch({ type: LOADING, payload: false });
  }
};
export const submitAnswerAction =
  ({ requestBody, setSelectedAnswer, nextPage, linkToResult, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      await submitAnswer(requestBody);
      setSelectedAnswer(null);
      if (requestBody.last) {
        navigate(linkToResult);
      } else {
        nextPage();
      }
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
        if (
          errorMessage == "Time's Up" ||
          errorMessage == "Quiz already completed"
        ) {
          navigate(linkToResult);
          // toast.error(errorMessage, {
          //   autoClose: 500,
          //   onClose: () => {},
          // });
        }
      }

      dispatch({ type: LOADING, payload: false });
    }
  };
export const fetchAllCategoriesAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllCategories();

    dispatch({ type: GET_ALL_CATEGORIES, payload: data.categories });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchAllresultsInTask = (id, quiz) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data: students } = await getStudentsInMeta(id);

    const resultData = await Promise.all(
      students.map(async (e) => {
        const user = e.quiz_id;

        const { data } = await getQuizAnswer({ user, quiz });

        if (data) {
          return { ...e, result: data };
        } else {
          return { ...e, result: "No result" };
        }
      })
    );

    dispatch({ type: GET_ALL_RESULTS_IN_TASK, payload: resultData });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const fetchResultsInBoard =
  ({ user_id, board_id, userQuiz_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data: quizes } = await getQuizesInMeta({ user_id, board_id });

      const resultData = await Promise.all(
        quizes.map(async (e) => {
          try {
            const quiz = e;
            const user = userQuiz_id;

            const { data } = await getQuizAnswer({ user, quiz });

            if (data) {
              return { ...data };
            }
          } catch (error) {
            return null;
          }
        })
      );

      dispatch({
        type: GET_ALL_RESULTS_IN_BOARD,
        payload: resultData.filter((e) => e != null),
      });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const exportQuizAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    // Use the existing exportQuiz API call
    const { data } = await exportQuiz(id);

    // Since you're downloading a file, use `response.data` to get the file content (likely a blob)
    const blob = new Blob([data], { type: "application/json" });

    // Create a link element to trigger the file download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `quiz_${id.id}.json`;

    // Programmatically click the link to start the download
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(link.href);

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.error("Error exporting quiz:", error);

    // Handle any error message if available
    if (error?.response?.data?.message) {
      console.error(error.response.data.message);
    }

    dispatch({ type: LOADING, payload: false });
  }
};
