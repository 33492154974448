// import node module libraries
import React, { Fragment, useState } from 'react';
import { Row, Col, Breadcrumb, Offcanvas, Button } from 'react-bootstrap';
import DetailsTable from './DetailsTable';

// import sub components

const DetailsList = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Client Details</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Client Management</Breadcrumb.Item>
								<Breadcrumb.Item active>Clients</Breadcrumb.Item>
								<Breadcrumb.Item active>Client Details</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col xs={12}>
					<DetailsTable onNewProject={handleShow} />
				</Col>
			</Row>
		</Fragment>
	);
};

export default DetailsList;
