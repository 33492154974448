// import node module libraries
import React, { useContext, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  Accordion,
  Card,
  useAccordionButton,
  AccordionContext,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import Topics from "./Topics";
import { deleteSubjectAction, updateSubjectAction } from "actions/curriculum";
import { useDispatch } from "react-redux";

const UpdateTopic = ({
  id,
  subjectData,
  setSubjectData,
  showTopic,
  setShowTopic,
  curriculumId,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowTopic(false);
    setSubjectData({
      title: "",
      description: "",
    });
  };

  const handleUpdateSubject = () => {
    dispatch(updateSubjectAction(id, subjectData, handleClose, curriculumId));
  };
  return (
    <Fragment>
      <Modal
        show={showTopic}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Form.Group className="mb-3" controlId="formaddupdatelecture">
            <Form.Control
              type="text"
              placeholder="title"
              value={subjectData.title}
              onChange={(e) =>
                setSubjectData({ ...subjectData, title: e.target.value })
              }
            />
            <Form.Control
              type="text"
              className="mt-3"
              placeholder="description"
              value={subjectData.description}
              onChange={(e) =>
                setSubjectData({
                  ...subjectData,
                  description: e.target.value,
                })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="pt-0 border-0 d-inline ">
          <Button onClick={() => handleUpdateSubject()} variant="primary">
            Update Topic
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const GKAccordionActions = ({
  accordionItems,
  curriculumId,
  setSubjectId,
  setShowPoint,
  setPointId,
  setPoint,
}) => {
  const dispatch = useDispatch();
  //delete
  const [showDelete, setShowDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState();

  const handleClose = () => setShowDelete(false);
  const handleShow = (id) => {
    setIdToDelete(id);
    setShowDelete(true);
  };

  const handleDeleteTopic = () => {
    dispatch(
      deleteSubjectAction(idToDelete, setIdToDelete, handleClose, curriculumId)
    );
  };
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Fragment>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">
            <Link
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
              to="#"
              className="text-inherit"
            >
              <span className="align-middle p-1">{children}</span>
            </Link>
          </h5>
          <div>
            <Link
              to="#"
              className="text-inherit"
              data-bs-toggle="collapse"
              onClick={decoratedOnClick}
              aria-expanded={isCurrentEventKey}
            >
              <span className="chevron-arrow">
                <i className="fe fe-chevron-down fs-5"></i>
              </span>
            </Link>
          </div>
        </div>
        <Modal
          show={showDelete}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Topic</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <p>Are you sure you want to delete thus Topic</p>
          </Modal.Body>
          <Modal.Footer className="pt-0 border-0 d-inline ">
            <Button onClick={handleDeleteTopic} variant="primary">
              Yes
            </Button>
            <Button variant="outline-secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  };

  const handleShowTopic = (e) => {
    setSubjectData({
      title: e.title,
      description: e.description,
    });
    setShowTopic(true);
  };

  const [subjectData, setSubjectData] = useState({
    title: "",
    description: "",
  });

  const [showTopic, setShowTopic] = useState(false);

  // points mangment
  const handleAddPoint = (id) => {
    setShowPoint(true);
    setSubjectId(id);
  };
  return (
    <Fragment>
      <Accordion defaultActiveKey={accordionItems[0]?._id}>
        {accordionItems.map((item, index) => (
          <Card
            key={item?._id}
            className="px-2 py-2 mb-1 shadow-none"
            style={{ position: "relative" }}
          >
            <div
              style={{
                position: "absolute",
                right: 20,
                top: 12,
              }}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
              >
                <Link
                  onClick={() => handleShowTopic(item)}
                  to="#"
                  className="me-1 text-inherit"
                  title="Edit"
                >
                  <i className="fe fe-edit fs-6"></i>
                </Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
              >
                <Link
                  onClick={() => handleShow(item._id)}
                  to="#"
                  className="me-1 text-inherit"
                  title="Delete"
                >
                  <i className="fe fe-trash-2 fs-6"></i>
                </Link>
              </OverlayTrigger>
            </div>
            <UpdateTopic
              id={item._id}
              subjectData={subjectData}
              setSubjectData={setSubjectData}
              showTopic={showTopic}
              setShowTopic={setShowTopic}
              curriculumId={curriculumId}
            />
            <Card.Header className="bg-transparent border-0 p-0">
              <div className="border-0">
                <h3 className="mb-0 fw-bold">
                  <ContextAwareToggle eventKey={item?._id + index}>
                    {item.title}
                  </ContextAwareToggle>
                </h3>
                <p>{item?.description}</p>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey={item?._id + index}>
              <Card.Body className="fs-4">
                {/* {item.content}	 */}
                <Topics
                  accordionItems={item.topics}
                  setPoint={setPoint}
                  setPointId={setPointId}
                  setShowPoint={setShowPoint}
                />
                <Link
                  onClick={() => handleAddPoint(item?._id)}
                  to="#"
                  className="btn btn-secondary btn-sm mb-1"
                >
                  Add Points +
                </Link>{" "}
                {/* <Link to="#" className="btn btn-secondary btn-sm mb-1">
                  Add Description +
                </Link> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </Fragment>
  );
};

export default GKAccordionActions;
