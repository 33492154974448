import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'helper/utils';
import { fetchAllBadgesAction, getFreeBadgesAction } from 'actions/badge';

const AutocompleteBadgeInClass = ({selectedOption, setSelectedOption}) => {
	const dispatch = useDispatch();
	const {freeBadges} = useSelector(state => state.badges);
  const [searchText, setSearchText] = useState('');
  const search=useDebounce(searchText,500)
  const handleInputChange = (text) => {
    setSearchText(text);
  };
  const page=1;
	useEffect(() => {
		dispatch(getFreeBadgesAction(search,page))
		 
	  }, [dispatch,search,page]);

  const handleSelection = (selected) => {
    if(selected.length>0){
        setSelectedOption(selected[0].badgeId);
        
    }
   
  };
 
  const options = [
    
    ...freeBadges.map(item => item),
    
  ];

  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey="badgeId" 
        options={options}
        placeholder="Type to search..."
        onChange={handleSelection}
        onInputChange={(text) => handleInputChange(text)}
      />
    </div>
  );
};

export default AutocompleteBadgeInClass;
