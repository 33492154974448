import { v4 as uuid } from "uuid";

const NavbarHome = [
  {
    id: uuid(),
    menuitem: "Home",
    link: "/",
  },
  // {
  //   id: uuid(),
  //   menuitem: "Pricing",
  //   link: "/marketing/pages/pricing",
  // },

  // {
  //   id: uuid(),
  //   menuitem: "About us",
  //   link: "/marketing/pages/about",
  // },
  {
    id: uuid(),
    menuitem: "Become Partner",
    link: "/marketing/pages/contact",
  },
];

export default NavbarHome;
