// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link, useParams } from "react-router-dom";
import {
  Dropdown,
  Image,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { MoreVertical, Trash, Edit, Mail } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";

// import utility file

// import data files
import {
  deleteClientFromClassAction,
  fetchClientInClassAction,
} from "actions/classes";
import { assigneBadgeAction } from "actions/badge";
import AutocompleteBadgeInClass from "./AutoCompleteBadgeInClass";
import SpinnerLoading from "../SpinnerLoading";

const ClientInClassTable = ({ setClientId }) => {
  const { loading } = useSelector((state) => state.auth);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const { id: class_id } = useParams();
  const dispatch = useDispatch();
  const { clientsInClasses } = useSelector((state) => state.clientsInClasses);

  useEffect(() => {
    dispatch(fetchClientInClassAction(class_id));
  }, [dispatch]);

  const handleDeleteClientInClass = (id) => {
    setClientInClassToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteClientInClass = () => {
    if (clientInClassToDelete && confirmationMessage === "DELETE CLIENT") {
      dispatch(
        deleteClientFromClassAction({
          classId: class_id,
          clientId: clientInClassToDelete,
        })
      );
      setClientInClassToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clientInClassToDelete, setClientInClassToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  //assigne Badge
  // const [textInput, setTextInput] = useState('');
  // const handleTextInput = (e) => {
  // 	setTextInput(e.target.value);
  // };
  const handleAssigne = () => {
    dispatch(assigneBadgeAction(selectedOption, class_id));
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));
  const DeleteClient = ({ id, setClientId }) => {
    return (
      userData?.role === "admin" && (
        <Button variant="danger" onClick={() => handleDeleteClientInClass(id)}>
          <Trash size="15px" onClick={confirmDeleteClientInClass} />
        </Button>
      )
    );
  };
  const ActionMenu = ({ id, setClientId }) => {
    return (
      <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={() => setClientId(id)}>
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="2"
            onClick={() => handleDeleteClientInClass(id)}
          >
            {" "}
            <Trash
              size="15px"
              className="dropdown-item-icon"
              variant="danger"
              onClick={confirmDeleteClientInClass}
            />{" "}
            Remove
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "_id", Header: "ID", show: false },
      {
        Header: "Index",
        accessor: (_, index) => index + 1,
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        accessor: "name",
        Header: "Name",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              {/* <Image
								src={row.original.img}
								alt=""
								className="rounded-circle avatar-md me-2"
							/> */}
              <h5 className="mb-0">{value}</h5>
            </div>
          );
        },
      },
      { accessor: "defaultPhone", Header: "Main Phone" },

      {
        accessor: "code",
        Header: "Intellect Code",
        Cell: ({ value }) => {
          return (
            <h5
              className={
                value !== undefined && value !== null
                  ? "text-secondary"
                  : "text-danger"
              }
            >
              {value !== undefined && value !== null ? value : "No code"}
            </h5>
          );
        },
      },

      {
        accessor: "badge",
        Header: "Badge",
        Cell: ({ value }) => {
          if (value && value.badgeId) {
            return <span style={{ color: "green" }}>{value.badgeId}</span>;
          } else {
            return <span style={{ color: "red" }}>Not Assigned</span>;
          }
        },
      },

      {
        accessor: "message",
        Header: "",
        Cell: ({ row }) => {
          return (
            <div className="hstack gap-4">
              {/* <OverlayTrigger
								key="top"
								placement="top"
								overlay={<Tooltip id={`tooltip-top`}>Message</Tooltip>}
							>
								<Link to="#">
									<Mail size="15px" className="dropdown-item-icon" />
								</Link>
							</OverlayTrigger>
 */}
              {/* <OverlayTrigger
								key="top"
								placement="top"
								overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}
							>
								<Link to="#">
									<Trash size="15px" className="dropdown-item-icon" />
								</Link>
							</OverlayTrigger> */}

              <DeleteClient id={row.original._id} setClientId={setClientId} />
            </div>
          );
        },
      },
    ],
    []
  );
  const data = useMemo(() => clientsInClasses, [clientsInClasses]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className=" overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Client"
            />
          </Col>
        </Row>
      </div>

      <Table
        hover
        responsive
        {...getTableProps()}
        className="text-nowrap table-centered"
      >
        <thead className="table-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this client?</p>
          <h4 className="text-secondary">
            you must write "DELETE CLIENT" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteClientInClass}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
      <Col
        className="d-flex justify-content-start"
        style={{ marginBottom: "5%", marginTop: "1%" }}
      >
        {/* <div style={{ marginRight: "2%" }}>
					<input
						placeholder='Start from ..'
						className="form-control"
						type="text"
						onChange={handleTextInput}
					/>
				</div> */}
        <AutocompleteBadgeInClass
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <Button
          variant="primary"
          disabled={loading}
          style={{ width: "20%", marginLeft: "2%", height: "5%" }}
          onClick={() => handleAssigne()}
        >
          {" "}
          {loading ? <Spinner /> : "Assigned Badge"}
        </Button>
      </Col>
      <ToastContainer />
    </Fragment>
  );
};

export default ClientInClassTable;
