// import node module libraries
import {  Spinner } from 'react-bootstrap';

const SpinnerLoading = () => {
	

	return (
<div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'70vh'}}>
	<Spinner animation="grow" style={{color:"#6343D8", height:"50px",width:"50px"}} />
	</div>
	);
};

export default SpinnerLoading;
