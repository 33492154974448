// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Card, Tab, Breadcrumb, Nav, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// import sub components
import ClientInClassTable from './ClientInClassTable';
import AutocompliteClient from './AutoCompliteClient';
import { AddClientInClassAction, fetchClientInClassAction, generateCodeAction } from 'actions/classes';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addClientFromDataAction, generateExcelFileAction, getClientFromDataAction } from 'actions/client';



const ClientInClassPage = () => {
	const { loading } = useSelector(state => state.auth)
	const dispatch = useDispatch()
	const [clientId, setClientId] = useState(null)
	const [selectedOption, setSelectedOption] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedClient, setSelectedClient] = useState(null);
	const { clientsFromData } = useSelector(state => state.clientsFromData);
	
	const clientNames = clientsFromData?.map(client => client.name);

	const { id: class_id } = useParams();
	useEffect(() => {
		dispatch(fetchClientInClassAction(class_id))
	}, [dispatch]);
	const handleAddClient = async (e) => {
		e.preventDefault()
		if (class_id && selectedOption)
			dispatch(AddClientInClassAction({ classId: class_id, clientId: selectedOption }));
	}

	const handleSearchClient = () => {
		dispatch(getClientFromDataAction(searchQuery));
	}
	const handleGenerateCode = (class_Id) => {
		dispatch(generateCodeAction(class_Id))
	}
	const handleAddClientFromData = () => {
		if (class_id && selectedClient) {
			const clientWithSchoolId = {
				name: selectedClient.name,
				email: selectedClient.email,
				phone: selectedClient.phone,
				address: selectedClient.address,
				birthday: selectedClient.birthday,
				fatherFullName: selectedClient.fatherFullName,
				motherFullName: selectedClient.motherFullName,
				fatherPhone: selectedClient.fatherPhone,
				motherPhone: selectedClient.motherPhone,
				defaultPhone: selectedClient.defaultPhone === ""
					? (selectedClient.phone === ""
						? (selectedClient.fatherPhone === ""
							? selectedClient.motherPhone
							: selectedClient.fatherPhone)
						: selectedClient.phone)
					: selectedClient.defaultPhone,
				schoolId: '651ec55b85ea310134cdd167'
			};
			dispatch(addClientFromDataAction(clientWithSchoolId, class_id, setSelectedClient));
		}
	}
	const handleGenerateExcel = () => {
		dispatch(generateExcelFileAction(class_id));
	};

	return (
		<Fragment>
			<Tab.Container defaultActiveKey="list">
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Add Client To Class
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">Classes management</Breadcrumb.Item>
									<Breadcrumb.Item active>Classes</Breadcrumb.Item>
									<Breadcrumb.Item active>Add Client To Class</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							{/* <div>
								<GridListViewButton keyList="list" keyGrid="grid" />
							</div> */}


						</div>
					
						<Col className="d-flex justify-content-end" >
							<div className="mb-3" style={{ width: "50%", marginRight: '30%' }}>
								<input
									type="search"
									className="form-control"
									placeholder="Search Client From Your Data"
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
								{/* <ClientFromData
								selectedClient={selectedClient}
								setSelectedClient={setSelectedClient}
								/> */}
								<div className="mt-3">
									<div>
										<Button type="submit"
											variant="primary"
											id="add-new-event-btn"
											onClick={handleSearchClient}
										>
											Search Client From Your Data
										</Button>


									</div>
									<div style={{ marginTop: '10%' }}>
										{clientNames && clientNames.length > 0 && (
											<div className="dropdown">
												<select className="form-control" onChange={(e) => {
													const selectedClientData = clientsFromData.find(client => client.name === e.target.value);
													setSelectedClient(selectedClientData);
												}}>
													<option value="">Select a client</option>
													{clientNames.map((name, index) => (
														<option key={index} value={name}>
															{name}
														</option>
													))}
												</select>
												<Button
													className="mt-3"
													type="submit"
													variant="primary"
													id="add-new-event-btn"
													disabled={loading}
													onClick={handleAddClientFromData}
												>
													Add Client From Your Data
												</Button>
											</div>
										)}

									</div>

								</div>
							</div>
							<div >
								<AutocompliteClient
									selectedOption={selectedOption}
									setSelectedOption={setSelectedOption}
									class_id={class_id}

								/>
								<div className="mt-3">
									<Button type="submit"
										variant="primary"
										id="add-new-event-btn"
										onClick={(e) => handleAddClient(e)}
									>
										Add Client To this Class
									</Button>
								</div>


							</div>
						</Col>
						 <Col className="d-flex justify-content-end">
							<div>
							<Button 
							style={{backgroundColor:"#717895"}}
							onClick={() => handleGenerateCode(class_id)}
							>
								Generate Code For All Clients
							</Button>

							</div>
						</Col> 
						<Col className="d-flex justify-content-start">
							<div>
								<Button style={{marginTop:"-55px"}} onClick={handleGenerateExcel}>
									Generate Excel File
								</Button>

							</div>
						</Col>
					</Col>
				</Row>
				<Tab.Content>

					<Tab.Pane eventKey="list" className="pb-4">
						{/* students in list view */}
						<ClientInClassTable
							setClientId={setClientId}
						/>
						{/* <StudentsListItems /> */}
						{/* end of students in list view */}
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
			<ToastContainer />
		</Fragment>

	);
};

export default ClientInClassPage;
