// import node module libraries
import React, { Fragment, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	Col,
	Row,
	Dropdown,
	Table,
	Card,
	ProgressBar,
	Form,
	Button
} from 'react-bootstrap';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';


// import utility file
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientDetailsAction } from 'actions/clientDetails';
import { reformatISODate } from 'helper/utils';

const DetailsTable = () => {
    const {id}=useParams();
    const dispatch = useDispatch();
	const { clientDetails } = useSelector(state => state?.clientDetails);
    useEffect(() => {
		dispatch(fetchClientDetailsAction(id))
	}, [dispatch]);
	// const filterOptions = [
	// 	{ value: 'true', label: 'Present' },
	// 	{ value: 'false', label: 'Absent' },
	// ];

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));


	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{
				accessor: 'clientId',
				Header: 'Client Name',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<div className="ms-3">
								<h4 className="mb-0">
									{value?.name}
								</h4>
							</div>
						</div>
					);
				}
			},
            {   id:"class",
				accessor: 'sessionId',
				Header: 'Class Title',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<div className="ms-3">
								<h4 className="mb-0">
									{value?.classRomId?.title ? value?.classRomId?.title :''}
								</h4>
							</div>
						</div>
					);
				}
			},
			{
                accessor: 'sessionId',
                Header: 'Session Details',
                Cell: ({ value, row }) => {
                    const formattedDate = reformatISODate(value?.startAt);
                    return (
                        <div className="d-flex align-items-center">
                            <div className="ms-3">
                                <h4 className="mb-0">
                                    {formattedDate}
                                </h4>
                            </div>
                        </div>
                    );
                }
            },
			
            {
                accessor: 'presence',
                Header: 'Status',
                Cell: ({ value }) => {
                  const statusText = value ? 'Present' : 'Absent';
                  const colorClass = value ? 'success' : 'danger';
              
                  return (
                    <span className={`text-${colorClass}`}>
                      {statusText}
                    </span>
                  );
                }
              }
		],
		[]
	);

	const data = useMemo(() => clientDetails, [clientDetails]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<Row className="justify-content-md-between mb-4 mb-xl-0 ">
				<Col xl={2} lg={4} md={6} xs={12}>
					{/* search records */}
					<div className="mb-2 mb-lg-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search by name"
						/>
					</div>
				</Col>
				{/* <Col xxl={2} lg={2} md={6} xs={12}>
					
					<Form.Control
						as={FormSelect}
						placeholder="Filter"
						options={filterOptions}
					/>
				</Col> */}
			</Row>

			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>
						<Card.Body className="p-0">
							<div className="table-responsive border-0 overflow-y-hidden">
								<Table
									hover
									{...getTableProps()}
									className="text-nowrap table-centered"
								>
									<thead>
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<th {...column.getHeaderProps()}>
														{column.render('Header')}
													</th>
												))}
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()}>
										{page.map((row) => {
											prepareRow(row);
											return (
												<tr {...row.getRowProps()}>
													{row.cells.map((cell) => {
														return (
															<td
																{...cell.getCellProps()}
																className="align-middle"
															>
																{cell.render('Cell')}
															</td>
														);
													})}
												</tr>
											);
										})}
										
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>

					{/* Pagination @ Footer */}
					<div className="mt-4">
						{' '}
						<Pagination
							previousPage={previousPage}
							pageCount={pageCount}
							pageIndex={pageIndex}
							gotoPage={gotoPage}
							nextPage={nextPage}
						/>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default DetailsTable;
