export const AUTH = "AUTH";
export const LOADING = "LOADING";
export const LOGOUT = "LOGOUT";

//Users constants
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER = "CREATE_USER";

//Admin constants
export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const GET_ADMIN = "GET_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const CREATE_ADMIN = "CREATE_ADMIN";

//Assistant constants
export const GET_ALL_ASSISTANTS = "GET_ALL_ASSISTANTS";
export const GET_ASSISTANT = "GET_ASSISTANT";
export const EDIT_ASSISTANT = "EDIT_ASSISTANT";
export const DELETE_ASSISTANT = "DELETE_ASSISTANT";
export const CREATE_ASSISTANT = "CREATE_ASSISTANT";

//Teachers constants
export const GET_ALL_TEACHERS = "GET_ALL_TEACHERS";
export const GET_TEACHER = "GET_TEACHER";
export const EDIT_TEACHER = "EDIT_TEACHER";
export const DELETE_TEACHER = "DELETE_TEACHER";
export const CREATE_TEACHER = "CREATE_TEACHER";

//CLIENT constants
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const GET_CLIENT = "GET_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const GET_CLIENT_FROM_DATA = "GET_CLIENT_FROM_DATA";
export const ADD_CLIENT_FROM_DATA = "ADD_CLIENT_FROM_DATA";
export const GENERATE_EXCEL = "GENERATE_EXCEL";
export const SEARCH_READER = "SEARCH_READER";
export const LINK_CODE = "LINK_CODE";
export const CHECK_SESSION = "CHECK_SESSION";

//CLASS constants
export const GET_ALL_CLASSES = "GET_ALL_CLASSES";
export const GET_CLASS = "GET_CLASS";
export const EDIT_CLASS = "EDIT_CLASS";
export const DELETE_CLASS = "DELETE_CLASS";
export const CREATE_CLASS = "CREATE_CLASS";
export const ADD_CLIENT_IN_CLASS = "ADD_CLIENT_IN_CLASS";
export const GET_CLIENT_IN_CLASS = "GET_CLIENT_IN_CLASS";
export const DELETE_CLIENT_FROM_CLASS = "DELETE_CLIENT_FROM_CLASS";
export const GENERATE_CODE = "GENERATE_CODE";

//SMS
export const SEND_TO_CLIENT = "SEND_TO_CLIENT";
export const SEND_TO_CLASS = "SEND_TO_CLASS";
export const SEND_CODE_TO_CLASS = "SEND_CODE_TO_CLASS";
export const VIEW_SMS_CLASS = "VIEW_SMS_CLASS";

//PRESENCE
export const START_SESSION = "START_SESSION";
export const GET_ALL_PRESENCE = "GET_ALL_PRESENCE";
export const PRESENT_ACTION = "PRESENT_ACTION";
export const ABSENT_ACTION = "ABSENT_ACTION";
export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const GET_CLIENT_NAME = "GET_CLIENT_NAME";
//Notes
export const CREATE_NOTE = "CREATE_NOTE";
export const GET_NOTE_BY_TEACHER = "GET_NOTE_BY_TEACHER";
export const DELETE_NOTE = "DELETE_NOTE";
export const GET_ONE_NOTE = "GET_ONE_NOTE";
export const EDIT_NOTE = "EDIT_NOTE";
export const GET_ALL_NOTES = "GET_ALL_NOTES";
export const CHANGE_STATUS = "CHANGE_STATUS";

//Sessions constants
export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const GET_SESSION = "GET_SESSION";
export const EDIT_SESSION = "EDIT_SESSION";
export const DELETE_SESSION = "DELETE_SESSION";
export const CREATE_SESSION = "CREATE_SESSION";
export const GET_SESSIONS_BY_TEACHER = "GET_SESSIONS_BY_TEACHER";

//Badge Constant

export const GET_ALL_BADGES = "GET_ALL_BADGES";
export const GET_FREE_BADGES = "GET_FREE_BADGES";
export const GET_BADGE = "GET_BADGE";
export const EDIT_BADGE = "EDIT_BADGE";
export const DELETE_BADGE = "DELETE_BADGE";
export const CREATE_BADGE = "CREATE_BADGE";

//Logs Constant

export const GET_ALL_LOGS = "GET_ALL_LOGS";

//KANBAN TASKs
export const CREATE_KANBAN_TASK = "CREATE_KANBAN_TASK";
export const GET_KANBAN_TASK_BY_TEACHER = "GET_KANBAN_TASK_BY_TEACHER";
export const DELETE_KANBAN_TASK = "DELETE_KANBAN_TASK";
export const GET_ONE_KANBAN_TASK = "GET_ONE_KANBAN_TASK";
export const EDIT_KANBAN_TASK = "EDIT_KANBAN_TASK";
export const GET_ALL_KANBAN_TASKS = "GET_ALL_KANBAN_TASKS";
//KANBAN STATUS
export const GET_ALL_STATUS = "GET_ALL_STATUS";

// Board Constants
export const GET_ALL_BOARDS = "GET_ALL_BOARDS";
export const GET_BOARD = "GET_BOARD";
export const GET_BOARD_FOR_TEACHER = "GET_BOARD_FOR_TEACHER";
export const EDIT_BOARD = "EDIT_BOARD";
export const DELETE_BOARD = "DELETE_BOARD";
export const CREATE_BOARD = "CREATE_BOARD";
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_STUDENTS = "GET_STUDENTS";

// List Constants
export const GET_ALL_LISTS = "GET_ALL_LISTS";
export const GET_LIST = "GET_LIST";
export const GET_LIST_BY_BOARD = "GET_LIST_BY_BOARD";
export const EDIT_LIST = "EDIT_LIST";
export const DELETE_LIST = "DELETE_LIST";
export const CREATE_LIST = "CREATE_LIST";
export const MOVE_TASK = "MOVE_TASK";

// CArd Constants
export const GET_ALL_CARDS = "GET_ALL_CARDS";
export const GET_NOTE_CARD = "GET_NOTE_CARD";
export const GET_CARD = "GET_CARD";
export const GET_CARD_BY_LIST = "GET_CARD_BY_LIST";
export const EDIT_CARD = "EDIT_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const CREATE_CARD = "CREATE_CARD";
export const GET_ALL_ARCHIVED_CARDS = "GET_ALL_ARCHIVED_CARDS";
export const GET_CARD_IN_BOARD = "GET_CARD_IN_BOARD";
export const ARCHIVE_TASK = "ARCHIVE_TASK";
export const SET_TASK = "REMOVE_TASK";
export const REMOVE_CARD = "REMOVE_CARD";
// Group cards

export const CREATE_KANBAN_GROUP = "CREATE_KANBAN_GROUP";
export const EDIT_KANBAN_GROUP = "EDIT_KANBAN_GROUP";
export const DELETE_KANBAN_GROUP = "DELETE_KANBAN_GROUP";
export const HIDE_GROUPS = "HIDE_GROUPS";
// Comment Constants
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENT_BY_CARD = "GET_COMMENT_BY_CARD";
export const EDIT_COMMENT = "EDIT_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const CREATE_COMMENT = "CREATE_COMMENT";
//DESC TASK
export const GET_DESC = "GET_DESC";
export const GET_DESC_BY_CARD = "GET_DESC_BY_CARD";
export const DELETE_DESCRIPTION = "DELETE_DESCRIPTION";
export const CREATE_DESC = "CREATE_DESC";
export const EDIT_DESC = "EDIT_DESC";

// Logs Constants
export const GET_ALL_TASK_LOGS = "GET_ALL_TASK_LOGS";
export const GET_ALL_BOARD_LOGS = "GET_ALL_BOARD_LOGS";

//pack constant
export const GET_PACK_FROM_DATA = "GET_PACK_FROM_DATA";

//Approve User
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_TEACHER_SUCCESS = "APPROVE_TEACHER_SUCCESS";

//Packs constants
export const GET_ALL_PACKS = "GET_ALL_PACKS";
export const GET_PACK = "GET_PACK";
export const EDIT_PACK = "EDIT_PACK";
export const DELETE_PACK = "DELETE_PACK";
export const CREATE_PACK = "CREATE_PACK";
export const GET_BOOKS_IN_PACK = "GET_BOOKS_IN_PACK";
export const ADD_BOOKS_IN_PACK = "ADD_BOOKS_IN_PACK";
export const DELETE_BOOKS_FROM_PACK = "DELETE_BOOKS_FROM_PACK";

//Books constants
export const GET_ALL_BOOKS = "GET_ALL_BOOKS";
export const GET_BOOK = "GET_BOOK";
export const EDIT_BOOK = "EDIT_BOOK";
export const DELETE_BOOK = "DELETE_BOOK";
export const CREATE_BOOK = "CREATE_BOOK";

export const GET_SESSIONS_FROM_BOOK = "GET_SESSIONS_FROM_BOOK";

//FollowedPacks constants
export const GET_ALL_FOLLOWED_PACKS = "GET_ALL_FOLLOWED_PACKS";
export const APPROVE_FOLLOWED_PACK = "APPROVE_FOLLOWED_PACK";
export const DELETE_FOLLOWED_PACK = "DELETE_FOLLOWED_PACK";
export const REJECT_FOLLOWED_PACK = "REJECT_FOLLOWED_PACK";

//FollowedSessions constants
export const GET_ALL_FOLLOWED_SESSIONS = "GET_ALL_FOLLOWED_SESSIONS";
export const APPROVE_FOLLOWED_SESSION = "APPROVE_FOLLOWED_SESSION";
export const DELETE_FOLLOWED_SESSION = "DELETE_FOLLOWED_SESSION";
export const REJECT_FOLLOWED_SESSION = "REJECT_FOLLOWED_SESSION";

//Curriculum

export const GET_ALL_CURRICULUM = "GET_ALL_CURRICULUM";
export const DELETE_CURRICULUM = "DELETE_CURRICULUM";
export const CREATE_CURRICULUM = "CREATE_CURRICULUM";
export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM";
export const CREATE_SUBJECT_IN_CURRIULUM = "CREATE_SUBJECT_IN_CURRIULUM";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const CREATE_TOPIC_IN_SUBJECT = "CREATE_TOPIC_IN_SUBJECT";
export const DELETE_TOPIC = "DELETE_TOPIC";
export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const FETCH_POINTS = "FETCH_POINTS";
export const GET_ONE_CURRICULUM = "GET_ONE_CURRICULUM";

//QUizs constants
export const GET_ALL_QUIZS = "GET_ALL_QUIZS";
export const GET_ONE_QUIZ = "GET_ONE_QUIZ";
export const GET__QUIZS_IN_SESSION = "GET__QUIZS_IN_SESSION";
export const ADD__QUIZS_TO_SESSION = "ADD__QUIZS_TO_SESSION";
export const DELETE__QUIZS_FROM_SESSION = "DELETE__QUIZS_FROM_SESSION";
export const GET_RESULT_IN_SESSION = "GET_RESULT_IN_SESSION";
export const GET_ESSAY_QUESTIONS = "GET_ESSAY_QUESTIONS";
export const VALIDATE_ESSAY_QUESTIONS = "VALIDATE_ESSAY_QUESTIONS";
export const GET_RESULT_FOR_EACH_READER = "GET_RESULT_FOR_EACH_READER";
export const ADD__QUIZ = "ADD__QUIZ";
export const CREATE_QUIZ = "CREATE_QUIZ";
export const DELETE_QUIZ = "DELETE_QUIZ";
export const UPDATE_QUIZ = "UPDATE_QUIZ";
export const CLEAR__QUIZ = "CLEAR_QUIZ";
export const GET_RESULT = "GET_RESULT";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_RESULTS_IN_TASK = "GET_ALL_RESULTS_IN_TASK";
export const FETCH_POINTS_BY_IDS = "FETCH_POINTS_BY_IDS";
export const GET_ALL_RESULTS_IN_BOARD = "GET_ALL_RESULTS_IN_BOARD";
export const FETCH_LEARNING_PATH = "FETCH_LEARNING_PATH";
//words
export const GET_JSON_list = "GET_JSON_list";
export const GET_WORD = "GET_WORD";
export const SPLIT_STORY = "SPLIT_STORY";
//Placment Test
export const UPDATE_PLACMENT_TEST = "UPDATE_PLACMENT_TEST";
//Templats

export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const GENERATE_TEMPLATE = "GENERATE_TEMPLATE";
export const GET_RELATED_CARDS = "GET_RELATED_CARDS";

//meeting
export const CREATE_MEETING = "CREATE_MEETING";
export const FETCH_MEETINGS = "FETCH_MEETINGS";
export const FETCH_MEETING = "FETCH_MEETING";
export const START_MEETING = "START_MEETING";
