// import node module libraries
import { Col, Row, Form, Button, Breadcrumb } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation} from 'react-router-dom';
import { createNoteAction } from 'actions/notes';

const CreateRequest = () => {
    const {loading }= useSelector(state=>state.auth)
    const dispatch= useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get('name');
    const id = queryParams.get('id');
    const [message, setMessage] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(message && id)
        dispatch(createNoteAction(id,message,setMessage));
    };
    return (
        <div >
        <Row>
                <Col lg={12} md={12} sm={12}>
                    <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
                        <div className="mb-3 mb-md-0">
                            <h1 className="mb-1 h2 fw-bold">
                            Create Note For {name}
                            </h1>
                            <Breadcrumb>
                                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item href="#">Teacher</Breadcrumb.Item>
                                <Breadcrumb.Item active>Create Note</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    
                    </div>
                </Col>
            </Row>
        {/* form section */}
        <div className="px-4 px-xl-20 py-8 py-lg-0" id="form">
                <Form>
                    <Row>
                        {/* Messages */}
                        <Col md={15} sm={12}>
                            <Form.Group className="mb-3" controlId="formMessages">
                                <Form.Label>Message:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Messages"
                                    rows={3}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        {/* button */}
                        <Col md={12} sm={12}>
                            <Button
                             variant="primary"
                              type="submit"
                              disabled={loading}
                              onClick={(e) => handleSubmit(e)}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <ToastContainer/>
        </div>
    );
};
export default CreateRequest;
