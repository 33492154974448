import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Tab, Card, Breadcrumb, Dropdown } from "react-bootstrap";

// import sub custom components

import AddNewBoard from "./AddNewBoard";

import { importBoardAction } from "actions/boardKanban";
import { useDispatch } from "react-redux";
import BoardKanbanGrid from "./BoardKanbanGrid";
import ImportNewBoard from "./ImportNewBoard";
import BoardFromTemplate from "./BoardFromTemplate";

const BoardKanban = () => {
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [boardId, setBoardId] = useState(null);
  const [show, setShow] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [file, setFile] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [boardData, setBoardData] = useState({
    title: "",
    description: "",
    owner: userData._id,
    members: [],
    type: "",
    curriculum: [],
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (userData) {
      setOwnerId(userData._id);
    }
  }, []);
  useEffect(() => {
    if (boardId) setShow(true);
  }, [boardId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (!file) {
      alert("Please select a JSON file");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(importBoardAction(formData, ownerId));
    }
  };

  return (
    <Fragment>
      <AddNewBoard
        showBoard={show}
        setShowBoard={setShow}
        setBoardId={setBoardId}
        boardId={boardId}
        boardData={boardData}
        setBoardData={setBoardData}
      />
      <ImportNewBoard
        handleFileChange={handleFileChange}
        handleUpload={handleUpload}
        show={showImport}
        setShow={setShowImport}
      />
      <BoardFromTemplate show={showTemplate} setShow={setShowTemplate} />

      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0" style={{ marginBottom: "5%" }}>
              <h1 className="mb-1 h2 fw-bold">List of Boards </h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Kanban Board</Breadcrumb.Item>
                <Breadcrumb.Item active>List of Boards</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
        <Dropdown drop="end">
          <Dropdown.Toggle
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: 0,
              color: "black",
            }}
          >
            <i className="fe fe-plus fs-4"> Add Board</i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="start">
            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              onClick={() => {
                setShow(true);
                setBoardData({
                  title: "",
                  description: "",
                  owner: userData._id,
                  members: [],
                  type: "",
                  curriculum: [],
                });
              }}
            >
              <i className="dropdown-item-icon fe fe-plus"></i> Create Board
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              onClick={() => {
                setShowImport(true);
              }}
            >
              <i className="dropdown-item-icon fe fe-plus"></i> Import Board
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              onClick={() => {
                setShowTemplate(true);
              }}
            >
              <i className="dropdown-item-icon fe fe-plus"></i> Board From
              Template
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* <Col className="d-flex justify-content-start">
          <div>
            <AddNewBoard
              showBoard={show}
              setShowBoard={setShow}
              setBoardId={setBoardId}
              boardId={boardId}
            />
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="primary"
            disabled={loading}
            style={{ margin: "0 15px 15px 0", width: "40%" }}
            onClick={handleUpload}
          >
            Import Board
          </Button>
          <div>
            <input
              className="form-control"
              type="file"
              accept=".json"
              onChange={handleFileChange}
            />
          </div>
        </Col> */}
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card style={{ backgroundColor: "#f1f5f9" }}>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4">
                    <BoardKanbanGrid setBoardId={setBoardId} />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default BoardKanban;
