import { GET_JSON_list, GET_WORD, SPLIT_STORY } from "../actions/constants";

const WordReducer = (
  state = { words: [], verbTenses: [], word: null, stories: [] },
  action
) => {
  switch (action.type) {
    case SPLIT_STORY:
      return { ...state, words: action?.payload };
    case GET_WORD:
      return { ...state, word: action?.payload };
    case GET_JSON_list:
      return { ...state, stories: action?.payload };

    default:
      return state;
  }
};

export default WordReducer;
