import { CHANGE_STATUS, DELETE_NOTE, EDIT_NOTE, GET_ALL_NOTES, GET_NOTE_BY_TEACHER, GET_ONE_NOTE, } from "../actions/constants";

const notesReducer = (state = { notes: [], note: {} }, action) => {
  switch (action.type) {
    case GET_ALL_NOTES:
      return { ...state, notes: action?.payload };
    case GET_NOTE_BY_TEACHER:
      return { ...state, notes: action?.payload };
    case GET_ONE_NOTE:
      return { ...state, note: action?.payload };
    case EDIT_NOTE:
      return {
        ...state, notes: state.notes.map(e => {
          if (e._id == action.payload._id) {
            return action.payload
          }
          else {
            return e
          }
        })
      };
      ;
    case DELETE_NOTE:
      return { ...state, notes: state.notes.filter(e => e._id !== action.payload) };

      case CHANGE_STATUS:
        const statusEdited = state.notes.map(note => {
          if (note._id === action.payload) {
            const newStatus = note.status === 'toDo' ? 'done' : 'toDo';
            return { ...note, status: newStatus };
          }
          return note;
        });
        return { ...state, notes: statusEdited };
    default:
      return state;
  }
}

export default notesReducer;
