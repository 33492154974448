import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllCards,
  getCardById,
  editCard,
  deleteCard,
  createCard,
  fetchCardByList,
  fetchListByBoard,
  changePosition,
  fetchNoteCardByTeacher,
  getAllTaskLogs,
  moveTaskLog,
  deleteTaskLog,
  createTaskLog,
  editTaskLog,
  getAllArchivedCard,
  updateBoardLog,
  changeIndexPosition,
  getCardsInBoard,
  createCardWithStudent,
  addMetaCardToMember,
  getLearningPath,
  genrateQuizToActivity,
  genrateActivityDesc,
  createDescription,
  exportCard,
  importCard,
  customTaskLog,
  createMetaCards,
  getRelatedCards,
  createRelatedCards,
  updateRelatedCards,
  deleteRelatedCards,
} from "api";
import {
  GET_ALL_CARDS,
  GET_CARD,
  EDIT_CARD,
  DELETE_CARD,
  CREATE_CARD,
  LOADING,
  GET_CARD_BY_LIST,
  GET_LIST_BY_BOARD,
  GET_NOTE_CARD,
  GET_ALL_TASK_LOGS,
  GET_ALL_ARCHIVED_CARDS,
  GET_CARD_IN_BOARD,
  FETCH_LEARNING_PATH,
  CREATE_QUIZ,
  CREATE_DESC,
  MOVE_TASK,
  ARCHIVE_TASK,
  GET_RELATED_CARDS,
} from "./constants";
import io from "socket.io-client";

// const socket = io("http://localhost:5034");
const socket = io("https://api-sprintup.intellect.tn");

const userDataString = localStorage.getItem("userData");
const userData = JSON.parse(userDataString);
// const userData = { _id: "652a6d801e41855bd07db017" };
export const fetchAllCardsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllCards();
    dispatch({ type: GET_ALL_CARDS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const getCardsInBoardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getCardsInBoard(id);
    dispatch({ type: GET_CARD_IN_BOARD, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
  }
};
export const fetchNoteCardByTeacherAction =
  (boardId, teacherId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await fetchNoteCardByTeacher(boardId, teacherId);
      dispatch({ type: GET_NOTE_CARD, payload: data });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
    }
  };

export const addMetaCardToMemberAction =
  (fromData, id, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      await addMetaCardToMember(fromData, id);
      // dispatch({ type: GET_LIST_BY_BOARD, payload: data.data });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const fetchCardAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getCardById(id);
    dispatch({ type: GET_CARD, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const fetchCardByListAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchCardByList(id);
    dispatch({ type: GET_CARD_BY_LIST, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};
export const editCardAction =
  (fromData, handleClose, id, idOfBoard, logs, setLogs, template) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data: task } = await editCard(fromData, id);
      if (template) {
        updateRelatedCards(id);
      }
      handleClose();
      // socket.emit("createTask", { boardId: idOfBoard, task });
      // toast.success(data.message, {
      //   autoClose: 1000,
      // });
      dispatch({ type: EDIT_CARD, payload: task });
      await editTaskLog({
        task: id,
        project: idOfBoard,
        user: userData._id,
        memberType: userData.role == "student" ? "Client" : "User",
      });
      await updateBoardLog({
        task: id,
        project: idOfBoard,
        user: userData._id,
        action: "Task Has Been Edited",
        memberType: userData.role == "student" ? "Client" : "User",
      });
      await Promise.all(
        logs.map(async (log) => {
          return customTaskLog({
            task: id,
            project: idOfBoard,
            user: userData._id,
            action: log,
            memberType: userData.role == "student" ? "Client" : "User",
          });
        })
      );

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      }
    }
  };
export const deleteCardAction = (id, idOfBoard) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    await deleteCard(id);
    dispatch({ type: DELETE_CARD, payload: id });
    const { data: listData } = await fetchListByBoard(idOfBoard);
    dispatch({ type: GET_LIST_BY_BOARD, payload: listData });
    const { data: archivedData } = await getAllArchivedCard(idOfBoard);
    dispatch({ type: GET_ALL_ARCHIVED_CARDS, payload: archivedData });
    await deleteTaskLog({
      task: id,
      project: idOfBoard,
      user: userData._id,
      memberType: userData.role == "student" ? "Client" : "User",
    });
    await updateBoardLog({
      task: id,
      project: idOfBoard,
      user: userData._id,
      action: "Task Has Been Deleted",
      memberType: userData.role == "student" ? "Client" : "User",
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const createCardAction =
  (fromData, handleClose, handleCloseX, idOfBoard, template) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createCard(fromData);
      if (template) {
        createRelatedCards(data.data._id);
      }
      dispatch({ type: CREATE_CARD, payload: data.data });

      const { data: noteBycreate } = await fetchNoteCardByTeacher(
        idOfBoard,
        userData._id
      );
      socket.emit("createTask", {
        boardId: idOfBoard,
        task: data.data,
      });
      dispatch({ type: GET_NOTE_CARD, payload: noteBycreate });
      await createTaskLog({
        task: data.data._id,
        project: idOfBoard,
        user: userData._id,
      });
      await updateBoardLog({
        task: data._id,
        project: idOfBoard,
        user: userData._id,
        action: "Task Has Been Created",
        memberType: userData.role == "student" ? "Client" : "User",
      });
      handleClose();
      handleCloseX();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

export const createCardWithStudentAction =
  (fromData, handleClose, idOfBoard) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createCardWithStudent(fromData);
      dispatch({ type: CREATE_CARD, payload: data.data });
      // const { data: listData } = await fetchListByBoard(idOfBoard);
      // dispatch({ type: GET_LIST_BY_BOARD, payload: listData });
      // const { data: noteBycreate } = await fetchNoteCardByTeacher(
      //   idOfBoard,
      //   userData._id
      // );
      dispatch({ type: GET_NOTE_CARD, payload: noteBycreate });
      // await createTaskLog({
      //   task:data.data._id,
      //   project:idOfBoard,
      //   user:userData._id,
      // })
      await updateBoardLog({
        task: data._id,
        project: idOfBoard,
        user: userData._id,
        action: "Task Has Been Created by student",
        memberType: userData.role == "student" ? "Client" : "User",
      });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
    }
  };

export const changePositionAction = (fromData, id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    dispatch({
      type: MOVE_TASK,
      payload: {
        source: fromData.currentListId,
        newListId: fromData.newListId,
        taskId: fromData.cardId,
        newIndex: fromData.newIndex,
        startGroup: fromData.startGroup,
        destinationGroup: fromData.destinationGroup,
      },
    });
    await changePosition(fromData, id);

    // socket.emit("moveTask", {
    //   task: {
    //     taskId: fromData.cardId,
    //     newListId: fromData.newListId,
    //     source: fromData.currentListId,
    //     newIndex: fromData.newIndex,
    //   },
    //   boardId: id,
    // });

    dispatch({ type: LOADING, payload: false });

    await moveTaskLog({
      task: fromData.cardId,
      project: id,
      user: userData._id,
      source: fromData.currentListId,
      destination: fromData.newListId,
      memberType: userData.role == "student" ? "Client" : "User",
    });
    await updateBoardLog({
      task: fromData.cardId,
      project: id,
      user: userData._id,
      action: "Task Position Successfully Updated",
      memberType: userData.role == "student" ? "Client" : "User",
    });
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const changeIndexPositionAction = (fromData, id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    await changeIndexPosition(fromData, id);
    dispatch({
      type: MOVE_TASK,
      payload: {
        source: fromData.listId,
        newListId: fromData.listId,
        taskId: fromData.cardId,
        newIndex: fromData.newIndex,
      },
    });
    socket.emit("moveTask", {
      task: {
        taskId: fromData.cardId,
        newListId: fromData.listId,
        source: fromData.listId,
        newIndex: fromData.newIndex,
      },
      boardId: id,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  }
};

export const getAllTaskLogsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllTaskLogs(id);
    dispatch({ type: GET_ALL_TASK_LOGS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const archivedCardAction =
  (fromData, id, idOfBoard, template) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await editCard(fromData, id);
      if (template) {
        deleteRelatedCards(id);
      }
      dispatch({ type: ARCHIVE_TASK, payload: data });
      await editTaskLog({
        task: id,
        project: idOfBoard,
        user: userData._id,
        memberType: userData.role == "student" ? "Client" : "User",
      });
      await updateBoardLog({
        task: id,
        project: idOfBoard,
        user: userData._id,
        action: "Task Has Been Archived",
        memberType: userData.role == "student" ? "Client" : "User",
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };

export const fetchAllArchivedCardsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getAllArchivedCard(id);
    dispatch({ type: GET_ALL_ARCHIVED_CARDS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchLearningPathAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getLearningPath(formData);
    dispatch({ type: FETCH_LEARNING_PATH, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const genrateQuizToActivityAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await genrateQuizToActivity(formData);

    dispatch({ type: CREATE_QUIZ, payload: data });
    dispatch({ type: LOADING, payload: false });
    toast.success("AI quiz generated successfully", {
      autoClose: 1000,
    });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
  }
};
export const genrateDescToActivityAction =
  (formData, setshowPromptDescs) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data: desc } = await genrateActivityDesc(formData);
      console.log(desc);
      const { data } = await createDescription({
        ...desc,
        cardId: formData.cardId,
      });
      dispatch({ type: CREATE_DESC, payload: data });

      dispatch({ type: LOADING, payload: false });
      setshowPromptDescs(false);
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      console.log(error);
    }
  };
export const downloadCard = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await exportCard(id);
    const jsonData = data.card;
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${id}_card.json`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
  }
};

export const importCardAction =
  (card, handleClose, idOfBoard, listId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      const { data } = await importCard(card, userData._id, idOfBoard, listId);
      dispatch({ type: CREATE_CARD, payload: data.data });
      await updateBoardLog({
        task: data._id,
        project: idOfBoard,
        user: userData._id,
        action: "Task Has Been Imported",
        memberType: userData.role == "student" ? "Client" : "User",
      });
      handleClose();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
    }
  };
export const createMetaToUsersAction =
  (id, ids, handleCloseUsersModal) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      await createMetaCards(id, ids);
      handleCloseUsersModal();
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
    }
  };
export const fetchRelatedCardsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getRelatedCards(id);

    dispatch({ type: GET_RELATED_CARDS, payload: data.cards });
    dispatch({ type: LOADING, payload: data.cards });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
  }
};
