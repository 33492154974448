// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
} from "react-bootstrap";
import { Trash, MoreVertical, Edit, Download } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteQuizAction,
  exportQuizAction,
  fetchAllQuizsAction,
  importQuizAction,
} from "actions/quiz";

import { ToastContainer } from "react-toastify";

// import data files

const QuizList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const handleEditQuiz = ({ id }) => {
    navigate(`/quiz/create-quiz?quiz_id=${id}`);
  };
  const { loading } = useSelector((state) => state.auth);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a JSON file");
    } else {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(importQuizAction(formData));
    }
  };
  const { quizs } = useSelector((state) => state.quizs);
  useEffect(() => {
    dispatch(fetchAllQuizsAction());
  }, [dispatch]);

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  /* DELETE QUIZ */
  const handleDeleteQuiz = ({ id }) => {
    setQuizToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteQuiz = () => {
    if (quizToDelete && confirmationMessage === "DELETE QUIZ") {
      dispatch(deleteQuizAction(quizToDelete));
      setQuizToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };
  const exportQuiz = (id) => {
    dispatch(exportQuizAction(id));
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [quizToDelete, setQuizToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = (id) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={() => handleEditQuiz(id)}>
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          <Dropdown.Item eventKey="2" onClick={() => handleDeleteQuiz(id)}>
            {" "}
            <Trash
              size="15px"
              className="dropdown-item-icon"
              variant="danger"
              onClick={confirmDeleteQuiz}
            />{" "}
            Remove
          </Dropdown.Item>
          <Dropdown.Item eventKey="3" onClick={() => exportQuiz(id)}>
            {" "}
            <Download size="15px" className="dropdown-item-icon" /> Export
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "title", Header: "Title" },
      {
        accessor: "duration",
        Header: "Duration",
        Cell: ({ value, row }) => {
          return (
            <div>
              <h5>{value} min</h5>
            </div>
          );
        },
      },
      {
        accessor: "questions.length",
        Header: "Question Number",
        Cell: ({ value, row }) => {
          return (
            <div>
              <h5>{value} questions</h5>
            </div>
          );
        },
      },
      {
        accessor: "pass_mark",
        Header: "Mark Pass(%)",
        Cell: ({ value, row }) => {
          return (
            <div>
              <h5>{value} %</h5>
            </div>
          );
        },
      },
      {
        id: "action",
        accessor: "",
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <Fragment>
              <ActionMenu id={row.original._id} />
            </Fragment>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => quizs, [quizs]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          /* 	id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} /> */
        },
        ...columns,
      ]);
    }
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Quizes</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Quiz mangment</Breadcrumb.Item>
                <Breadcrumb.Item active>Quizs</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
        <Col className="d-flex justify-content-start">
          <div>
            <Link to="/quiz/create-quiz">
              <Button>Add New Quiz</Button>
            </Link>
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            variant="primary"
            disabled={loading}
            style={{ margin: "0 15px 15px 0", width: "40%" }}
            onClick={handleUpload}
          >
            Import Quiz
          </Button>
          <div>
            <input
              className="form-control"
              type="file"
              accept=".json"
              onChange={handleFileChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div className=" overflow-hidden">
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="mb-lg-0 mb-2 px-5 py-4"
                  >
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                      placeholder="Search Quiz"
                    />
                  </Col>
                </Row>
              </div>

              <div className="table-responsive ">
                <Table {...getTableProps()} className="text-nowrap">
                  <thead className="table-light">
                    {headerGroups.map((headerGroup, index) => (
                      <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, index) => (
                          <th key={index} {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr key={index} {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to delete this quiz?</p>
                  <h4 className="text-secondary">
                    you must write "DELETE QUIZ" to confirm the deletion
                  </h4>
                  <input
                    className="form-control"
                    rows="3"
                    placeholder="Enter confirmation message"
                    value={confirmationMessage}
                    onChange={(e) => setConfirmationMessage(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={
                      confirmationMessage !== "DELETE QUIZ"
                        ? "secondary"
                        : "danger"
                    }
                    disabled={confirmationMessage !== "DELETE QUIZ"}
                    onClick={confirmDeleteQuiz}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showApproveModal}
                onHide={() => setShowApproveModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Approve</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to approve this request ?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowApproveModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    disabled={loading}
                    // onClick={confirmApprovedFollowedPackRequest}
                  >
                    Approved
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showRejectModal}
                onHide={() => setShowRejectModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Change Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to Change this status ?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowRejectModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    // onClick={confirmRejectedFollowedPackRequest}
                  >
                    Change Status
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default QuizList;
