import { AUTH, LOADING, LOGOUT } from "../actions/constants";

const authReducer = (
  state = { is_authenticated: false, loading: false, user: null },
  action
) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case AUTH:
      if (action.payload.is_authenticated) {
        // User is authenticated, update the user state
        return { ...state, user: action.payload, is_authenticated: true };
      } else {
        // User is not authenticated, clear the user state
        return { ...state, user: null, is_authenticated: false };
      }

    case LOGOUT:
      // Handle logout action
      localStorage.removeItem("user");
      return { ...state, user: null, is_authenticated: false };

    default:
      return state;
  }
};

export default authReducer;