import {
    GET_CLIENT_DETAILS,
  } from "../actions/constants";
  
  const clientDetailsReducer = (state = { clientDetails: [], }, action) => {
    switch (action.type) {
      case GET_CLIENT_DETAILS:
        return { ...state, clientDetails: action?.payload };
      default:
        return state;
    }
  };
  
  export default clientDetailsReducer;
  