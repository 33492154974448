import { createQuizAccountAction } from "actions/quiz";

import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const PlacmentTest = () => {
  const dispatch = useDispatch();
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const navigate = useNavigate();

  const handleCreateQuizAccount = () => {
    dispatch(createQuizAccountAction(userData._id));
  };
  const handleStartTest = () => {
    if (userData?.placmentTest?.completed) {
      alert("You have already passed the placement Test.");
    } else {
      if (!userData?.placmentTest?.test1?.completed) {
        navigate(
          `/quiz/quiz_start?quiz_id=${userData?.schoolId.placementTest?.test1}&placmentTest=1`
        );
      } else if (
        userData?.placmentTest?.test1?.completed &&
        userData?.placmentTest?.test1?.success &&
        !userData?.placmentTest?.test2?.completed
      ) {
        navigate(
          `/quiz/quiz_start?quiz_id=${userData?.schoolId.placementTest?.test2}&placmentTest=2`
        );
      } else if (
        userData?.placmentTest?.test2?.completed &&
        userData?.placmentTest?.test2?.success &&
        !userData?.placmentTest?.test3?.completed
      ) {
        navigate(
          `/quiz/quiz_start?quiz_id=${userData?.schoolId.placementTest?.test3}&placmentTest=3`
        );
      } else if (
        userData?.placmentTest?.test3?.completed &&
        userData?.placmentTest?.test3?.success &&
        !userData?.placmentTest?.test4?.completed
      ) {
        navigate(
          `/quiz/quiz_start?quiz_id=${userData?.schoolId.placementTest?.test4}&placmentTest=4`
        );
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <div>
        {userData?.quiz_id ? (
          <Button onClick={() => handleStartTest()}>
            Start Placement Test
          </Button>
        ) : (
          <Button onClick={() => handleCreateQuizAccount()}>
            Move to Placement Test!
          </Button>
        )}
      </div>
    </div>
  );
};

export default PlacmentTest;
