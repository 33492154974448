// import node module libraries
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";

//translate component

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ApexCharts from "components/elements/charts/ApexCharts";
import { getQuizAnswerAction } from "actions/quiz";
import {
  generateFourDigitId,
  getCoveredPointsFromFailedAnswers,
} from "helper/utils";
import { genrateBoardAction } from "actions/boardKanban";
import { updateTestAction } from "actions/authClient";

const QuizFinish = () => {
  const dispatch = useDispatch();
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const quiz = searchParams.get("quiz_id");
  const user = searchParams.get("user_id");
  const board_id = searchParams.get("board_id");
  const placmentTest = searchParams.get("placmentTest");

  const { result } = useSelector((state) => state.quizs);
  console.log(result);
  useEffect(() => {
    dispatch(getQuizAnswerAction({ user, quiz }, placmentTest));
  }, [dispatch, quiz, user]);

  const maxScore = result?.max_score;
  const userScore = result?.score;
  const score = Math.round(result?.percentage);
  const userPassedQuiz = result?.success;
  const QuizResultChartSeries = [score];
  const QuizResultChartOptions = {
    colors: userPassedQuiz ? ["#38a169"] : ["#F1170D"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: -2,
          size: "50%",
          background: userPassedQuiz ? "#d1f5ea" : "#FF9B88",
        },
        dataLabels: {
          name: { show: true, fontSize: "18px", fontWeight: 600, offsetY: 7 },
          value: { show: false },
        },
      },
    },
    labels: [score + "%"],
  };

  const handleFinishQuiz = () => {
    if (placmentTest == "77") {
      dispatch(
        genrateBoardAction({
          title: "genratedBoard" + generateFourDigitId(),
          type: "teaching",
          studentId: userData._id,
          uncovredPoints: getCoveredPointsFromFailedAnswers(result),
        })
      );
      navigate("/kanban_board_by_member");
    } else {
      navigate("/kanban_board_by_member");
    }
  };

  const handleNextTest = () => {
    if (placmentTest == "1" && userPassedQuiz) {
      navigate(
        `/quiz/quiz_start?quiz_id=${userData?.schoolId.placementTest?.test2}&placmentTest=2`
      );
    } else if (placmentTest == "2" && userPassedQuiz) {
      navigate(
        `/quiz/quiz_start?quiz_id=${userData?.schoolId.placementTest?.test3}&placmentTest=3`
      );
    } else if (placmentTest == "3" && userPassedQuiz) {
      navigate(
        `/quiz/quiz_start?quiz_id=${userData?.schoolId.placementTest?.test4}&placmentTest=4`
      );
    }
  };
  return (
    <Card>
      <Card.Body className="p-10 text-center">
        {userPassedQuiz && !placmentTest ? (
          <div className="mb-4">
            <h2>🎉 Congratulations. You passed!</h2>
            <p className="mb-0 px-lg-14">
              You have successfully completed the quiz. Now you can click on
              'Finish' and go back to your quiz page.
            </p>
          </div>
        ) : !placmentTest ? (
          <div className="mb-4">
            <h2>😔 Sorry. You failed.</h2>
            <p className="mb-0 px-lg-14">
              You did not pass the quiz. Please review your answers.
            </p>
          </div>
        ) : (
          <div className="mb-4">
            <h2>🎉 Congratulations! You finished the test!</h2>
            <p className="mb-0 px-lg-14">
              You have successfully completed the quiz. Now you can click on
              'Next Test' to proceed to the next test, or 'Finish' to return to
              your dashboard page.
            </p>
          </div>
        )}
        {!placmentTest && (
          <div className="d-flex justify-content-center">
            <div className="resultChart">
              <ApexCharts
                options={QuizResultChartOptions}
                series={QuizResultChartSeries}
                type="radialBar"
                height={150}
              />
            </div>
          </div>
        )}
        {!placmentTest && (
          <div className="mt-3">
            <span>
              Your Score:
              <span className="text-dark">
                {" "}
                is ({userScore} {"points"}) {"out of"} ({maxScore} {"points"})
              </span>
            </span>
            <br />
          </div>
        )}
        <div className="mt-5">
          <Button
            onClick={() => handleFinishQuiz()}
            className="btn btn-primary"
          >
            Finish
          </Button>
          {((placmentTest == "1" && userPassedQuiz) ||
            (placmentTest == "2" && userPassedQuiz) ||
            (placmentTest == "3" && userPassedQuiz)) && (
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => handleNextTest()}
              className="btn btn-primary"
            >
              Next Test
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default QuizFinish;
